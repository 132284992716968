// from https://stackoverflow.com/a/6117889
export const getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}
// adapted from https://stackoverflow.com/a/45592631
export const getDateOfISOWeek = (week, year) => {
    const getZeroBasedIsoWeekDay = date => (date.getDay() + 6) % 7
    const getIsoWeekDay = date => getZeroBasedIsoWeekDay(date) + 1

    const zeroBasedWeek = week - 1
    const zeroBasedWeekDay = 0
    let days = (zeroBasedWeek * 7) + zeroBasedWeekDay
    
    // Dates start at 2017-01-01 and not 2017-01-00
    days += 1
    
    const firstDayOfYear = new Date(year, 0, 1)
    const firstIsoWeekDay = getIsoWeekDay(firstDayOfYear)
    const zeroBasedFirstIsoWeekDay = getZeroBasedIsoWeekDay(firstDayOfYear)
    
    // If year begins with W52 or W53
    if (firstIsoWeekDay > 4) days += 8 - firstIsoWeekDay
    // Else begins with W01
    else days -= zeroBasedFirstIsoWeekDay

    // make sure the time is in UTC
    let res = new Date(Date.UTC(year, 0, days))
    
    return res 
}
import React, { useState } from "react";

import {useNavigate} from 'react-router-dom';

import { API } from "aws-amplify";

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import EditInvoice from "./EditInvoice";


function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Create New invoice</h1>
        </div>
    )
}
function NewEmptyInvoice(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [emptyInvoice, setEmptyInvoice] = useState({})


    React.useEffect(() => {
        API
        .get("billing", "/billing?action=getLastInvoice&org="+props.org, {})
        .then(documents => {
            let documentNumber = ""
            if(documents.length > 0) {
                documentNumber = (parseInt(documents[0].documentNumber)+1).toString()
            }
            setEmptyInvoice({
                documentNumber: documentNumber,
                invoiceIssueDate: today(),
                dueDate: addDays(new Date(), props.organizationAttributes.configuration.defaultPaymentTerms),
                currency: props.organizationAttributes.preferredCurrency,
                exchangeRate: 0,
                strExchangeRate: "",
                taxRate: 0,
                strTaxRate: "",
                lineItems: [{
                    type: "service",
                    description: "",
                    quantity: 0,
                    unitPrice: 0,
                }],
                company: {},
                paymentData: [],
            })
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });

    }, [props.org, props.organizationAttributes.preferredCurrency, props.organizationAttributes.configuration.defaultPaymentTerms])
    
    function today() {
        return new Date().toISOString()
    }
    function addDays(d, days) {
        d.setDate(d.getDate() + days);
        return d.toISOString()
    }

    function onSubmit(documentData) {
        // set page to submitted
        setSubmitting(true)
        // prepare post
        const params = {
            body: {
                action: "newInvoice",
                document: documentData,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contracts => {
            navigate("/invoices");
            setSubmitting(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }


    if(loading || Object.keys(emptyInvoice).length === 0) {
        return (
            <div>
                <Header />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }

    return (
        <div>
            <Header />
            {errorMessage !== "" ?
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
            :
                null
            }
            <EditInvoice {...props} preferredCurrency={props.organizationAttributes.preferredCurrency} submitting={submitting} onSubmit={onSubmit} defaults={emptyInvoice} id="" issueData="" submitButton="Save" type="invoice" />

        </div>
    )
}

export default NewEmptyInvoice;
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert'

import { API } from "aws-amplify";
import BillablesCosts from './BillablesCosts';
import Estimates from './Estimates';
import Budgets from "./Budgets";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonToolbar";
import TopNumbers from "./TopNumbers";


function Dashboard(props) {
    const [estimateData, setEstimateData] = useState({hasData: false})
    const [totalData, setTotalData] = useState({
        hasData: false,
    })
    const [dashboardData, setDashboardData] = useState({
        billables: {},
        profit: {},
        cumulative: {},
        cumulativePrevPeriod: [],
        hasData: false,
    })
    const [errorMessage, setErrorMessage] = useState("")
    const [inputDate, setInputDate] = useState({
        year: new Date().getFullYear(),
        month: "", //(new Date().getMonth()+1).toString().padStart(2, "0"),
    })

    React.useEffect(() => {
        // static data
        const labelsMonthly = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
        const labelsYearly = [1,2,3,4,5,6,7,8,9,10,11,12]
        // API call
        API
        .get("billing", "/billing?action=getContractorsBillables&org="+props.org+"&year="+inputDate.year+"&month="+inputDate.month, {})
        .then(response => {
            let totalData = {
                total: response.total,
                totalBillableCosts: response.totalBillableCosts,
                totalOtherCosts: response.totalOtherCosts,
                totalProfit: response.totalProfit,
                totalRecurring: response.totalRecurring,
                hasData: true,
            }
            let dashboardData = {
                billables: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                    datasets: [...response.userBillables, response.totalContractorCosts],
                },
                billablesPrevPeriod: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                },
                nonBillables: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                    datasets: [...response.userNonBillables],
                },
                profit: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                    datasets: [ response.userBillablesAndContractsProfit ],
                },
                cumulative: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                    datasets: [ response.cumulativeProfit, response.cumulativeRevenue ],
                },
                cumulativePrevPeriod: [],
                profitPrevPeriod: {
                    labels: inputDate.month === "" ? labelsYearly : labelsMonthly,
                },
                profitAverage: response.userBillablesProfitAverage,
                yearly: inputDate.month === "" ? true : false,
                hasData: true,
            }
            // set estimate table data
            const newEstimateData = {
                total: response.total,
                userTotals: response.userTotals,
                recurringItems: response.recurringItems,
                hasData: true,
            }
            // retrieve last years numbers
            let prevPeriodYear = inputDate.year
            let prevPeriodMonth = inputDate.month
            if(inputDate.month === "") {
                prevPeriodYear--
            } else {
                if(inputDate.month === "01") {
                    prevPeriodMonth = "12"
                    prevPeriodYear--
                } else {
                    prevPeriodMonth = (parseInt(prevPeriodMonth)-1).toString().padStart(2, "0")
                }
            }
            API
            .get("billing", "/billing?action=getContractorsBillables&org="+props.org+"&year="+prevPeriodYear+"&month="+prevPeriodMonth, {})
            .then(response => {
                dashboardData.profitPrevPeriod.datasets = [ response.userBillablesAndContractsProfit ]
                dashboardData.profitAveragePrevPeriod = response.userBillablesProfitAverage
                dashboardData.cumulativePrevPeriod = [response.cumulativeProfit, response.cumulativeRevenue]
                dashboardData.billablesPrevPeriod.datasets = [ summarizeBillables(response.userBillables, prevPeriodYear, prevPeriodMonth) ]
                // update state
                setTotalData(totalData)
                setDashboardData(dashboardData)
                setEstimateData(newEstimateData)
            })
            .catch(error => {
                if(typeof error === 'object') {
                    setErrorMessage(JSON.stringify(error));
                } else {
                    setErrorMessage(error);
                }
            });

            })
        .catch(error => {
            if(typeof error === 'object') {
                setErrorMessage(JSON.stringify(error));
            } else {
                setErrorMessage(error);
            }
        });
    },[props.org, inputDate])
        
    function createMonthDropDown(year) {
        const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        let monthIndex = 11
        let items = [];
        if(year === new Date().getUTCFullYear()) {
            monthIndex = new Date().getUTCMonth()
        }
        items.push(<option key="" value="">Aggregated</option>)         
        for (let i = 0; i <= monthIndex; i++) {          
            const key = (i+1).toString().padStart(2, "0")
            items.push(<option key={key} value={key}>{months[i]}</option>);
        }
        return items;
    }
    function createYearDropDown() {
        var x = new Date();
        x.setDate(1);
        
        let items = [];         
        for (let i = 0; i <= 10; i++) { 
             const v = x.getFullYear()   
             items.push(<option key={v} value={v}>{v}</option>);
             x.setYear(x.getFullYear()-1);
        }
        return items;
    }
    function onChangeInputDateYear(value) {
        setEstimateData({hasData: false})
        setDashboardData({hasData: false})
        setInputDate({
            ...inputDate,
            year: value,
        })
    }
    function onChangeInputDateMonth(value) {
        setEstimateData({hasData: false})
        setDashboardData({hasData: false})
        setInputDate({
            ...inputDate,
            month: value,
        })
    }
    function summarizeBillables(billables, year, _) {
        if(billables.length === 0 || billables[0].data.length === 0) {
            return {}
        }
        const summarized = { 
            label: "Previous year (" + year + ")",
            data: Array(billables[0].data.length).fill(0),
        }
        billables.forEach(billableItem => {
            billableItem.data.forEach((dataItem, i) => {
                summarized.data[i] += dataItem
            })
        })
        return summarized
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h2>Dashboard</h2>
                    <ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup aria-label="Select contractor / client contracts" className="mx-1">
                            <Form.Select className="form-select form-select-sm" aria-label="Year" value={inputDate.year} onChange={e => onChangeInputDateYear(e.target.value)}>
                                {createYearDropDown()}
                            </Form.Select>
                        </ButtonGroup>
                        <ButtonGroup aria-label="New Vendor Contracts group">
                            <Form.Select className="form-select form-select-sm" aria-label="Month" value={inputDate.month} onChange={e => onChangeInputDateMonth(e.target.value)}>
                                {createMonthDropDown(inputDate.year)}
                            </Form.Select>
                        </ButtonGroup>
                    </ButtonToolbar>
            </div>
            <div>
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
                }
                <Row className="mt-4 mb-5 mx-2">
                    <TopNumbers data={totalData} {...props} />
                </Row>
                <Row className="mb-5">
                    <Col>
                        <BillablesCosts dashboardData={dashboardData} {...props} />
                    </Col>
                    <Col>
                        <Estimates data={estimateData} {...props} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Budgets inputDate={inputDate} {...props} />
                    </Col>
                    <Col>
                        <Budgets inputDate={inputDate} responseType="hoursremaining" {...props} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
  
export default Dashboard;
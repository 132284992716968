import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

import { API } from '@aws-amplify/api';

import EditContractorContract from './EditContractorContract';
import {useNavigate} from 'react-router-dom';


function NewContract(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)

    const emptyContract = {
        description: "",
        dayRate: 0,
        strDayRate: "",
        currency: "EUR/$",
        paymentTermsInDays: 30,
        autoInvoice: false,
        company: {
            id: "",
            issueDate: "",
            user: {
                id: "",
                userID: "",
            }
        },
        startDate: new Date().toISOString().split("T")[0],
        endDate: "",
        hasEndDate: false,
    }

    function onSubmit(contract) {
        setSubmitting(true)
        const params = {
            body: {
                action: "newContract",
                contract: { ...contract, 
                    startDate: new Date(contract.startDate).toISOString(),
                    endDate: contract.endDate === "" ? new Date("0001-01-01").toISOString() : new Date(contract.endDate).toISOString() },
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contract => {
            navigate(props.myroute + "?showSaved=1")
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <Container>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">New Contract</h1>
        </div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }

        <EditContractorContract {...props} submitting={submitting} onSubmit={onSubmit} id="" issueData="" contract={emptyContract} />

    </Container>
    )
}
export default NewContract;
import {useNavigate} from 'react-router-dom';



function UserListItem(props) {

    const navigate = useNavigate();
    const key = props.user.id + "|" + props.user.issueDate
    const onRowClick = () => navigate("/getUser?id=" + props.user.id + "&userID=" + props.user.userID);
    return (
        <tr key={key} className="clickable">
            <td onClick={() => onRowClick()}>{props.user.firstName} {props.user.lastName}</td>
            <td onClick={() => onRowClick()}>{props.user.email}</td>
            <td onClick={() => onRowClick()}>{props.user.role}</td>
        </tr>
    )

}

export default UserListItem;

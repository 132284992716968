import React, {useState, useEffect} from 'react'
import BTable from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import Calendar from 'react-calendar'

import { ArrowDownCircle, ArrowUpCircle, FileText } from 'react-feather'

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'
import { matchSorter } from 'match-sorter'

import { API } from "aws-amplify";

import 'react-calendar/dist/Calendar.css';

import { CSVLink } from 'react-csv'
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonToolbar";

function Header(props) {
  const [calendarValue, setCalenderValue] = useState([new Date(), new Date()]);
  const [showCalendar, setShowCalendar] = useState(false)

  function onCalendarChange(value) {
    setCalenderValue(value)
    setShowCalendar(false)
    props.onCalendarChange(value)
  }

  function getCalendarValue() {
    if(calendarValue[0].getFullYear() === calendarValue[1].getFullYear() && calendarValue[0].getMonth() === calendarValue[1].getMonth()) {
      return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0")
    } else {
      return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0") + " until " + calendarValue[1].getFullYear() + "-" + (calendarValue[1].getMonth()+1).toString().padStart(2, "0")
    }
  }
  return (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Reports</h1>
          <div>
          <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup aria-label="Export to CSV">
                <CSVLink data={props.csvData} className="btn btn-outline-secondary" filename={"report-exported-"+getCalendarValue()+".csv"}>
                  <FileText size={15} /> Export to CSV
                </CSVLink>
              </ButtonGroup>
              <ButtonGroup aria-label="Calendar picker" className="mx-3">
                <Button variant="outline-secondary" onClick={() => setShowCalendar(!showCalendar)}>Date: {getCalendarValue()}</Button>
                {showCalendar ? 
                  <Calendar className="calendar-overlay"
                  onChange={onCalendarChange}
                  value={calendarValue}
                  view="year"
                  maxDetail="year"
                  selectRange={true}
                />
                :
                  null
                }
                </ButtonGroup>
            </ButtonToolbar>
          </div>
      </div>
  )
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

function ColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Table({ columns, data, loading, setCsvData }) {
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  )

  // put data in csv format
  useEffect(() => {
    let csvData = []
    rows.forEach((row, i) => {
      csvData.push(row.values)
    })
    // replace returns in data (otherwise it'll make the csv corrupt)
    csvData.map(x => {
      for (var key in x) {
        if (x.hasOwnProperty(key) && key === "description") {
          x[key] = x[key].replace(/\n/g, "; ").replace(/\r/g, "").replace(/\.;/g, ";").replace(/\t/g, " ")
        }
      }
      return x
    })
    setCsvData(csvData)
  }, [rows, setCsvData]);

  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                      &nbsp;{column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDownCircle size={16} />
                          : <ArrowUpCircle size={16} />
                        : ''}
                  </span>
                </div>
                {/* Render the columns filter UI */}
                <div>{column.canFilter ? column.render('Filter') : <br />}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            </th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? 
          <tr><td colSpan={visibleColumns.length}><Spinner animation="border"/></td></tr>
        :
          null
        }
        {loading ? null : rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps()}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>
    </BTable>
  )
}

function Reports(props) {
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [errorMessage, setErrorMessage] = useState('');
    const [timeEntries, setTimeEntries] = useState({
        timesheetEntries: [],
    });
    const [loading, setLoading] = useState(true);
    const [csvData, setCsvData] = useState([])

    React.useEffect(() => {
        const start = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth()+1).toString().padStart(2, "0") + "-01T00:00:00.000000Z"
        const end = new Date(dateRange[1].getFullYear(), dateRange[1].getMonth()+1, 0).toISOString()
        let url = "/billing?action=listTimeAndProjects&org="+props.org+"&start="+start+"&end="+end
        if(props.role === "client") {
          url += "&selectedUserID=" + props.userID
        }
        API
        .get("billing", url, {})
        .then(res => {
            res.timesheetEntries.forEach((timeEntry, index) => {
              res.timesheetEntries[index].duration = parseFloat(timeEntry.duration / 3600).toFixed(2)
              res.timesheetEntries[index].entryDate = timeEntry.entrySortKey.entryDate.split("T")[0]
              res.projectNames.forEach(project => {
                if(project.issueDate === timeEntry.entrySortKey.projectIssueDate) {
                  res.timesheetEntries[index].projectName = project.name
                  res.timesheetEntries[index].clientName = project.clientName
                  project.tasks.forEach(task => {
                    if(task.issueDate === timeEntry.entrySortKey.projectTaskIssueDate) {
                      res.timesheetEntries[index].taskName = task.taskName
                    }
                  })
                }
                res.users.forEach(user => {
                  if(user.userID === timeEntry.entrySortKey.userID) {
                    res.timesheetEntries[index].user = user.firstName + " " + user.lastName
                  }
                })
              })
            })
            setTimeEntries(res)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });

    }, [props.org, dateRange, props.role, props.userID])

    function onCalendarChange(value) {
      setLoading(true)
      setDateRange(value)
    }

    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      // Calculate the options for filtering
      // using the preFilteredRows
      const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
          if(row.hasOwnProperty("values")) {
            options.add(row.values[id])
          }
        })
        return [...options.values()]
      }, [id, preFilteredRows])

      // Render a multi-select box
      return (
        <select
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined)
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      )
    }

    const columns = React.useMemo(
        () => [
          {
            Header: 'Client Name',
            accessor: 'clientName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Project Name',
            accessor: 'projectName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Task Name',
            accessor: 'taskName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Team Member',
            accessor: 'user',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Entry Date',
            accessor: 'entryDate',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Description',
            accessor: 'description',
            Filter: ColumnFilter,
            filter: 'fuzzyText',
          },
          {
            Header: 'Duration',
            accessor: 'duration',
            disableFilters: true,
            Footer: duration => {
              const total = React.useMemo(
                () =>
                  duration.rows.reduce((sum, row) => parseFloat(row.values.duration) + sum, 0),
                [duration.rows]
              )

              return <>Total: {parseFloat(total).toFixed(2)}</>
            },
          },
        ],
        []
      )

    return (
      <div>
        <Header onCalendarChange={onCalendarChange} csvData={csvData} />
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <Table columns={columns} data={timeEntries.timesheetEntries} loading={loading} setCsvData={setCsvData} />
      </div>
    )

}
export default Reports;
import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

import { API } from '@aws-amplify/api';

import EditProject from './EditProject';
import {useNavigate} from 'react-router-dom';


function NewProject(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)

    const emptyProject = {
        name: "",
        currency: "USD",
        hourlyRate: "",
        autoInvoice: true,
        client: {
            id: "",
            issueDate: "",
        },
        tasks: [],
        budget: {
            type:  "",
            hours: 0,
            strHours: "",
            frequency: "",
        }
    }

    function onSubmit(project) {
        setSubmitting(true)
        const params = {
            body: {
                action: "newProject",
                project: project,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(project => {
            navigate(props.myroute + "?org="+props.org+"&showSaved=1")
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <Container>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">New Project</h1>
        </div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }

        <EditProject submitting={submitting} onSubmit={onSubmit} id="" issueData="" project={emptyProject} org={props.org} />

    </Container>
    )
}
export default NewProject;
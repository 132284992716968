import { API } from "aws-amplify";

async function GetOrganizationAttributes(orgID) {
    let res = await API
    .get("billing", "/billing?action=getOrganization&org="+orgID, {})
    .then(organization => {
        return organization
    })
    .catch(error => {
        return null
    });
    return res
}

export default GetOrganizationAttributes;
import { UserCheck } from 'react-feather';
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';

function ContactListItem(props) {

    const navigate = useNavigate();
    const key = props.contact.id + "|" + props.contact.issueDate
    const onRowClick = () => navigate("/getContact?id=" + props.contact.id +"&issueDate="+props.contact.issueDate);
    return (
        <tr key={key} className="clickable">
            <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
            <td onClick={() => onRowClick()}>{props.contact.name}</td>
            <td onClick={() => onRowClick()}>{props.contact.contactDetails === null || props.contact.contactDetails.length === 0 ? "" : props.contact.contactDetails[0].email}</td>
            <td onClick={() => onRowClick()} className="text-center text-green">{props.contact.user === null || props.contact.user.userID === "" ? "" : <UserCheck size={18} />}</td>
        </tr>
    )

}

export default ContactListItem;

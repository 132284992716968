import React, { useState } from 'react'
import PlanningDropdown from './PlanningDropdown'

function PlanningEntry(props) {
    const [cell, setCell] = useState({ background: Array.from({length: props.days.length}, (_, i) => "transparent"), projects: Array.from({length: props.days.length}, _ => []) })
    const [clickActive, setClickActive] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)
    const [selectedRange, setSelectedRange] = useState([])
    const [selectedExistingEntry, setSelectedExistingEntry] = useState(-1)

    React.useEffect(() => {
        let cell = { background: Array.from({length: props.days.length}, (_, i) => "transparent"), projects: Array.from({length: props.days.length}, _ => []) }
        props.entries.forEach((planningEntries, index) => {
            planningEntries.forEach(planningEntry => {
                const startDate = new Date(planningEntry.startDate)
                const projectInfo = planningEntry.project.hasOwnProperty("name") && planningEntry.project.name !== "" ? {issueDate: planningEntry.project.issueDate, name: planningEntry.project.name, clientName: planningEntry.project.clientName, durationHours: planningEntry.durationHours, color: planningEntry.project.color, type: "project"} : {type: planningEntry.type}
                if(planningEntry.userID === props.user.userID) {
                    switch(props.totalRows) {
                        case 1:
                            if(planningEntry.durationHours === 4) {
                                cell.background[index] = planningEntry.type + "-50"
                            } else if(planningEntry.durationHours === 1) {
                                cell.background[index] = planningEntry.type + "-12"
                            } else {
                                cell.background[index] = planningEntry.type + "-100"
                            }
                            cell.projects[index].push(projectInfo)
                            break;
                        case 2:
                            if(planningEntry.durationHours > 4) {
                                cell.background[index] = planningEntry.type
                            } else if(isBefore(startDate, "12PM") && props.row === 0) {
                                if(planningEntry.durationHours === 1) {
                                    cell.background[index] = planningEntry.type + "-12"
                                } else {
                                    cell.background[index] = planningEntry.type + "-100"
                                }
                            } else if(!isBefore(startDate, "12PM") && props.row === 1) {
                                if(planningEntry.durationHours === 1) {
                                    cell.background[index] = planningEntry.type + "-12"
                                } else {
                                    cell.background[index] = planningEntry.type + "-100"
                                }
                            }
                            cell.projects[index].push(projectInfo)
                            break;
                        case 10:
                            if(isInHourRange(startDate.getUTCHours(), planningEntry.durationHours, 8+props.row)) {
                                cell.background[index] = planningEntry.type + "-100"
                                cell.projects[index].push(projectInfo)
                            }
                            break;
                        case 24:
                            if(isInHourRange(startDate.getUTCHours(), planningEntry.durationHours, 0+props.row)) {
                                cell.background[index] = planningEntry.type + "-100"
                                cell.projects[index].push(projectInfo)
                            }
                            break;
                        default:
                        cell.background[index] = planningEntry.type
                    }
                }
            })
        })
        props.selectedPlanningItem.entries.forEach(planningEntry => {
            const startDate = new Date(planningEntry.startDate)
            let endDate = new Date(planningEntry.startDate)
            endDate.setUTCHours(endDate.getUTCHours() + planningEntry.durationHours)
            props.days.forEach((day, index) => {
                if(props.selectedPlanningItem.userID === props.user.userID) {
                    switch(props.totalRows) {
                        case 1:
                            if(planningEntry.durationHours === 8 && startDate.toISOString().split("T")[0] === day.toISOString().split("T")[0]) {
                                cell.background[index] = props.selectedPlanningItem.type + "-selected"
                            }
                            break;
                        case 2:
                            if(planningEntry.durationHours === 8 && startDate.toISOString().split("T")[0] === day.toISOString().split("T")[0]) {
                                cell.background[index] = props.selectedPlanningItem.type + "-selected"
                            } else if(planningEntry.durationHours === 4 && startDate.toISOString().split("T")[0] === day.toISOString().split("T")[0]) {
                                if(isBefore(startDate, "12PM") && props.row === 0) {
                                    cell.background[index] = props.selectedPlanningItem.type + "-selected"
                                }
                                if(!isBefore(startDate, "12PM") && props.row === 1) {
                                    cell.background[index] = props.selectedPlanningItem.type + "-selected"
                                }
                            } 
                            break;
                        case 10:
                            if(startDate.toISOString().split("T")[0] === day.toISOString().split("T")[0] && isInHourRange(startDate.getUTCHours(), planningEntry.durationHours, 8+props.row)) {
                                cell.background[index] = props.selectedPlanningItem.type + "-selected"
                            }
                            break
                        case 24:
                            if(startDate.toISOString().split("T")[0] === day.toISOString().split("T")[0] && isInHourRange(startDate.getUTCHours(), planningEntry.durationHours, 0+props.row)) {
                                cell.background[index] = props.selectedPlanningItem.type + "-selected"
                            }
                            break
                        default:
                    }
                }
            })
        })
        setCell(cell)
    }, [props.view, props.days, props.entries, props.user.userID, props.totalRows, props.row, props.selectedPlanningItem])
   
    function isInHourRange(hour, duration, rowHour) {
        if(duration === 8) duration++ // 8 + 1 hour break
        for(let i = 0; i < duration; i++) {
            if(duration === 9 && rowHour === 12) {
                return false // lunch break for 8 hour days
            }
            if((hour+i) === rowHour) {
                return true
            }
        }
        return false
    }

    function onCellClick(index) {
        if(props.lock) return // there's another PlanningEntry component that has a lock
        if(cell.background[index] !== "transparent" && cell.background[index] !== "active-2" && cell.background[index] !== "active") { // clicked on existing cell
            if(selectedExistingEntry === -1) {
                setSelectedExistingEntry(index)
                props.onClickExistingEntry(props.user.userID, getHoursBasedOnView(props.days[index]), cell.background[index].split("-")[0])
            }
            return // don't overwrite
        }
        if(clickActive && index < activeIndex) return // ignore negative ranges
        if(selectedRange.length === 2) return // ignore cell clicks when a range is selected
        
        if(clickActive) {
            // check if range is correct
            let isRangeCorrect = true
            for(let i = activeIndex; i <= index; i++) {
                if(cell.background[i] !== "active" && cell.background[i] !== "active-2") {
                    isRangeCorrect = false
                }
            }
            if(isRangeCorrect) { // only if yes, then set the background color on "active"
                let newCellBackground = [...cell.background]
                newCellBackground[index] = "active"
                setCell({ ...cell, background: newCellBackground })
            }
            setSelectedRange([activeIndex, index])
        } else {
            let newCellBackground = [...cell.background]
            newCellBackground[index] = "active"
            setCell({ ...cell, background: newCellBackground })
        }
        setClickActive(!clickActive)
        setActiveIndex(index)
    }
    function onCellMouseEnter(index) {
        if(cell.projects[index].length > 0) {
            props.setActiveCell(cell.projects[index])
        } else {
            props.setActiveCell([])
        }
        if(cell.background[index] !== "transparent" && cell.background[index] !== "active-2" && cell.background[index] !== "active") return // don't overwrite
        if(selectedRange.length === 2) return // ignore cell clicks when a range is selected
        if(clickActive && index > activeIndex) {
            let newCellBackground = [...cell.background]
            for(let i = activeIndex+1; i <= index; i++) {
                if(newCellBackground[i] !== "transparent" && newCellBackground[i] !== "active" && newCellBackground[i] !== "active-2") return // don't overwrite
                newCellBackground[i] = "active-2"
            }
            for(let i = index+1; i < props.days.length; i++) {
                if(newCellBackground[i] === "active-2") {
                    newCellBackground[i] = "transparent"
                }
            }
            setCell({ ...cell, background: newCellBackground })
        }
        if(clickActive && index === activeIndex) {
            let newCellBackground = [...cell.background]
            for(let i = index+1; i < props.days.length; i++) {
                if(newCellBackground[i] === "active-2") {
                    newCellBackground[i] = "transparent"
                }
            }
            setCell({ ...cell, background: newCellBackground })
        }
    }
    function getHours() {
        if(props.view === "day") {
            return 8
        } else if(props.view === "half day") {
            return 4
        }
        return 1
    }
    function dropdownClickProject(project) {
        props.onSubmitSelectedRange(getDateRangeToSubmit(), "project", props.user, project)
        let newCellbackground = [...cell.background]
        for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
            newCellbackground[i] = "project"
        }
        let newProjectsInfo = [...cell.projects]
        for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
            newProjectsInfo[i].push = { name: project.name, clientName: project.client.name, durationHours: getHours() }
        }
        setCell({ ...cell, background: newCellbackground, projects: newProjectsInfo })
        setClickActive(false)
        setActiveIndex(-1)
        setSelectedRange([])
        setSelectedExistingEntry(-1)
    }
    function dropdownClick(action) {
        if(action === "cancel" && selectedExistingEntry !== -1) {
            setSelectedExistingEntry(-1)
        } else if(action === "cancel") {
            let newCellbackground = [...cell.background]
            for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
                if(newCellbackground[i] === "active" || newCellbackground[i] === "active-2") {
                    newCellbackground[i] = "transparent"
                }
            }
            setCell({ ...cell, background: newCellbackground })
        } else if(action === "clear") {
            props.deleteSelectedPlanningItem()
            setSelectedExistingEntry(-1)
        } else if(action === "zoomIn") {
            if(props.view === "day") {
                props.setView("half day")
            } else if(props.view === "half day") {
                props.setView("work day")
            } else if(props.view === "work day") {
                props.setView("full day")
            }
        } else if(action === "zoomOut") {
            if(props.view === "full day") {
                props.setView("work day")
            } else if(props.view === "work day") {
                props.setView("half day")
            } else if(props.view === "half day") {
                props.setView("day")
            }
        } else {
            props.onSubmitSelectedRange(getDateRangeToSubmit(), action, props.user, {})
            let newCellbackground = [...cell.background]
            for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
                newCellbackground[i] = action
            }
            setCell({ ...cell, background: newCellbackground })
        }
        setClickActive(false)
        setActiveIndex(-1)
        setSelectedRange([])
        setSelectedExistingEntry(-1)
        props.setLock(false)
        props.unSelectPlanningItem()
    }

    function isRangeCorrect() {
        let res = true
        for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
            if(cell.background[i] !== "active" && cell.background[i] !== "active-2") {
                return false
            }
        }
        return res
    }

    function getHoursBasedOnView(inputDate) {
        let newDate = new Date(inputDate)
        switch(props.totalRows) {
            case 1:
                newDate.setUTCHours(8, 0, 0)
                break
            case 2:
                if(props.row === 0) {
                    newDate.setUTCHours(8, 0, 0)
                } else {
                    newDate.setUTCHours(13, 0, 0)
                }
                break
            case 10:
                newDate.setUTCHours(8 + props.row, 0, 0) // start hour is 8
                break
            case 24:
                newDate.setUTCHours(0 + props.row, 0, 0) // start hour is 0
                break
            default:
        }
        return newDate
    }

    function getDateRangeToSubmit() {
        let range = []
        for(let i = selectedRange[0]; i <= selectedRange[1]; i++) {
            let newDate = getHoursBasedOnView(props.days[i])
            range.push(newDate)
        }
        return range
    }

    function getCellBackgroundForProject(index) {
        if(cell.background[index].endsWith("-selected")) {
            return {backgroundColor: "#3759ad"}
        }
        if(cell.projects[index].length === 1) {
            return {backgroundColor: cell.projects[index][0].color}
        }
        if(cell.projects[index].length === 2) {
            return {background: "linear-gradient(to right bottom, "+cell.projects[index][0].color+" 48%, "+cell.projects[index][1].color+" 50%)"}
        }
        return {}
    }

    function getCellBackground(day, index) {
        if(day.getUTCDay() === 0 || day.getUTCDay() === 6) {
            return " background-weekend-" +cell.background[index]
        }
        if(cell.background[index].startsWith("project")) {
            return ""
        }
        return " background-" +cell.background[index]
    }

    function showRowLegend() {
        switch(props.totalRows) {
            case 1:
                return "8 Hours"
            case 2:
                return "4 Hours"
            case 10:
                if ((props.row+8) >= 12) {
                    if ((props.row + 8) === 12) return "12:00 PM"
                    return (props.row + 8 - 12) + ":00 PM"
                }
                return (props.row+8) + ":00 AM"
            case 24:
                if ((props.row) >= 12) {
                    if ((props.row + 8) === 12) return "12:00 PM"
                    return (props.row - 12) + ":00 PM"
                }
                return (props.row) + ":00 AM"
            default:
                return ""
        }
    }

    function isBefore(startDate, pointInTime) {
        switch(pointInTime) {
            case "12PM":
                let newDate = new Date(startDate)
                newDate.setUTCHours(12, 0, 0)
                if(startDate < newDate) return true
                break;
            default:
                return false
        }
        return false
    }

    function getCellContents(index) {
        let out = []
        cell.projects[index].forEach(project => {
            if(project.type === "project") {
                out.push(project.clientName.charAt(0).toUpperCase())
            }
            if(project.type === "timeoff") {
                out.push("T")
            }
        })
        return out.join(", ")
    }
    
    return (
        <tr>
            {props.row === 0 ?
                <td rowSpan={props.totalRows}>
                    {props.user.firstName}&nbsp;{props.user.lastName}
                </td>
            :
                null
            }
            <td style={{whiteSpace: "nowrap"}}>
                <small>{showRowLegend()}</small>
            </td>
            
            {props.days.map((day, index) => {
                const projectBackground = getCellBackgroundForProject(index)
                return (
                    <td 
                        key={index + "-" + day.getUTCDate()}
                        onMouseEnter={() => onCellMouseEnter(index)}
                        style={{...projectBackground, whiteSpace: "nowrap", textAlign: "center", fontWeight: "bold"}}
                        className={"border border-bottom clickable" + getCellBackground(day, index)}
                        onClick={() => onCellClick(index)}>

                        
                        {getCellContents(index)}
                        
                        <PlanningDropdown 
                            {...props}
                            selectedRange={selectedRange}
                            index={index}
                            isRangeCorrect={isRangeCorrect}
                            selectedExistingEntry={selectedExistingEntry}
                            selectedPlanningItem={props.selectedPlanningItem}
                            dropdownClick={dropdownClick} 
                            dropdownClickProject={dropdownClickProject}
                            projects={props.projects} />
                    </td>
                    )
                })}
            </tr>
    )
}

export default PlanningEntry
import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import ProjectListItem from './ProjectListItem'
import SelectArchived from '../common/SelectArchived'
import SelectProjectAction from './SelectProjectAction'


function Header(props) {
    function onChangeSelectArchived(value) {
        props.onChangeSelectArchived(value)
    }
    function onChangeSelectProjectAction(value) {
        props.onChangeSelectProjectAction(value)
    }
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Projects</h1>
        {props.role === "admin" ?
            <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup aria-label="Select action">
                    <SelectProjectAction onChange={onChangeSelectProjectAction} selected="" page={props.selectedArchivedValue} />
                </ButtonGroup>
                <ButtonGroup aria-label="Select active / archived" className="mx-3">
                    <SelectArchived onChange={onChangeSelectArchived} type="Projects" selected={props.selectedArchivedValue} />
                </ButtonGroup>
            </ButtonToolbar>
        : null }
        </div>
    )
}

function ListProjects(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState({
        projects: []
    });
    const [clients, setClients] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showArchived, setShowArchived] = useState('active')

    const updateCheckedItems = (k, v) => {
        // pass a clone of storeMap
        setCheckedItems(new Map(checkedItems.set(k, v)));
    };

    React.useEffect(() => {
        let url = "/billing?action=listProjects&org="+props.org
        if(props.role === "client") {
            url = "/billing?action=listProjectsForClient&org="+props.org + "&selectedUserID=" + props.userID
        }
        if(showArchived === "archived") {
            url += "&archived=true"
        }
        API
        .get("billing", url, {})
        .then(projects => {
            setProjects(projects)
            setLoading(false)
        })
        .catch(error => {
            if(API.isCancel(error)) return
            setErrorMessage(error.message)
        });
        /* Contacts */
        url = "/billing?action=listContacts&org="+props.org
        if(props.role === "client") {
            url = "/billing?action=getMyContact&org="+props.org
        }
        if(showArchived === "archived") {
            url += "&all=true"
        }
        API
        .get("billing", url, {})
        .then(clients => {
            setClients(clients)
        })
        .catch(error => {
            if(API.isCancel(error)) return
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, showArchived, props.userID])


    function getClient(clientID, clientIssueDate, clients) {
        let res = {}
        clients.forEach(client => {
            if(client.id === clientID && client.issueDate === clientIssueDate) {
                res = client
            }
        })
        return res
    }

    function onChangeSelectArchived(value) {
        setLoading(true)
        setShowArchived(value)
    }

    function onChangeSelectProjectAction(value) {
        switch(value) {
            case "archive":
                patchSelectedProject({archived: true})
            break;
            case "restore":
                patchSelectedProject({archived: false})
            break;
            default:
                return
        }
    }

    function patchSelectedProject(patch) {
        setLoading(true)
        let projectsToPatch = []
        checkedItems.forEach((checked, key) => {
            if(checked) {
                const keySplit = key.split("|")
                projectsToPatch.push({
                    id: keySplit[0] + "|" + keySplit[1],
                    issueDate: keySplit[2],
                    ...patch,
                })
            }
        })
        const params = {
            body: {
                action: "patchProjects",
                projects: projectsToPatch,
                org: props.org,
            },
        };
        API
        .post("billing", "/billing", params)
        .then(response => {
            let newProjects = []
            projects.projects.forEach(project => {
                let found = false
                projectsToPatch.forEach(projectToPatch => {
                    if(projectToPatch.id === project.id && projectToPatch.issueDate === project.issueDate) {
                        found = true
                        if(showArchived === "archived" && projectToPatch.archived) {
                            newProjects.push(project)
                        }
                        if(showArchived !== "archived" && !projectToPatch.archived) {
                            newProjects.push(project)
                        }
                    }    
                })
                if(!found) {
                    newProjects.push(project)
                }
            })
            setProjects({ ...projects, projects: newProjects })
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }

    function handleCheckboxChange(target) {
        const key = target.name;
        const isChecked = target.checked;
        updateCheckedItems(key, isChecked)
    }

    if(loading) {
        return (
            <div>
                <Header onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} onChangeSelectProjectAction={onChangeSelectProjectAction} />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }

    return (
        <Container>
            <Header {...props} onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} onChangeSelectProjectAction={onChangeSelectProjectAction} />
            {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Project saved
            </Alert>
            : ""
            }   
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
            }
            <Table striped bordered hover>
                    <thead>
                        <tr key="header">
                        <th></th>
                        <th>Client</th>
                        <th>Project Name</th>
                        <th>Budget</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.projects.map((project, index) => {
                            if(clients.length > 0) {
                                return (
                                    <ProjectListItem
                                    key={project.id + "|" + project.issueDate}
                                    project={project}
                                    budget={projects.projectCurrentBudget.filter(function (e) { return e.projectID === project.id && e.projectIssueDate === project.issueDate })}
                                    org={props.org}
                                    client={getClient(project.client.id, project.client.issueDate, clients)}
                                    prevClient={index === 0 ? {issueDate: "" } : getClient(projects.projects[index-1].client.id, projects.projects[index-1].client.issueDate, clients) }
                                    myroute={props.myroute}
                                    checkedItems={checkedItems}
                                    readOnly={props.role === "admin" ? false : true}
                                    handleCheckboxChange={(e) => handleCheckboxChange(e)}
                                    />
                                )
                            }
                            return (null)
                        })
                        }
                    </tbody>
                </Table>
        </Container>
    )
}
export default ListProjects;
import React, {useState} from "react";


import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import { Archive, FilePlus, FileText, RotateCcw } from "react-feather";
import ListContractorContracts from "./ListContractorContracts";
import ListClientContracts from "./ListClientContracts";
import Alert from 'react-bootstrap/Alert'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {useNavigate} from 'react-router-dom';
import { API } from "aws-amplify";
import Spinner from 'react-bootstrap/Spinner';

function Header(props) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("Active Vendor Contracts")
  const newClientContract = () => navigate("/newClientContract");
  const newVendorContract = () => navigate("/newContract");

  function onChangeFilter(value, setShowArchived, description) {
    setFilter(description)
    if(props.page !== setShowArchived) { props.setShowArchived(setShowArchived) }
    props.onSelectContractType(value)
  }

  return (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">Contracts</h1>
      {props.role === "admin" ?
      <ButtonToolbar aria-label="Toolbar with button groups">
          <DropdownButton id="dropdown-basic-button" title="Action" variant="white border-secondary">
            <Dropdown.Item onClick={() => newVendorContract()}><FilePlus size={16} /> New Vendor Contract</Dropdown.Item>
            <Dropdown.Item onClick={() => newClientContract()}><FilePlus size={16} /> New Client Contract</Dropdown.Item>
            <Dropdown.Divider />
            {props.page === "active" ?
                <Dropdown.Item onClick={(e) => props.onAction("archive")}><Archive size={16} /> Archive Selected Contracts</Dropdown.Item>
                :
                null
            }
            {props.page === "archived" ?
                <Dropdown.Item onClick={(e) => props.onAction("restore")}><RotateCcw size={16} /> Restore Selected Contracts</Dropdown.Item>
                :
                null
            }
          </DropdownButton>
          <DropdownButton id="dropdown-basic-button" title={"Filter: " + filter} variant="white border-secondary" className="mx-3">
            <Dropdown.Item onClick={() => onChangeFilter("contractor", "active", "Active Vendor Contracts")}><FileText size={16} /> Active Vendor Contracts</Dropdown.Item>
            <Dropdown.Item onClick={() => onChangeFilter("client", "active", "Active Client Contracts")}><FileText size={16} /> Active Client Contracts</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => onChangeFilter("contractor", "archived", "Archived Vendor Contracts")}><Archive size={16} /> Archived Vendor Contracts</Dropdown.Item>
            <Dropdown.Item onClick={() => onChangeFilter("client", "archived", "Archived Client Contracts")}><Archive size={16} /> Archived Client Contracts</Dropdown.Item>
          </DropdownButton>
      </ButtonToolbar>
        : null
      }
      </div>
  )
}


function ListContracts(props) {
  const [showSaved, setShowSaved] = useState(props.showSaved);
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [contractType, setContractType] = useState("contractor")
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [showArchived, setShowArchived] = useState('active')
  const [contracts, setContracts] = useState([])

  React.useEffect(() => {
    setLoading(true)
    let url = ""

    if(contractType === "client") {
      url = "/billing?action=listUserClientContracts&org="+props.org
      if(props.role === "admin") {
        url = "/billing?action=listClientContracts&org="+props.org
      }
    } else {
      url = "/billing?action=listUserContracts&org="+props.org
      if(props.role === "admin") {
        url = "/billing?action=listContracts&org="+props.org
      }
    }
    if(showArchived === "archived") {
      url += "&archived=true"
    }

    API
    .get("billing", url, {})
    .then(contracts => {
      setLoading(false)
      setContracts(contracts)
    })
    .catch(error => {
        setErrorMessage(error.message)
    });
  }, [props.org, props.role, showArchived, contractType])

  const updateCheckedItems = (k, v) => {
    // pass a clone of storeMap
    setCheckedItems(new Map(checkedItems.set(k, v)));
  };


  function onSelectContractType(value) {
    setCheckedItems(new Map())
    setContracts([])
    setContractType(value)
  }

  function handleCheckboxChange(target) {
    const key = target.name;
    const isChecked = target.checked;
    updateCheckedItems(key, isChecked)
  }
  function onAction(value) {
    switch(value) {
        case "archive":
          patchSelectedContracts({archived: true})
        break;
        case "restore":
          patchSelectedContracts({archived: false})
        break;
        default:
            return
    }
  }
  function patchSelectedContracts(patch) {
    setLoading(true)
    let contractsToPatch = []
    checkedItems.forEach((checked, key) => {
        if(checked) {
            const keySplit = key.split("|")
            contractsToPatch.push({
                id: keySplit[0] + "|" + keySplit[1],
                issueDate: keySplit[2],
                ...patch,
            })
        }
    })
    const params = {
        body: {},
    };
    if(contractType === "client") {
      params.body = {
        action: "patchClientContracts",
        clientContracts: contractsToPatch,
        org: props.org,
      }
    } else {
      params.body = {
        action: "patchContracts",
        contracts: contractsToPatch,
        org: props.org,
      }
    }

    API
    .post("billing", "/billing", params)
    .then(response => {
        let newContracts = []
        contracts.forEach(contract => {
            let found = false
            contractsToPatch.forEach(contractToPatch => {
                if(contractToPatch.id === contract.id && contractToPatch.issueDate === contract.issueDate) {
                    found = true
                    if(showArchived === "archived" && contractToPatch.archived) {
                        newContracts.push(contract)
                    }
                    if(showArchived !== "archived" && !contractToPatch.archived) {
                        newContracts.push(contract)
                    }
                }    
            })
            if(!found) {
                newContracts.push(contract)
            }
        })
        setContracts(newContracts)
        setLoading(false)
    })
    .catch(error => {
        setErrorMessage(error.message)
    });
  }

    
  if(loading) {
    return (
        <div>
            <Header onSelectContractType={onSelectContractType} contractTypeSelected={contractType} onAction={onAction} page={showArchived} setShowArchived={setShowArchived} {...props} />
            {errorMessage !== "" ?
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
            :
                <Spinner animation="border"/>
            }
        </div>
    )
}


  if(contractType === "client") {
    return (
        <div>
            <Header onSelectContractType={onSelectContractType} contractTypeSelected={contractType} onAction={onAction} page={showArchived} setShowArchived={setShowArchived} {...props} />
            {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Contract saved
            </Alert>
            : ""
            } 
            <ListClientContracts {...props} checkedItems={checkedItems} handleCheckboxChange={(e) => handleCheckboxChange(e)} contracts={contracts} />
        </div>
    )
  } else {
    return (
      <div>
            <Header onSelectContractType={onSelectContractType} contractTypeSelected={contractType} onAction={onAction} page={showArchived} setShowArchived={setShowArchived} {...props} />
            {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Contract saved
            </Alert>
            : ""
            } 
            <ListContractorContracts {...props} checkedItems={checkedItems} handleCheckboxChange={(e) => handleCheckboxChange(e)} contracts={contracts} />
      </div>
    );
  }

}
  
  export default ListContracts;

import React, { useState } from "react";
import { API } from '@aws-amplify/api'
import Alert from 'react-bootstrap/Alert'

import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/esm/Spinner";
import Table from 'react-bootstrap/Table'

function SelectUsers(props) {
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        API
        .get("billing", "/billing?action=listUsers&org="+props.org, {})
        .then(users => {
            setUsers(users)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }, [props.role, props.org, props.default])


    if(loading) {
        return (
            <Spinner animation="border"/>
        )
    }

    if(props.type !== undefined && props.type === "selectbox") {
        return (
            <div>
                <Form.Select className={props.selectClassName ? props.selectClassName : "form-select form-select-sm"} onChange={(e) => props.handleUserChange(e.target.value)} value={props.selected}>
                    {props.showDefaultValue !== undefined && props.showDefaultValue ?
                        <option value="">Select User</option>
                        : null
                    }
                    {props.defaultValue !== undefined && props.defaultValue !== "" ?
                        <option value="">{props.defaultValue}</option>
                        : null
                    }
                    {users.map((user, _) => (
                        <option key={user.userID} value={user.userID}>{user.firstName} {user.lastName}</option>
                    ))}
                </Form.Select>
                
            {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
            }
            </div>
        )
    } else {
        return (
            <div>
            <Table striped bordered hover>
            <thead>
                <tr key="header">
                <th></th>
                <th>First Name</th>
                <th>Last Name</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr key={user.id + "|" + user.userID}>
                        <td><Form.Check type="checkbox" label="" name={user.id + "|" + user.userID} checked={props.checkedUsers.has(user.id + "|" + user.userID) ? props.checkedUsers.get(user.id + "|" + user.userID) : false} onChange={(e) => props.handleUserChange(e.target)} /></td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                    </tr>
                ))
                }
            </tbody>
            </Table>
            {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
            }
            </div>
        )
    }

}

export default SelectUsers;
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom';

function InvoiceListItem(props) {
    const navigate = useNavigate();
    const invoice = props.invoice
    const key = invoice.id + "|" + invoice.issueDate
    const invoiceIssueDate = new Date(invoice.invoiceIssueDate)
    const dueDate = new Date(invoice.dueDate)
    const onRowClick = () => navigate(props.myroute + "?id=" + invoice.id +"&issueDate="+invoice.issueDate);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: invoice.currency,
    });

    function getInvoiceTotal() {
        return formatter.format(invoice.invoiceTotal)
    }
    function getInvoiceTotalWithVAT() {
        if(invoice.taxRate === 0) {
            return formatter.format(invoice.invoiceTotal)
        }
        const taxAmount = Math.round(((invoice.invoiceTotal / 100 * invoice.taxRate) + Number.EPSILON) * 100) / 100
        return formatter.format(invoice.invoiceTotal + taxAmount)
    }

    return (
        <tr key={key} className="clickable">
            <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
            <td onClick={() => onRowClick()}>{invoice.company.name}</td>
            <td onClick={() => onRowClick()}>{invoice.documentNumber}</td>
            <td onClick={() => onRowClick()}>{invoiceIssueDate.getFullYear() === 1 ? "" : invoiceIssueDate.toISOString().split('T')[0]}</td>
            <td onClick={() => onRowClick()}>{dueDate.getFullYear() === 1 ? "" : dueDate.toISOString().split('T')[0]}</td>
            <td onClick={() => onRowClick()}>{getInvoiceTotal()}</td>
            <td onClick={() => onRowClick()}>{getInvoiceTotalWithVAT()}</td>
            <td onClick={() => onRowClick()}>{invoice.status}</td>
        </tr>
    )
}

export default InvoiceListItem;
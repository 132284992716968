import { ChevronsRight, DownloadCloud, PlusCircle, Save } from 'react-feather'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import React, { useState } from "react";

import SelectCurrency from '../common/SelectCurrency';
import { API } from "aws-amplify";

import ErrorMessage from '../Error'

import Spinner from 'react-bootstrap/Spinner';
import EditInvoiceLineItem from './EditInvoiceLineItem';
import SelectContact from '../contacts/SelectContact';
import { Modal } from 'react-bootstrap';


function EditInvoice(props) {
    const [document, setDocument] = useState({
        currency: props.preferredCurrency,
        lineItems: [],
    })
    const [loading, setLoading] = useState(true)
    const [showImportRows, setShowImportRows] = useState(false)
    const [importRowValue, setImportRowValue] = useState("")
    const [errorMessage, setErrorMessage] = useState('');

    React.useEffect(() => {
        if(props.id !== "" && props.issueDate !== "") {
            let action = ""
            if(props.type === "bill") {
                action = "listBills"
            } else if(props.type === "invoice") {
                action = "listInvoices"
            } else {
                setErrorMessage("invoice/bill not supplied")
                return
            }
            API
            .get("billing", "/billing?action="+action+"&org="+props.org+"&id="+props.id+"&issueDate="+props.issueDate, {})
            .then(documents => {
                if(documents.length > 0) {
                    if(documents[0].lineItems === null || documents[0].lineItems.length === 0) {
                        documents[0].lineItems = [{
                            type: "",
                            description: "",
                            quantity: 1,
                            unitPrice: documents[0].invoiceTotal,
                            amount: documents[0].invoiceTotal,
                        }]
                    }
                    setDocument({ ...documents[0], strExchangeRate: documents[0].exchangeRate.toString(), strTaxRate: documents[0].taxRate.toString() } )
                    setLoading(false)
                } else {
                    setErrorMessage("Document not found")
                }
            })
            .catch(error => {
                setErrorMessage(error.message)
            });
        } else {
            setDocument(props.defaults)
            setLoading(false)
        }
    }, [props.id, props.issueDate, props.org, props.defaults, props.type])

    const addLineItem = (newLineItem) => {
        const newLineItems = [...document.lineItems, newLineItem]
        setDocument({...document,  lineItems: newLineItems})
    };

    const setCurrency = (currency) => {
        setDocument({...document, currency: currency })
    };
    const updateLineItems = (newItem, index) => {
        const newLineItems = [
            ...document.lineItems.slice(0,index),
            newItem,
            ...document.lineItems.slice(index+1)
        ]
        setDocument({...document,  lineItems: newLineItems})
    };

    const updatePaymentData = (newItem, index) => {
        const newPaymentData = [
            ...document.paymentData.slice(0,index),
            newItem,
            ...document.paymentData.slice(index+1)
        ]
        setDocument({...document,  paymentData: newPaymentData})
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: document.currency,
    });

    function addExtraRow() {
        addLineItem({
            type: "service",
            description: "",
            quantity: 0.00,
            unitPrice: 0.00,
        })
    }
    function importRows() {
        const importedLines = importRowValue.split("\n")
        let newLineItems = [...document.lineItems]
        if(newLineItems.length > 0 && newLineItems[newLineItems.length-1].description === "" && newLineItems[newLineItems.length-1].quantity === 0 && newLineItems[newLineItems.length-1].unitPrice === 0) {
            newLineItems.pop()
        }
        importedLines.forEach(v => {
            const elements = v.split(",")
            if(elements.length === 4) {
                newLineItems.push({
                    type: elements[0],
                    description: elements[1],
                    quantity: parseFloat(elements[2]),
                    unitPrice: parseFloat(elements[3]),
                })
            }
        })
        setDocument({...document, lineItems: newLineItems})


        setShowImportRows(false)
    }
    function removeRow(rowNumber) {
        let newLineItems = [...document.lineItems]
        newLineItems.splice(rowNumber, rowNumber)
        setDocument({...document,  lineItems: newLineItems})
    }


    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
    
    const onImportRowChange = (event) => {
        setImportRowValue(event.target.value)
    }
    const onExtraRowHandleChange = (propertyName, index) => (event) => {
        const value = event.target.value
        if(propertyName === "quantity") {
            if(!isNumeric(value) && value !== "") {
                return
            }
            updateLineItems({
                ...document.lineItems[index],
                [propertyName]: parseFloat(value),
                strQuantity : value,
              }, index)
        } else if(propertyName === "unitPrice") {
            if(!isNumeric(value) && value !== "") {
                return
            }
            updateLineItems({
                ...document.lineItems[index],
                [propertyName]: parseFloat(value),
                strUnitPrice : value,
              }, index)
        } else {
            updateLineItems({
                ...document.lineItems[index],
                [propertyName]: value
              }, index)
        }
    }

    const onDocumentFieldChange = (propertyName) => (event) => {
        if(propertyName === "invoiceIssueDate" || propertyName === "dueDate") {
            if(isNaN(Date.parse(event.target.value)) === false) {
                setDocument({...document, 
                    [propertyName]: new Date(event.target.value).toISOString(),
                    ["str"+propertyName.charAt(0).toUpperCase() + propertyName.slice(1)]: event.target.value
                })
            } else {
                setDocument({...document, 
                    ["str"+propertyName.charAt(0).toUpperCase() + propertyName.slice(1)]: event.target.value
                })
            }
        } else if(propertyName === "exchangeRate" || propertyName === "taxRate") {
            if(!isNumeric(event.target.value) && event.target.value !== "") {
                return
            }
            setDocument({...document,
                ["str"+propertyName.charAt(0).toUpperCase() + propertyName.slice(1)]: event.target.value,
                [propertyName]: parseFloat(event.target.value),
            })
        } else {
            setDocument({...document,  [propertyName]: event.target.value})
        }
    }
    const onDocumentCompanyFieldChange = (propertyName) => (event) => {
        const newCompany = {...document.company,  [propertyName]: event.target.value}
        setDocument({...document,  company: newCompany})
    }

    function onCompanyChange(contact) {
        setDocument({...document, 
            company: { 
                id: contact.id, issueDate: contact.issueDate 
            },
            currency: contact.currency,
            taxRate: contact.tax,
            strTaxRate: contact.tax.toString(),
        })
    }

    const onDocumentPaymentChange = (propertyName, index) => (event) => {
        const newItem = {
          ...document.paymentData[index],
          [propertyName]: event.target.value
        };
        updatePaymentData(newItem, index)
    }

    function showPaymentItems() {
        let items = [];
        if(document.paymentData === null) {
            return items
        }
        for(let i = 0; i < document.paymentData.length; i++){
            items.push(<tr key={"paymentItem-" + i}> 
                <td><Form.Control size="sm" type="text" onChange={onDocumentPaymentChange('accountNumber', i)} value={document.paymentData[i].accountNumber}/></td>
                <td><Form.Control size="sm" type="text" onChange={onDocumentPaymentChange('holderName', i)} value={document.paymentData[i].holderName}/></td>
                <td><Form.Control size="sm" type="text" onChange={onDocumentPaymentChange('paymentReference', i)} value={document.paymentData[i].paymentReference}/></td>
                </tr>)
        }
        return items
    }

    function getInvoiceTotal() {
        let total = 0
        if(document.lineItems === null) {
            return total
        }
        for(let i = 0; i < document.lineItems.length; i++) {
            total += document.lineItems[i].quantity * document.lineItems[i].unitPrice
        }
        return total
    }
    function getTaxAmount() {
        if(document.taxRate === 0) return 0
        const taxAmount = Math.round(((getInvoiceTotal() / 100 * document.taxRate) + Number.EPSILON) * 100) / 100
        return taxAmount
    }
    function getInvoiceTotalWithTax() {
        return getInvoiceTotal() + getTaxAmount()
    }
    function getDateFromDateTime(str) {
        if(str === undefined) {
            return ""
        }
        const index = str.indexOf("T")
        if(index === -1) {
            return str
        }
        return str.slice(0, index)
    }
    function submitEditInvoice() {
        props.onSubmit({ ...document, invoiceTotal: getInvoiceTotal() })
    }

    if(!loading) {
        return (
            <Form.Group>
                <Row className="mt-5 d-flex align-items-center">
                    <Col md="2">
                        Document Number
                    </Col>
                    <Col md="2">
                        <Form.Control size="sm" type="text" onChange={onDocumentFieldChange('documentNumber')} value={document.documentNumber}/>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="2">
                        Company
                    </Col>
                    <Col md="2">
                        {(props.role === "admin" && document.company.id !== "") || (props.role === "admin" && props.type !== "bill") ?
                                <SelectContact {...props} onChange={onCompanyChange} default={document.company.id + "|" + document.company.issueDate} displayName="Client" />
                            :
                                <Form.Control size="sm" type="text" onChange={onDocumentCompanyFieldChange('name')} value={document.company.name} readOnly={props.role === "admin" ? false : true} />
                        }
                    </Col>
                </Row>
                <Row className="mt-5 d-flex align-items-center">
                    <Col md="2">
                        Issue Date
                    </Col>
                    <Col md="2">
                        <Form.Control size="sm" type="text" onChange={onDocumentFieldChange('invoiceIssueDate')} value={document.hasOwnProperty("strInvoiceIssueDate") ? document.strInvoiceIssueDate : getDateFromDateTime(document.invoiceIssueDate)}/>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="2">
                        Due Date
                    </Col>
                    <Col md="2">
                      <Form.Control size="sm" type="text" onChange={onDocumentFieldChange('dueDate')} value={document.hasOwnProperty("strDueDate") ? document.strDueDate : getDateFromDateTime(document.dueDate)}/>
                    </Col>
                </Row>
                <Row className="mt-5 d-flex align-items-center">                
                    <Col md="2">
                        Tax Rate
                    </Col>
                    <Col md="2">
                    <InputGroup className="mb-3" size="sm">
                        <Form.Control size="sm" type="text" onChange={onDocumentFieldChange('taxRate')} value={document.strTaxRate}/>
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                    </Col>
                    <Col md="2"></Col>
                </Row>
                <Row className="mt-5 d-flex align-items-center">
                    <Col md="2">
                        Currency
                    </Col>
                    <Col md="2">
                        <SelectCurrency onChange={setCurrency} default={document.currency} />
                    </Col>
                    <Col md="2"></Col>
                    {document.currency !== props.preferredCurrency ?
                        <Col md="2">
                            Exchange Rate
                        </Col>
                    :
                        null
                    }
                    {document.currency !== props.preferredCurrency ?
                        <Col md="2">
                            <Form.Control size="sm" type="text" onChange={onDocumentFieldChange('exchangeRate')} value={document.strExchangeRate}/>
                        </Col>
                        :
                            null
                    }
                </Row>
                <Row className="mt-5">
                    <Table striped bordered hover>
                    <thead>
                        <tr key="header">
                            <th>Item Type</th>
                            <th>Description</th>
                            <th style={{width: "80px"}}>Quantity</th>
                            <th style={{width: "140px"}}>Unit Price</th>
                            <th style={{width: "140px"}}>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {document.lineItems.map((lineItem, lineItemKey) => (
                            <EditInvoiceLineItem currency={document.currency} key={lineItemKey} lineItem={lineItem} lineItemKey={lineItemKey} onChange={onExtraRowHandleChange} removeRow={removeRow} />
                        ))}
                    </tbody>
                    </Table>
                </Row>
            <Row className="border-bottom">
                <Col md="2">
                    <Button variant="outline-secondary" size="sm me-3" onClick={() => addExtraRow() }>
                        <PlusCircle size={16} /> Add Row
                    </Button>
                    <Button variant="outline-secondary" size="sm" onClick={() => setShowImportRows(true) }>
                        <DownloadCloud size={16} /> Import Rows
                    </Button>
                </Col>
                <Col md="6">

                </Col>
                <Col md="4">
                {document.taxRate === 0 ?
                    <p className="text-end fs-3 fw-bold">
                        Total Payable: {formatter.format(getInvoiceTotal())}
                    </p>
                :
                    <div>
                        <Row className="text-end fs-5 fw-normal">
                            <Col md="8">Total excluding Tax:</Col><Col md="2">{formatter.format(getInvoiceTotal())}</Col>
                            
                        </Row>
                        <Row className="text-end fs-5 fw-normal">
                            <Col md="8">Tax:</Col><Col md="2">{formatter.format(getTaxAmount())}</Col>
                        </Row>
                        <Row className="text-end fs-4 fw-bold border-top border-dark">
                            <Col colSpan="2">Total Payable: {formatter.format(getInvoiceTotalWithTax())}</Col>
                        </Row>
                    </div>
                }
                </Col>
            </Row>
            {document.company.invoiceNote !== "" ? 
                <Row className="mt-2 pb-2 border-bottom">
                    {document.company.invoiceNote}
                </Row>
            : null}
            {props.type === "bill" ?
                <Row className="mt-5 border-bottom">
                    <h2 className="h3">Payment details</h2>
                    <Table striped bordered hover>
                    <thead>
                        <tr key="header">
                            <th>Account Number</th>
                            <th>Holder</th>
                            <th>Reference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showPaymentItems()}
                    </tbody>
                    </Table>
                </Row>
            : null}

            <Row className="mt-3">  
                <Col md="2">
                    <div className="d-grid gap-2">
                        <Button variant="primary" disabled={props.submitting} onClick={submitEditInvoice}>
                        {props.submitButton === "next" ?
                                <span>Next <ChevronsRight size={16} /></span>
                            :
                                <span>Save <Save size={18} /></span>
                            }
                        </Button>
                    </div>
                </Col>
            </Row>
            <Modal show={showImportRows} onHide={() => setShowImportRows(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Import Rows</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Provide row data in comma separated format: Service,Description,Quantity,UnitPrice
                    </p>
                    <Form>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={5} onChange={(e) => onImportRowChange(e)} />
                    </Form.Group>
                    </Form>
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowImportRows(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={importRows}>
                    Import
                </Button>
                </Modal.Footer>
            </Modal>
            </Form.Group>
        )
    } else {
        return (
            <div>
                <Spinner animation="border"/>
                <ErrorMessage body={errorMessage} />
            </div>
        )
    }
}


export default EditInvoice;
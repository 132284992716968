import React from "react";

import Table from 'react-bootstrap/Table'

import {useNavigate} from 'react-router-dom';

import Form from 'react-bootstrap/Form'

function ListClientContracts(props) {
  const navigate = useNavigate();

  const onRowClick = (id, issueDate) => navigate("/getClientContract?id=" + id +"&issueDate="+issueDate);

  function getBillables(billables, currency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency === "" ? props.preferredCurrency : currency,
    });
    let total = 0
    billables.forEach(billable => {
      let multiplier = 0
      switch(billable.frequency) {
        case "monthly":
          multiplier = 12
          break;
        case "quarterly":
          multiplier = 3
          break;
        case "yearly":
            multiplier = 1  
            break;
        default:
          return 0
      }
      billable.lineItems.forEach(lineItem => {
        total += lineItem.unitPrice * lineItem.quantity * multiplier
      })
    })
    return formatter.format(total)
  }

  function addContracts() {
    let items = [];

    if(props.contracts.length > 0) {    
      props.contracts.forEach(contract => {
        const key = contract.id + "|" + contract.issueDate
        items.push(<tr key={key} className="clickable">
          <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
          <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{contract.contact.name}</td>
          <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{contract.name}</td>
          <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{getBillables(contract.billables, contract.contact.currency)}</td>
        </tr>);
      })
    }
    return items
  }

  return (
      <div>
        <Table striped bordered hover>
            <thead>
                <tr key="header">
                  <th></th>
                  <th>Client</th>
                  <th>Contract Name</th>
                  <th>Yearly billables</th>
                </tr>
            </thead>
            <tbody>
                {addContracts()}
            </tbody>
      </Table>
      </div>
  );
}
  
  export default ListClientContracts;
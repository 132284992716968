import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import { API } from '@aws-amplify/api';

import React, { useState } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { Lock, Plus, Save, X } from 'react-feather';
import Spinner from 'react-bootstrap/esm/Spinner';
import SelectUsers from '../users/SelectUsers';
import EntryLine from './EntryLine';
import SubmitApproval from './SubmitApproval'
import EntriesHeader from './EntriesHeader';

import {getWeekNumber, getDateOfISOWeek} from "../common/Utils"

function Header(props) {
    const monthFormatter = new Intl.DateTimeFormat('en', { month: 'short', timeZone: "UTC" });
    
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Week of {props.days[0].getUTCDate()} {monthFormatter.format(props.days[0])}. {props.days[0].getUTCFullYear()} to {props.days[6].getUTCDate()} {monthFormatter.format(props.days[6])}. {props.days[0].getUTCFullYear()}</h1> 
           
            {props.role === "admin" ?
                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="team">
                        <SelectUsers org={props.org} type="selectbox" handleUserChange={props.handleUserChange} selected={props.selectedUserID} />
                    </ButtonGroup>
                </ButtonToolbar>
            : null
            }
        </div>
    )
}
function Timesheet(props) {
    const navigate = useNavigate();
    const {year, month, day, userID} = useParams();

    const [week, setWeek] = useState(getWeekNumber(year === undefined ? new Date() : new Date(year + "-" + month + "-" + day)))
    const [days, setDays] = useState([])
    const [showAddRow, setShowAddRow] = useState(false)
    const [showEditTime, setShowEditTime] = useState({
        show: false,
        day: new Date(),
    })
    const [editTime, setEditTime] = useState({
        projectID: "",
        taskID: "",
        time: "",
        strDuration: "",
        duration: 0,
        prevDuration: 0,
        locked: true,
    })
    const [addRow, setAddRow] = useState({
        projectID: "",
        taskID: "",
    })
    const [userProjects, setUserProjects] = useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [loading, setLoading] = useState(true)
    const [projectsToShow, setProjectsToShow] = useState([])
    const [timesheetEntries, setTimesheetEntries] = useState([])
    const [selectedUserID, setSelectedUserID] = useState(userID !== undefined ? userID : props.userID)

    const monthFormatter = new Intl.DateTimeFormat('en', { month: 'short', timeZone: "UTC" });

    const onEditAddRowChange = (propertyName) => (e) => {
        if(propertyName === "projectID" && e.target.value !== "") {
            let defaultTask = ""
            userProjects.forEach(userProject => {
                if(e.target.value === userProject.id + "|" + userProject.issueDate) {
                    userProject.tasks.forEach(task => {
                        if(task.taskName.toLowerCase() === "default") {
                            defaultTask = task.id + "|" + task.issueDate
                        }
                    })
                }
            })
            setAddRow({...addRow, [propertyName]: e.target.value, taskID: defaultTask})
        } else {
            setAddRow({...addRow, [propertyName]: e.target.value})
        }
    }
    const editTimeChange = (propertyName) => (e) => {
        if(propertyName === "strDuration") {
            let duration = 0
            const parts = e.target.value.split(":")
            if(parts.length > 1) {
                const hours = parseInt(parts[0]);
                const minutes = parseInt(parts[1]);
                if (!isNaN(hours) && !isNaN(minutes)) {
                    duration = (hours * 60 + minutes) * 60
                } 
                
            } else {
                const possibleFloat = parseFloat(e.target.value)
                if (!isNaN(possibleFloat)) {
                    duration = parseInt(Math.round(possibleFloat * 60)) * 60
                }
            }
            setEditTime({...editTime, [propertyName]: e.target.value, duration })
        } else {
            setEditTime({...editTime, [propertyName]: e.target.value })
        }
    }

    const onEditTimehandleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submitEditTime()
          }
    }

    React.useEffect(() => {
        let days = []
        let d = getDateOfISOWeek(week[1], week[0])
        d.setUTCHours(0, 0, 0)      
        for (let i = 0; i < 7; i++) {
            days.push(new Date(d.valueOf()));
            d.setDate(d.getDate() + 1)
        }
        setDays(days)       
        const start = days[0].toISOString()
        let endDate = days[6]
        endDate.setUTCHours(23, 59, 59, 999)
        const end = endDate.toISOString()
        API
        .get("billing", "/billing?action=listTimeAndProjectsForUser&org="+props.org+"&start="+start+"&end="+end+"&selectedUserID="+selectedUserID, {})
        .then(res => {
            let newProjectsToShow = []
            Object.entries(res.helper).forEach( helperKey => {
                const helperKeyParts = helperKey[0].split("|")
                if(helperKeyParts.length === 6) {
                    newProjectsToShow.push({
                        projectID: helperKeyParts[0] + "|" + helperKeyParts[1] + "|" + helperKeyParts[2],
                        taskID: helperKeyParts[3] + "|" + helperKeyParts[4] + "|" + helperKeyParts[5]
                    })
                }
            })
            if(newProjectsToShow.length > 0) { // if we have projects to show (rows), then set the state
                setProjectsToShow(newProjectsToShow)
            }
            setUserProjects(res.projectNames)
            setTimesheetEntries({
                entries: res.timesheetEntries,
                helper: res.helper,
            })
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [week, props.org, selectedUserID])

    function onClickEditTime(project, dayIndex) {
        const day = days[dayIndex]
        setShowEditTime({show: true, day: day})
        const index = project.projectID + "|" + project.taskID
        let description = ""
        let strDuration = ""
        let duration = 0
        let locked = false
        if(timesheetEntries.helper.hasOwnProperty(index) && timesheetEntries.helper[index][dayIndex] !== -1) {
            const entry = timesheetEntries.entries[timesheetEntries.helper[index][dayIndex]]
            description = entry.description
            strDuration = durationToString(entry.duration)
            duration = entry.duration
            locked = entry.locked
        }
        setEditTime({
            projectID: project.projectID,
            taskID: project.taskID,
            description: description,
            strDuration: strDuration,
            duration: duration,
            prevDuration: duration,
            dayIndex: dayIndex,
            entryDate: day,
            locked: locked,
        })
    }
    function onClickCloseAddRow() {
        setShowAddRow(false)
    }
    function onClickCloseEditTime() {
        setShowEditTime({...showEditTime, show: false})
    }
    function getSubmitParams(newOrDelete) {
        // make sure all dates are at 00:00:00 time
        let entryDate = editTime.entryDate
        entryDate.setUTCHours(0, 0, 0, 0, 0)
        // submit
        const projectIssueDate = getFullIDFromProjectOrTask(editTime.projectID).issueDate
        const projectTaskIssueDate = getFullIDFromProjectOrTask(editTime.taskID).issueDate
        const params = {
            body: {
                action: newOrDelete + "TimesheetEntry",
                timesheetEntry: {
                    entrySortKey: {
                        entryDate: entryDate.toISOString(),
                        projectTaskIssueDate: projectTaskIssueDate,
                        projectIssueDate: projectIssueDate,
                        userID: selectedUserID,
                        entryNumber: 0,
                    },
                    description: editTime.description,
                    duration: editTime.duration,
                    
                },
                project: getProjectFromUserProjects(projectIssueDate),
                org: props.org,
            },
        };
        return params
    }
    function getProjectFromUserProjects(projectIssueDate) {
        const projectsFiltered = userProjects.filter(project => {return project.issueDate === projectIssueDate})
        if(projectsFiltered.length > 0) {
            return projectsFiltered[0]
        }
        return {}
    }
    function deleteEntry() {
        setShowEditTime({...showEditTime, submitting: true})
        // do post
        API
        .post("billing", "/billing", getSubmitParams("delete"))
        .then(deletedEntry => {
            const index = props.org + "|project|" + deletedEntry.deleted.entrySortKey.projectIssueDate + "|" + props.org + "|task|" + deletedEntry.deleted.entrySortKey.projectTaskIssueDate
            let indexValue = [-1,-1,-1,-1,-1,-1,-1]
            if(timesheetEntries.helper.hasOwnProperty(index)) {
                indexValue = timesheetEntries.helper[index]
            }
            indexValue[editTime.dayIndex] = -1

            setTimesheetEntries({
                ...timesheetEntries,
                helper: {...timesheetEntries.helper, [index]: indexValue},
            })

            setShowEditTime({...showEditTime, show: false, submitting: false})

            // update budget
            let newUserProjects = [ ...userProjects ]
            newUserProjects.forEach((userProject, i) => {
                if(userProject.issueDate === deletedEntry.deleted.entrySortKey.projectIssueDate) {
                    newUserProjects[i].budget.hoursUsed -= deletedEntry.deleted.duration / 3600
                    newUserProjects[i].budget.percentageUsed = userProjects[i].budget.hoursUsed / userProjects[i].budget.totalHours
                }
            })
            setUserProjects(newUserProjects)
        })
        .catch(error => {
            setErrorMessage(error.message);
            setShowEditTime({...showEditTime, show: false, submitting: false})
        });
    }
    function submitEditTime() {
        setShowEditTime({...showEditTime, submitting: true})
        const prevDuration = editTime.prevDuration
        // do post
        API
        .post("billing", "/billing", getSubmitParams("new"))
        .then(timesheetEntry => {
            const index = props.org + "|project|" + timesheetEntry.entrySortKey.projectIssueDate + "|" + props.org + "|task|" + timesheetEntry.entrySortKey.projectTaskIssueDate
            let indexValue = [-1,-1,-1,-1,-1,-1,-1]
            if(timesheetEntries.helper.hasOwnProperty(index)) {
                indexValue = timesheetEntries.helper[index]
            }
            indexValue[editTime.dayIndex] = timesheetEntries.entries.length

            setTimesheetEntries({
                entries: [...timesheetEntries.entries, timesheetEntry],
                helper: {...timesheetEntries.helper, [index]: indexValue},
            })
            setShowEditTime({...showEditTime, show: false, submitting: false})

            // update budget
            let newUserProjects = [ ...userProjects ]
            newUserProjects.forEach((userProject, i) => {
                if(userProject.issueDate === timesheetEntry.entrySortKey.projectIssueDate) {
                    newUserProjects[i].budget.hoursUsed -= prevDuration / 3600 // ensure we remove any previous duration entry that has now been overwritten
                    newUserProjects[i].budget.hoursUsed += timesheetEntry.duration / 3600 
                    newUserProjects[i].budget.percentageUsed = userProjects[i].budget.hoursUsed / userProjects[i].budget.totalHours
                }
            })
            setUserProjects(newUserProjects)    
        })
        .catch(error => {
            setErrorMessage(error.message);
            setShowEditTime({...showEditTime, show: false, submitting: false})
        });
    }
    function getFullIDFromProjectOrTask(key) {
        let keySplitted = key.split("|")
        if(keySplitted.length > 1) {
            const issueDate = keySplitted.pop()
            const id = keySplitted.join("|")
            return {
                id: id,
                issueDate: issueDate,
            }
        }
        return {}
    }
    function submitAddRow() {
        setShowAddRow(false)
        if(addRow.projectID !== "" || addRow.taskID !== "") {
            // check whether project is not already added
            let alreadyAdded = false
            projectsToShow.forEach(project => {
                if(project.projectID === addRow.projectID && project.taskID === addRow.taskID) {
                    alreadyAdded = true
                }
            })
            if(!alreadyAdded) {
                setProjectsToShow([...projectsToShow, addRow ])
            } else {
                setWarningMessage("Project is already added.")
            }
            // cleanup modal
            setAddRow({
                projectID: "",
                taskID: "",
            }) 
        }
    }

    function previousWeek() {
        let newDate = days[0]
        setProjectsToShow([])
        newDate.setDate(newDate.getDate() - 1) // this will get us in the previous week
        setWeek(getWeekNumber(newDate))
        navigate("/timesheet/" + newDate.getUTCFullYear() + "/" + (newDate.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + newDate.getUTCDate().toString().padStart(2, '0') + "/" + selectedUserID)
    }
    function nextWeek() {
        let newDate = days[6]
        setProjectsToShow([])
        newDate.setDate(newDate.getDate() + 1) // this will get us in the next week
        setWeek(getWeekNumber(newDate))
        navigate("/timesheet/" + newDate.getUTCFullYear() + "/" + (newDate.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + newDate.getUTCDate().toString().padStart(2, '0') + "/" + selectedUserID)
    }

    function durationToString(duration) {
        const durationHours = duration / 3600
        const durationMinutes = Math.round((durationHours - Math.floor(duration / 3600)) * 60)
        return Math.floor(durationHours).toString() + ":" + durationMinutes.toString().padStart(2, '0')
    }
    function handleUserChange(userID) {
        setLoading(true)
        setProjectsToShow([])
        setSelectedUserID(userID)
        navigate("/timesheet/" + days[0].getUTCFullYear() + "/" + (days[0].getUTCMonth() + 1).toString().padStart(2, '0') + "/" + days[0].getUTCDate().toString().padStart(2, '0') + "/" + userID)
    }

    function getEntryTotals() {
        let totals = [0, 0, 0, 0, 0, 0, 0]

        if(!timesheetEntries.hasOwnProperty("helper")) {
            return totals
        }

        userProjects.forEach(userProject => {
            userProject.tasks.forEach(task => {
                const helperIndex = userProject.id + "|" + userProject.issueDate + "|" + task.id + "|" + task.issueDate
                const index = timesheetEntries.helper.hasOwnProperty(helperIndex) ? timesheetEntries.helper[helperIndex] : []
                for(let i = 0; i < index.length; i++) {
                    if (index[i] !== -1) {
                        totals[i] += timesheetEntries.entries[index[i]].duration
                    }
                }
            })
        })
        return totals
    }

    if(days.length === 7) {
        if(loading) {
            return (
                <div>
                    <Header days={days} {...props} selectedUserID={selectedUserID} handleUserChange={handleUserChange} />
                    <Spinner animation="border"/>
                </div>
            )
        }
        return (
            <div>
                <Header days={days} {...props} selectedUserID={selectedUserID} handleUserChange={handleUserChange} />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
                }
                {warningMessage !== "" ?
                    <Alert variant="warning" onClose={() => setWarningMessage("")} dismissible>
                        {warningMessage}
                    </Alert>
                    : ""
                }
                <Table striped bordered hover className="timesheet-table">
                <thead>
                    <EntriesHeader days={days} previousWeek={previousWeek} nextWeek={nextWeek} showPreviousNextWeek={true} />
                </thead>
                <tbody>
                    {projectsToShow.map(project => (
                        <EntryLine key={project.projectID + "|" + project.taskID} project={project} userProjects={userProjects} timesheetEntries={timesheetEntries} onClickEditTime={onClickEditTime} durationToString={durationToString} readOnly={false} />
                    ))}
                    <tr>
                       <td key="addRow"><Button onClick={() => setShowAddRow(true)}>Add Row <Plus size={18} /></Button></td>
                       {getEntryTotals().map((value, index) => (
                           <td className="timesheet-totals" key={index}>{projectsToShow.length === 0 ? "" : durationToString(value)}</td>
                       ))}
                    </tr>
                    { props.organizationAttributes.configuration.timesheetApproval ?
                    <tr className="timesheet-approval">
                        <td colSpan="8" className="timesheet-approval">
                            <SubmitApproval {...props} days={days} monthFormatter={monthFormatter} setErrorMessage={setErrorMessage} selectedUserID={selectedUserID} />
                        </td>
                    </tr>
                :
                    null
                }
                </tbody>
                </Table>
                
                <Modal
                    show={showEditTime.show}
                    onHide={onClickCloseEditTime}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Time of {showEditTime.day.getUTCDate()} {monthFormatter.format(showEditTime.day)}. {showEditTime.day.getUTCFullYear()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Row>
                        <Col sm="8">
                        <Form.Control as="textarea" rows={3} placeholder="Description..." onChange={editTimeChange('description')} value={editTime.description} />
                        </Col>
                        <Col sm="4">
                            <Row>
                                <Form.Control placeholder="0:00" onChange={editTimeChange('strDuration')} value={editTime.strDuration} onKeyDown={onEditTimehandleKeyDown} />
                            </Row>
                        </Col>
                    </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    {props.role === "admin" || !editTime.locked ? 
                        <div className="delete-entry-wrapper">
                            <Button className="delete-entry" variant="outline-danger" size="sm" onClick={deleteEntry}><X size={14}/> Delete Entry</Button>
                        </div>
                    :
                        null
                    }
                    <Button variant="secondary ms-auto" onClick={onClickCloseEditTime} disabled={showEditTime.submitting}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={submitEditTime} disabled={editTime.locked || showEditTime.submitting}>Save {editTime.locked ? <Lock size={18} /> : <Save size={18} />}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAddRow}
                    onHide={onClickCloseAddRow}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Add Row</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                    <Row>
                        <Col>
                        Project
                        <Form.Select className="form-select form-select-sm" onChange={onEditAddRowChange('projectID')} value={addRow.projectID}>
                            <option key="default" value="">Select project</option>
                            {userProjects.map((userProject, index) => (
                                <option key={userProject.id + "|" + userProject.issueDate} value={userProject.id + "|" + userProject.issueDate}>{userProject.clientName}: {userProject.name}</option>
                            ))}
                        </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            Task
                            <Form.Select className="form-select form-select-sm" onChange={onEditAddRowChange('taskID')} value={addRow.taskID}>
                            <option key="default" value="">Select task</option>
                            {addRow.projectID !== "" ? userProjects.map((userProject, index) => {
                                if(addRow.projectID === userProject.id + "|" + userProject.issueDate) {
                                    return userProject.tasks.map(task => (
                                        <option key={task.id + "|" + task.issueDate} value={task.id + "|" + task.issueDate}>{task.taskName} ({task.billable ? "billable" : "non-billable" })</option>
                                    ))
                                } else {
                                    return (null)
                                }
                            }) : ""}
                                    
                            </Form.Select>
                        </Col>
                    </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={onClickCloseAddRow}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={submitAddRow}>Add <Plus size={18} /></Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else {
        return (
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Timesheet - Week of {days[0]} {JSON.stringify(days)}</h1> 
            loading
            </div>
            </div>
        )
    }
}

export default Timesheet
import React from 'react';

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table'


import { API } from "aws-amplify";

class Ponto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            accounts: [],
        };
    }
    componentDidMount() {
        if(this.props.code !== "" && this.props.state !== "") {
            this.setState({loading: true})
            const params = {
                body: {
                    action: "pontoCallback",
                    code: this.props.code,
                    state: this.props.state,
                    org: this.props.org,
                },
            };
            API
            .post("billing", "/billing", params)
            .then(response => {
                this.setState({loading: false})
            })
            .catch(error => {
                console.log(error);
            });
        } else if(this.props.auth) {
            this.pontoAuth()
        } else {
            this.setState({loading: true})
            API
            .get("billing", "/billing?action=listPontoAccounts&org="+this.props.org, {})
            .then(response => {
                this.setState({loading: false, accounts: response.pontoAccounts})
            })
            .catch(error => {
                this.setState({loading: false})
                console.log(error);
            });
    
        }
    }
    pontoAuth() {
        this.setState({loading: true})
        API
        .get("billing", "/billing?action=getPontoAuthURL&org="+this.props.org, {})
        .then(response => {
            if(response.url !== "") {
                window.location.href = response.url; 
                return null;           
            }
        })
        .catch(error => {
            console.log(error);
        });

    }
    addAccounts(accounts) {
        let items = [];
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
        });
        if(accounts.length > 0) {
            accounts.forEach((account, i) => {
              const key = account.reference
              items.push(
                  <tr key={key}>
                      <td>{account.holderName}</td>
                      <td>{account.reference}</td>
                      <td>{formatter.format(account.currentBalance)}</td>
                      <td>{formatter.format(account.availableBalance)}</td>
                  </tr>);
            })
          }
          return items
    }
    render() {
        if(this.props.code !== "" && this.props.state !== "") {
            return (
                <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Ponto Authentication</h1>
                </div>
                {!this.state.loading ?
                    <p>Succesfully completed Ponto authentication</p>
                    :
                    <Spinner animation="border"/>
                }
                
                </div>
            
            )
        } else {
            if(this.state.loading) {
                return (
                    <div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Ponto Integration</h1>
                        </div>
                        <Spinner animation="border"/>
                    </div>
                )
            } else if(this.state.accounts !== null && this.state.accounts.length > 0) {
                return (
                    <div>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Linked Ponto Accounts</h1>
                        </div>
                        <Table>
                            <thead>
                                <tr key="header">
                                    <th>Holder Name</th>
                                    <th>Account</th>
                                    <th>Current Balance</th>
                                    <th>Available Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.addAccounts(this.state.accounts)}
                            </tbody>
                        </Table>
                    </div>
                )
            } else {
                return (
                    <div>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Ponto</h1>
                    </div>
                    <Button onClick={() => this.pontoAuth()} disabled={this.state.loading}>Ponto Auth</Button>
                    </div>
                )
            }
            
        }
    }
}
export default Ponto;
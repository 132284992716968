import React, {useState} from "react"
import { Doughnut } from "react-chartjs-2";

import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner";
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Row from "react-bootstrap/Row"

function Estimates(props) {
    const [dashboardType, setDashboardType] = useState("")

    const [data, setData] = useState({
        dashboardData: {},
        dashboardDataClients: {},
        dashboardDataBillables: {},
    })
    React.useEffect(() => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: props.preferredCurrency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        const dashboardOptions = {
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            if(props.organizationAttributes.configuration.privacyMode) return "***"
                            var label = context.label || '';
    
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                label += new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: props.preferredCurrency,
                                }).format(context.parsed)
                            }
                            return label;
                        }
                    }
                },
                elements: {
                    center: {
                      text: props.organizationAttributes.configuration.privacyMode ? "***" : formatter.format(Math.floor(props.data.total)),
                      color: '#FF6384', // Default is #000000
                      fontStyle: 'Arial', // Default is Arial
                      sidePadding: 30, // Default is 20 (as a percentage)
                      minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                      maxFontSize: 20,
                      lineHeight: 25 // Default is 25 (in px), used for when text wraps
                    }
                  }
            },                      
        }
        const backgroundColorsContractors = [
            "#c2e7ff",
            "#d6e2ff",
            "#ebdcff",
            "#fcd8f5",
            "#fcd8f5",
            "#ffd6d5",
            "#ffd9c6",
            "#fbddbe",
        ]
        const borderColorsContractors = [
            "#9cc3dc",
            "#b4c2e4",
            "#d0bee8",
            "#eabce1",
            "#fcd8f5",
            "#ffbfbf",
            "#ffc7aa",
            "#ffd29f",
        ]
        const backgroundColors = [
            "#003f5c",
            "#516795",
            "#806ba6",
            "#b36ca8",
            "#de6d9a",
            "#fd787e",
            "#ff905c",
            "#ffb13e",
        ]
        const borderColors = [
            "#305d7a",
            "#2f4b7c",
            "#665191",
            "#a05195",
            "#d45087",
            "#f95d6a",
            "#ff7c43",
            "#ffa600",
        ]

        if(!props.data.hasData) {
            return
        }

        let newData = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [...backgroundColorsContractors.slice(0, Object.keys(props.data.userTotals).length), ...backgroundColors],
                    borderColor: [...borderColorsContractors.slice(0, Object.keys(props.data.userTotals).length), ...borderColors],
                }
            ]
        };

        let newDataClients = { 
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,
                }
            ]
            }
        let newDataBillables = { 
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: backgroundColorsContractors,
                    borderColor: borderColorsContractors,
                }
            ]
        }

        let billablesTotal = 0.00
        let clientsTotal = 0.00

        Object.keys(props.data.userTotals).forEach(contractor => {
            newData.labels.push(props.organizationAttributes.configuration.privacyMode ? "***" : contractor)
            newData.datasets[0].data.push(props.data.userTotals[contractor])
            newDataBillables.labels.push(props.organizationAttributes.configuration.privacyMode ? "***" : contractor)
            newDataBillables.datasets[0].data.push(props.data.userTotals[contractor])
            billablesTotal += props.data.userTotals[contractor]
        })    
    
        props.data.recurringItems.forEach((item) => {
            newData.labels.push(props.organizationAttributes.configuration.privacyMode ? "***" : item.client)
            newData.datasets[0].data.push(item.amount.reduce((s, a) => s + a, 0))
            newDataClients.labels.push(props.organizationAttributes.configuration.privacyMode ? "***" : item.client)
            newDataClients.datasets[0].data.push(item.amount.reduce((s, a) => s + a, 0))
            clientsTotal += item.amount.reduce((s, a) => s + a, 0)
        })
        
        setData({ 
            dashboardData: newData,
            dashboardOptions: { ...dashboardOptions, plugins: { ...dashboardOptions.plugins, elements: { center: { ...dashboardOptions.plugins.elements.center, text: props.organizationAttributes.configuration.privacyMode ? "***" : formatter.format(clientsTotal+billablesTotal) } } } },
            dashboardDataClients: newDataClients,
            dashboardOptionsClients: { ...dashboardOptions, plugins: { ...dashboardOptions.plugins, elements: { center: { ...dashboardOptions.plugins.elements.center, text: props.organizationAttributes.configuration.privacyMode ? "***" : formatter.format(clientsTotal) } } } },
            dashboardDataBillables: newDataBillables,
            dashboardOptionsBillables: { ...dashboardOptions, plugins: { ...dashboardOptions.plugins, elements: { center: { ...dashboardOptions.plugins.elements.center, text: props.organizationAttributes.configuration.privacyMode ? "***" : formatter.format(billablesTotal) } } } },
        })
        setDashboardType("clients") // default dashboard
    },[props.data, props.preferredCurrency, props.organizationAttributes.configuration.privacyMode])

    // from https://stackoverflow.com/a/43026361
    const chartPlugins = [
        {
            beforeDraw: function(chart) {
                if (chart.config.options.plugins.elements.center) {
                    // Get ctx from string
                    const { ctx } = chart;
            
                    // Get options from the center object in options
                    var centerConfig = chart.config.options.plugins.elements.center;
                    var fontStyle = centerConfig.fontStyle || 'Arial';
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var maxFontSize = centerConfig.maxFontSize || 75;
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                    // Start with a base font of 30px
                    ctx.font = "30px " + fontStyle;
            
                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
            
                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = (chart.innerRadius * 2);
            
                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    var minFontSize = centerConfig.minFontSize;
                    var lineHeight = centerConfig.lineHeight || 25;
                    var wrapText = false;
            
                    if (minFontSize === undefined) {
                    minFontSize = 20;
                    }
            
                    if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                    }
            
                    // Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontSizeToUse + "px " + fontStyle;
                    ctx.fillStyle = color;
            
                    if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                    }
            
                    var words = txt.split(' ');
                    var line = '';
                    var lines = [];
            
                    // Break words up into multiple lines if necessary
                    for (var n = 0; n < words.length; n++) {
                    var testLine = line + words[n] + ' ';
                    var metrics = ctx.measureText(testLine);
                    var testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                        lines.push(line);
                        line = words[n] + ' ';
                    } else {
                        line = testLine;
                    }
                    }
            
                    // Move the center up depending on line height and number of lines
                    centerY -= (lines.length / 2) * lineHeight;
            
                    for (let nn = 0; nn < lines.length; nn++) {
                    ctx.fillText(lines[nn], centerX, centerY);
                    centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
                }
        }
    ]
    
    function switchDashboardType(newDashBoardType) {
        setDashboardType(newDashBoardType)
    }
    

            

    if (!props.data.hasData) {
        return (
            <Spinner type="border" />
        )
    }
    return (
        <div className="chart-estimates">
            <Row>
            <div>
                <ButtonGroup size="sm">
                    <Button active={dashboardType === "clients" ? true : false} variant="secondary" onClick={() => switchDashboardType("clients")}>Client Contracts</Button>
                    <Button active={dashboardType === "billables" ? true : false} variant="secondary" onClick={() => switchDashboardType("billables")}>Billables</Button>
                    <Button active={dashboardType === "all" ? true : false} variant="secondary" onClick={() => switchDashboardType("all")}>All</Button>
                </ButtonGroup>
            </div>
            </Row>
            <Row className="mt-2">
                {dashboardType === "all" ?
                    <Doughnut 
                    data={data.dashboardData}
                    options={data.dashboardOptions}
                    className="chart-estimates"
                    plugins={chartPlugins}
                    />
                    : null
                }
                {dashboardType === "billables" ?
                    <Doughnut 
                    data={data.dashboardDataBillables}
                    options={data.dashboardOptionsBillables}
                    className="chart-estimates"
                    plugins={chartPlugins}
                    />
                    : null
                }
                {dashboardType === "clients" ?
                    <Doughnut 
                    data={data.dashboardDataClients}
                    options={data.dashboardOptionsClients}
                    className="chart-estimates"
                    plugins={chartPlugins}
                    />
                    : null
                }
            </Row>

        </div>

    )
}
export default Estimates;
import {Link, useNavigate} from 'react-router-dom';
import React from 'react';


function Landing(props) {
    const navigate = useNavigate()
    React.useEffect(() => {
        ;
        if(props.org !== "" && props.role === "admin") {
            navigate("/dashboard")
        } else if (props.org !== "" && (props.role === "user" || props.role === "contractor")) {
            navigate("/timesheet")
        } else if (props.org !== "" && (props.role === "user" || props.role === "client")) {
            navigate("/timesheetReports")
        }
    }, [props.org, props.role, navigate])
    return (
        <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">No access</h1>
        </div>
        <div>
            You currently have no access to an organization. <Link to="/setup">Create a new organization.</Link>
        </div>
        </div>
        
    )
}
export default Landing;

import React, { useState } from "react";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Archive, CheckCircle } from "react-feather";

function SelectArchived(props) {
    const [selected, setSelected] = useState(capitalizeFirstLetter(props.selected) + " " + props.type)

    const onChange = (value) => {
        setSelected(value + " " + props.type)
        props.onChange(value)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <DropdownButton id="dropdown-basic-button" title={"Filter: " + selected} variant="white border-secondary">
            <Dropdown.Item onClick={() => onChange("active")}><CheckCircle size={16} /> Active {props.type}</Dropdown.Item>
            <Dropdown.Item onClick={() => onChange("archived")}><Archive size={16} /> Archived {props.type}</Dropdown.Item>
        </DropdownButton>
    )
}

export default SelectArchived;
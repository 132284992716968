import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import React, { useState } from "react";
import { Save } from 'react-feather';


function EditUser(props) {
    const [user, setUser] = useState(props.user)

    const onUserFieldChange = (propertyName) => (event) => {
        if(props.emailReadonly && propertyName === "email") {
            return
        }
        setUser({...user,  [propertyName]: event.target.value})
    }

    function submitEditUser() {
        props.onSubmit(user)
    }
    return (
    <Form.Group>
    <Row className="mt-3">
        <Col md="4">
            User First name, Last name
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="First name"  onChange={onUserFieldChange('firstName')} value={user.firstName}/>
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="Last name" onChange={onUserFieldChange('lastName')} value={user.lastName}/>
        </Col>
    </Row>
    <Row className="mt-3">
    <Col md="4">
            User Email
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="email@address" readOnly={props.emailReadonly} onChange={onUserFieldChange('email')} value={user.email}/>
        </Col>
    </Row>
    <Row className="mt-3">
    <Col md="4">
            Role
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onUserFieldChange("role")} value={user.role}>
                    <option value="contractor">Contractor</option>
                    <option value="client">Client</option>
                    <option value="admin">Admin</option>
            </Form.Select>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="2">
            <Button variant="primary" disabled={props.submitting} onClick={submitEditUser}>
                <span>Save <Save size={18} /></span>
            </Button>
        </Col>
    </Row>


    </Form.Group>
    )
}
export default EditUser

import React, { useState } from "react";
import { API } from '@aws-amplify/api'
import Alert from 'react-bootstrap/Alert'

import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/esm/Spinner";

function SelectContact(props) {
    const [selected, setSelected] = useState(props.default)
    const [errorMessage, setErrorMessage] = useState('');
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        API
        .get("billing", "/billing?action=listContacts&org="+props.org, {})
        .then(contacts => {
            setContacts(contacts)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }, [props.role, props.org])

    function getFullIDFromClient(key) {
        let keySplitted = key.split("|")
        if(keySplitted.length > 1) {
            const issueDate = keySplitted.pop()
            const id = keySplitted.join("|")
            return {
                id: id,
                issueDate: issueDate,
            }
        }
        return {}
    }

    const onChange = (e) => {
        setSelected(e.target.value)
        const parts = getFullIDFromClient(e.target.value)
        contacts.forEach(contact => {
            if(contact.id === parts.id && contact.issueDate === parts.issueDate) {
                props.onChange(contact)
                return
            }
        })
        
    };

    if(loading) {
        return (
            <Spinner animation="border"/>
        )
    }

    return (
        <div>
        <Form.Select className="form-select form-select-sm" onChange={(e) => onChange(e)} value={selected}>
            <option key="default" value="">Select {props.displayName}</option>
            {Object.entries(contacts).map(([key, contact], index) => (
                <option key={key} value={contact.id + "|" + contact.issueDate}>{contact.name}</option>
            ))}
        </Form.Select>
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        </div>
    )
}

export default SelectContact;
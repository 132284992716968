import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

import { FilePlus } from 'react-feather'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import UserListItem from './UserListItem'
import SelectArchived from '../common/SelectArchived'

function Header(props) {
    function onChangeSelectArchived(value) {
        props.onChangeSelectArchived(value)
    }
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Users</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="Select active / archived" className="mx-3">
                <SelectArchived onChange={onChangeSelectArchived} type="Users" selected={props.selectedArchivedValue} />
            </ButtonGroup>
            <ButtonGroup aria-label="New users group">
                <Link to="/newUser"><button type="button" className="btn btn-sm btn-outline-secondary"><FilePlus size={16} /> New User</button></Link>
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function ListUsers(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showArchived, setShowArchived] = useState('active')

    React.useEffect(() => {
        let url = "/billing?action=listUsers&org="+props.org
        if(showArchived === "archived") {
            url += "&archived=true"
        }
        API
        .get("billing", url, {})
        .then(users => {
            setUsers(users)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, showArchived])

    function onChangeSelectArchived(value) {
        setLoading(true)
        setShowArchived(value)
    }


    if(loading) {
        return (
            <div>
                <Header onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} />
                <Spinner animation="border"/>
            </div>
        )
    }

    return (
        <Container>
            <Header onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} />
            {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                User saved
            </Alert>
            : ""
            }   
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
                }
            <Table striped bordered hover>
                    <thead>
                        <tr key="header">
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, _) => (
                            <UserListItem
                            key={user.id + "|" + user.userID}
                            user={user}
                            org={props.org}
                            myroute={props.myroute}
                            />
                        ))
                        }
                    </tbody>
                </Table>
        </Container>
    )
}
export default ListUsers;
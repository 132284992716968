import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

import { API } from '@aws-amplify/api';

import EditContact from './EditContact';
import {useNavigate} from 'react-router-dom';


function NewContact(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)

    const emptyContact = {
        name: "",
        street1: "",
        street2: "",
        zipcode: "",
        state: "",
        city: "",
        country: "",
        currency: props.preferredCurrency,
        tax: 0.00,
        strTax: "",
        taxID: "",
        contactDetails: [{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            mobile: "",
        }],
        user: {
            id: "",
            userID: "",
        },
        bankData: [
            {
                accountNumber: "",
                holderName: "",
            }
        ],
        invoiceNote: "",
    }

    function onSubmit(contact) {
        setSubmitting(true)
        const params = {
            body: {
                action: "newContact",
                contact: contact,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contact => {
            navigate(props.myroute + "?org="+props.org+"&showSaved=1")
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <Container>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">New Contact</h1>
        </div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }

        <EditContact submitting={submitting} onSubmit={onSubmit} id="" issueData="" contact={emptyContact} {...props} />

    </Container>
    )
}
export default NewContact;
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import EditSettings from './EditSettings'

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">{props.organizationAttributes.name}'s Settings</h1>
        </div>
    )
}

function Settings(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [organization, setOrganization] = useState({
        id: "",
        configuration: {},
    });

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    React.useEffect(() => {
        API
        .get("billing", "/billing?action=getOrganization&org="+props.org, {})
        .then(organization => {
            setOrganization(organization)
            setLoading(false)

        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }, [props.org])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    function onSubmit(organization) {
        setSubmitting(true)
        const params = {
            body: {
                action: "updateOrganization",
                organization: {
                    ...organization,
                    id: props.org + "|organization", // make sure to include orgID in id to pass authz
                },
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(organization => {
            setSubmitting(false)
            setShowSaved(true)
            scrollToTop()
        })
        .catch(error => {
            setErrorMessage(error.message);
            scrollToTop()
            setSubmitting(false)
        });
    }

    if(loading) {
        return (
            <div>
                <Header {...props} />
                {errorMessage !== "" ?
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    :
                        <Spinner animation="border"/>
                    }
                
            </div>
        )
    }

    return (
        <div>
        <Header {...props} />
        {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Organization Settings saved
            </Alert>
            : ""
            }  
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <EditSettings organization={organization} onSubmit={onSubmit} submitting={submitting} {...props} />
        </div>
    )
}
export default Settings;
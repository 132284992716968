import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

import { FilePlus } from 'react-feather'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import EditClientContract from './EditClientContract'

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Client Contract</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="New clientContracts group">
                <Link to="/newClientContract"><button type="button" className="btn btn-sm btn-outline-secondary"><FilePlus size={16} /> New ClientContract</button></Link>
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function GetClientContract(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [submitting, setSubmitting] = useState(false)

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [clientContract, setClientContract] = useState({})

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    React.useEffect(() => {
        API
        .get("billing", "/billing?action=getClientContract&org="+props.org + "&id=" + props.id + "&issueDate=" + props.issueDate, {})
        .then(clientContract => {
            if(clientContract.length > 0) {
                setClientContract({
                    ...clientContract[0],
                    startDate: clientContract[0].startDate.split("T")[0],
                    endDate: clientContract[0].endDate.split("T")[0] === "0001-01-01" ? "" : clientContract[0].endDate.split("T")[0],
                })
            } else {
                setErrorMessage("No clientContract returned")
            }
            setLoading(false)

        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, props.id, props.issueDate])

    function onSubmit(newClientContract) {
        setSubmitting(true)
        const params = {
            body: {
                action: "updateClientContract",
                clientContract: { ...newClientContract, 
                    startDate: new Date(newClientContract.startDate).toISOString(),
                    endDate: newClientContract.endDate === "" ? new Date("0001-01-01").toISOString() : new Date(newClientContract.endDate).toISOString()
                },
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(clientContract => {
            setSubmitting(false)
            setShowSaved(true)
            scrollToTop()
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
            scrollToTop()
        });
    }

    if(loading) {
        return (
            <div>
                <Header />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }

    return (
        <div>
        <Header />
        {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                ClientContract saved
            </Alert>
            : ""
            }  
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <EditClientContract contract={clientContract} onSubmit={onSubmit} submitting={submitting} {...props} />
        </div>
    )
    
}
export default GetClientContract;
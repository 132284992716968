import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ErrorMessage(props) {
    const [show, setShow] = useState(0);

    const handleClose = () => setShow(-1);
    const handleShow = () => setShow(1);
  
    const errorMessage = props.body
    if(show === 0 && errorMessage != null && errorMessage !== "") {
        handleShow()
    }

    return (
        <Modal show={show === 1} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error occurred</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

export default ErrorMessage;
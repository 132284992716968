// constants.js
const dev = {
    url: {
        //API_URL: "http://localhost:8080",
        API_URL: "https://04sj7hk223.execute-api.eu-west-1.amazonaws.com/Prod",
//        FRONTEND_URL: "https://billing.in4it.io/"
        FRONTEND_URL: "http://localhost:3000"
    },
    aws: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_8GRtKTns7",
        userPoolWebClientId: "48758cgq7vfsffudsfhd6toin3",
    }
  };
const prod = {
    url: {
        API_URL: "https://04sj7hk223.execute-api.eu-west-1.amazonaws.com/Prod",
        FRONTEND_URL: "https://billing.in4it.io/"
    },
    aws: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_8GRtKTns7",
        userPoolWebClientId: "48758cgq7vfsffudsfhd6toin3",
    }
  };
  
  export const config = window.location.hostname === "localhost" ? dev : prod
  
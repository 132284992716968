import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

import { API } from '@aws-amplify/api';

import EditUser from './EditUser';
import {useNavigate} from 'react-router-dom';


function NewUser(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)

    const emptyUser = {
        firstName: "",
        lastName: "",
        role: "contractor",
        email: "",
    }

    function onSubmit(user) {
        setSubmitting(true)
        const params = {
            body: {
                action: "newUser",
                user: user,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(user => {
            navigate(props.myroute + "?org="+props.org+"&showSaved=1")
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <Container>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">New User</h1>
        </div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }

        <EditUser submitting={submitting} onSubmit={onSubmit} id="" issueData="" user={emptyUser} emailReadonly={false} />

    </Container>
    )
}
export default NewUser;
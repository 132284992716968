import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'

import { CheckSquare, Layers, Square } from 'react-feather'
import ListSubmittedItem from './ListSubmittedItem'


function Header(props) {
    function onChangeSelectApproved(value) {
        props.onChangeFilter(value)
    }

    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{props.filter.charAt(0).toUpperCase() + props.filter.slice(1)} Timesheets</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="Select action">
                <DropdownButton id="dropdown-basic-button" title={"Filter: "+props.filter.charAt(0).toUpperCase() + props.filter.slice(1)} variant="white border-secondary">
                    <Dropdown.Item onClick={(e) => onChangeSelectApproved("unapproved")}><Square size={16} /> Unapproved Timesheets</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => onChangeSelectApproved("approved")}><CheckSquare size={16} /> Approved Timesheets</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => onChangeSelectApproved("all")}><Layers size={16} /> All Timesheets</Dropdown.Item>
                </DropdownButton> 
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function ListSubmitted(props) {
    const [showSaved, setShowSaved] = useState("");
    const [loading, setLoading] = useState(true);
    const [timesheets, setTimesheets] = useState({
        timesheetApprovals: [],
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [filter, setFilter] = useState("unapproved")

    React.useEffect(() => {
        const url = "/billing?action=listSubmittedTimesheets&org="+props.org+"&filter="+filter
        API
        .get("billing", url, {})
        .then(timesheets => {
            timesheets.timesheetApprovals.forEach((timesheet, index) => {
                timesheets.users.forEach(user => {
                    if(user.userID === timesheet.userID) {
                        timesheets.timesheetApprovals[index].user = user
                    }
                })
            })
            setTimesheets(timesheets)
            setLoading(false)
        })
        .catch(error => {
            if(API.isCancel(error)) return
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, filter])


    function onChangeFilter(value) {
        setLoading(true)
        setFilter(value)
    }

    function patchTimesheetApproval(timesheetApprovalToPatch) {
        const params = {
            body: {
                action: "patchTimesheetApproval",
                TimesheetApprovals: [timesheetApprovalToPatch],
                org: props.org,
            },
        };
        API
        .post("billing", "/billing", params)
        .then(response => {
            let newTimesheets = []
            timesheets.timesheetApprovals.forEach(timesheet => {
                let found = false
                if(timesheetApprovalToPatch.id === timesheet.id && timesheetApprovalToPatch.issueDate === timesheet.issueDate) {
                    found = true
                    if(filter === "approved" && timesheetApprovalToPatch.approved) {
                        newTimesheets.push(timesheet)
                    }
                    if(filter === "unapproved" && !timesheetApprovalToPatch.approved) {
                        newTimesheets.push(timesheet)
                    }
                    if(filter === "all") {
                        newTimesheets.push({ ...timesheet, approved: timesheetApprovalToPatch.approved, stopLoading: true })
                    }
                }    
                if(!found) {
                    newTimesheets.push(timesheet)
                }
            })
            setTimesheets({...timesheets, timesheetApprovals: newTimesheets})
            if (timesheetApprovalToPatch.approved) {
                setShowSaved("Approved "+ timesheetApprovalToPatch.user.firstName + " " + timesheetApprovalToPatch.user.lastName + "'s timesheet for week starting on "+ timesheetApprovalToPatch.startDate.split("T")[0])
            } else {
                setShowSaved("Unapproved "+ timesheetApprovalToPatch.user.firstName + " " + timesheetApprovalToPatch.user.lastName + "'s timesheet for week starting on "+ timesheetApprovalToPatch.startDate.split("T")[0])
            }
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }


    if(loading) {
        return (
            <div>
                <Header onChangeFilter={onChangeFilter} filter={filter} />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }

    return (
        <Container>
            <Header onChangeFilter={onChangeFilter} filter={filter} />
            {showSaved !== "" ?
            <Alert variant="success" onClose={() => setShowSaved("")} dismissible>
                {showSaved}
            </Alert>
            : ""
            }   
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
            }
            <Accordion>
                {timesheets.timesheetApprovals.map(timesheet =>
                    <ListSubmittedItem key={timesheet.id + "|" + timesheet.issueDate} {...props} timesheet={timesheet} patchTimesheetApproval={patchTimesheetApproval} />   
                )}
            </Accordion>
        </Container>
    )
}
export default ListSubmitted;
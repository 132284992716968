function BillHeader(props) {
    return (
        <thead>
            <tr key="header">
            <th></th>
            <th>Company Name</th>
            <th>Document Number</th>
            <th>Document Date</th>
            <th>Due Date</th>
            <th>Invoice Total</th>
            <th>Payment Details</th>
            <th>Status</th>
            <th>PDF</th>
            </tr>
        </thead>
    )
}

export default BillHeader;

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import React, { useState } from "react";
import { Save } from 'react-feather';

import SelectCurrency from "../common/SelectCurrency"


function EditSettings(props) {
    const [organization, setOrganization] = useState(props.organization)

    const onOrganizationFieldChange = (propertyName) => (event) => {
        setOrganization({...organization,  [propertyName]: event.target.value})
    }

    const onConfigurationFieldChange = (propertyName) => (event) => {
        let value = null
        if(event.target.value === "true" || event.target.value === "false") {
            value = event.target.value === "true" ? true : false
        } else if(isNumeric(event.target.value)) {
            value = parseInt(event.target.value)
        } else {
            value = event.target.value
        }
        setOrganization({
            ...organization,
            configuration: {
                ...organization.configuration,
                [propertyName]: value
            }
        })
    }

    function setCurrency(value) {
        setOrganization({
            ...organization,
            preferredCurrency: value
        })
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }

    function submitOrganization() {
        props.onSubmit(organization)
        setOrganization({
            ...organization,
            version: organization.version + 1,
        })
    }
    return (
        <Form.Group>
        <Row className="mt-3">
            <Col md="4">
                Slack Webhook URL (for Slack notifications)
            </Col>
            <Col md="5">
                <Form.Control size="sm" type="text" placeholder="Slack webhook URL" onChange={onOrganizationFieldChange('slackWebhook')} value={organization.slackWebhook}/>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                Enable timesheet approval
            </Col>
            <Col md="5">
                <Form.Select className="form-select form-select-sm" onChange={onConfigurationFieldChange('timesheetApproval')} value={organization.configuration.timesheetApproval.toString()}>
                    <option value="false">Disabled</option>
                    <option value="true">Enabled</option>
                </Form.Select>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                Enable Time Planning
            </Col>
            <Col md="5">
                <Form.Select className="form-select form-select-sm" onChange={onConfigurationFieldChange('timePlanning')} value={organization.configuration.timePlanning.toString()}>
                    <option value="false">Disabled</option>
                    <option value="true">Enabled</option>
                </Form.Select>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                Default Payment Terms (days)
            </Col>
            <Col md="5">
                <Form.Control size="sm" type="text" placeholder="Payment terms in days" onChange={onConfigurationFieldChange('defaultPaymentTerms')} value={organization.configuration.defaultPaymentTerms}/>      
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                Privacy Mode
            </Col>
            <Col md="5">
                <Form.Select className="form-select form-select-sm" onChange={onConfigurationFieldChange('privacyMode')} value={organization.configuration.privacyMode.toString()}>
                    <option value="false">Disabled</option>
                    <option value="true">Enabled</option>
                </Form.Select>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                Base Currency
            </Col>
            <Col md="5">
                <SelectCurrency onChange={setCurrency} default={organization.preferredCurrency} />
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="2">
                <Button variant="primary" disabled={props.submitting} onClick={submitOrganization}>
                    <span>Save <Save size={18} /></span>
                </Button>
            </Col>
        </Row>
        </Form.Group>
    )
}
export default EditSettings;
import React, { useState, useCallback } from "react";
import Table from 'react-bootstrap/Table'


import { API } from "aws-amplify";

import Spinner from 'react-bootstrap/Spinner';

import "bootstrap/dist/css/bootstrap.min.css";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import InvoiceActions from './InvoiceActions'
import InvoiceListItem from './InvoiceListItem'

import ErrorMessage from '../Error'

import Calendar from "react-calendar"

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import {Link} from 'react-router-dom';

import { FilePlus } from "react-feather";
import BillListItem from "./BillListItem";
import InvoiceHeader from "./InvoiceHeader";
import BillHeader from "./BillHeader";
import HeaderInvoiceActions from "./HeaderInvoiceActions";

function Header(props) {
    const [calendarValue, setCalenderValue] = useState(props.dateRange);
    const [showCalendar, setShowCalendar] = useState(false)
    const [message, setMessage] = useState('')
  
    function onCalendarChange(value) {
      setCalenderValue(value)
      setShowCalendar(false)
      props.onCalendarChange(value)
    }
    
    function getCalendarValue() {
        if(calendarValue[0].getFullYear() === calendarValue[1].getFullYear() && calendarValue[0].getMonth() === calendarValue[1].getMonth()) {
          return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0")
        } else {
          return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0") + " until " + calendarValue[1].getFullYear() + "-" + (calendarValue[1].getMonth()+1).toString().padStart(2, "0")
        }
    }

    return (
        <div>
            {message !== "" ?
                <Alert variant="success" onClose={() => setMessage("")} dismissible>
                    {message}
                </Alert>
            :
                null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">{props.role === "contractor" ? "Invoices" : props.type === "bill" ? "Bills" : "Invoices" }</h1>
                {props.role === "contractor" ?
                    <ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup aria-label="New Invoice group">
                            <Link to="/contractor/newInvoice"><button type="button" className="btn btn-sm btn-outline-secondary"><FilePlus size={16} /> New</button></Link>
                        </ButtonGroup>
                    </ButtonToolbar>
                :
                    null
                }
                <div>
                    <Button variant="outline-dark" onClick={() => setShowCalendar(!showCalendar)} className="mx-3">Date: {getCalendarValue()}</Button>
                    {showCalendar ? 
                    <Calendar className="calendar-overlay"
                    onChange={onCalendarChange}
                    value={calendarValue}
                    view="year"
                    maxDetail="year"
                    selectRange={true}
                    />
                    :
                    null
                    }
                    {props.role === "admin" ? <ButtonGroup aria-label="New Invoice group"><HeaderInvoiceActions {...props} setMessage={setMessage} /></ButtonGroup> : null}
                </div>
            </div>
    </div>
    )
}

function ListInvoices(props) {
    const [dateRange, setDateRange] = useState(initDateRange());
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [errorMessage, setErrorMessage] = useState('');

    const updateCheckedItems = (k, v) => {
        // pass a clone of storeMap
        setCheckedItems(new Map(checkedItems.set(k, v)));
    };

    const wrapperSetErrorMessage = useCallback(val => {
        setErrorMessage(val);
      }, [setErrorMessage]);
    const wrapperSetInvoices = val => {
        setInvoices(val);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.preferredCurrency,
    });

    function initDateRange() {
       let res = [new Date(), new Date()]
       if(props.role === "admin") {
           res[0].setMonth(res[0].getMonth() - 1)
           res[0].setDate(1)
       }
       if(props.role === "contractor") {
           res[0].setMonth(0)
           res[0].setDate(1)
       }
       return res
    }


    React.useEffect(() => {
        setLoading(true)
        const start = dateRange[0].toISOString()
        const end = new Date(dateRange[1].getFullYear(), dateRange[1].getMonth()+1, 0).toISOString()
        let action = ""
        switch(props.type) {
            case "bill":
                action = "listBillsForUser"
                if(props.role === "admin") {
                    action = "listBills"
                }
            break;
            case "invoice":
                action = "listInvoices"
                break;
            default:
                setErrorMessage("No billing/invoice type supplied")
            return
        }
        
        API
        .get("billing", "/billing?action="+action+"&org="+props.org+"&start="+start+"&end="+end, {})
        .then(invoices => {
            setInvoices(invoices)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, props.type, dateRange])

    function handleCheckboxChange(target) {
        const key = target.name;
        const isChecked = target.checked;
        updateCheckedItems(key, isChecked)
    }

    function viewPDF(invoiceID, invoiceIssueDate) {
        setLoading(true)
        API
        .get("billing", "/billing?action=getPresignedDownloadURL&org="+props.org+"&id="+invoiceID+"&issueDate="+invoiceIssueDate, {})
        .then(response => {
            if(response.url !== "") {
                setLoading(false)
                window.location.href = response.url; 
                return null;           
            }
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }

    function getInvoiceTotal() {
        let total = 0
        invoices.forEach(invoice => {
            total += invoice.invoiceTotal
        })
        return formatter.format(total)
    }

    function onCalendarChange(value) {
        setLoading(true)
        setDateRange(value)
    }
   
    if(loading) {
        return (
            <div>
                <Header {...props} onCalendarChange={onCalendarChange} dateRange={dateRange} />
                {errorMessage !== "" ?
                    <ErrorMessage body={errorMessage} />
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }
    return (
        <div>
            <Header {...props} onCalendarChange={onCalendarChange} dateRange={dateRange} />
            {errorMessage !== "" ?
                <Alert variant="warning" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                </Alert>
            :
                null
            }
            <Form>
            <Table striped bordered hover>
                {props.type === "bill" ?
                        <BillHeader />
                    :
                        <InvoiceHeader />
                }
                <tbody>
                    {invoices.map((invoice, index) => {
                        if(props.type === "bill") {
                            return (
                                <BillListItem
                                key={invoice.id + "|" + invoice.issueDate}
                                invoice={invoice}
                                checkedItems={checkedItems}
                                viewPDF={viewPDF}
                                preferredCurrency={props.preferredCurrency}
                                myroute={props.myroute}
                                org={props.org}
                                handleCheckboxChange={(e) => handleCheckboxChange(e)}/>
                            )
                        }
                        return (
                            <InvoiceListItem
                            key={invoice.id + "|" + invoice.issueDate}
                            invoice={invoice}
                            checkedItems={checkedItems}
                            viewPDF={viewPDF}
                            preferredCurrency={props.preferredCurrency}
                            myroute={props.myroute}
                            org={props.org}
                            handleCheckboxChange={(e) => handleCheckboxChange(e)}/>
                        )
                        })}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td colSpan={4} style={{textAlign: "right", fontWeight: "bolder"}}>
                            TOTAL:
                        </td>
                        <td style={{fontWeight: "bolder"}}>
                            {getInvoiceTotal()}
                        </td>
                        <td colSpan={3}>

                        </td>
                    </tr>
                </tfoot>
            </Table>
            {props.role === "admin" && props.type === "bill" ?
                <InvoiceActions items={checkedItems} invoices={invoices} setError={wrapperSetErrorMessage} setInvoices={wrapperSetInvoices} org={props.org}/>
                :
                ""
            }
            
            </Form>
        </div>
    )
    
}

export default ListInvoices;
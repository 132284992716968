import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import React, { useState } from "react";
import { Save } from 'react-feather';

import SelectContact from '../contacts/SelectContact'
import SelectTasks from './SelectTasks';
import SelectUsers from '../users/SelectUsers'
import SelectCurrency from '../common/SelectCurrency';
import ColorPicker from '../common/ColorPicker';

function EditProject(props) {
    const [project, setProject] = useState(props.project)
    const [checkedTasks, setCheckedTasks] = useState(new Map());
    const [tasksBudget, setTasksBudget] = useState(new Map());
    const [tasksRate, setTasksRate] = useState(new Map());
    const [checkedUsers, setCheckedUsers] = useState(new Map());

    React.useEffect(() => {
        if(!props.submitting && props.project.hasOwnProperty("tasks") && props.project.tasks !== null) {
            let defaultCheckedTasks = new Map()
            let defaultTasksBudget = new Map()
            let defaultTasksRate = new Map()
            props.project.tasks.forEach(task => {
                defaultCheckedTasks.set(task.id + "|"+task.issueDate, true)
                if(props.project.hasOwnProperty("budget") && props.project.budget.type === "task" && task.hasOwnProperty("budget")) {
                    defaultTasksBudget.set(task.id + "|"+task.issueDate, { 
                        frequency: task.budget.frequency,
                        value: task.budget.hours,
                        valueStr: task.budget.hours.toString(),
                    })
                }
                if(props.project.rateType === "task" && task.hasOwnProperty("hourlyRate")) {
                    defaultTasksRate.set(task.id + "|"+task.issueDate, { 
                        value: task.hourlyRate,
                        valueStr: task.hourlyRate.toString(),
                    })
                }
            })
            setCheckedTasks(defaultCheckedTasks);
            setTasksBudget(defaultTasksBudget)
            setTasksRate(defaultTasksRate)
        }
        if(!props.submitting && props.project.hasOwnProperty("team") && props.project.team !== null) {
            let defaultCheckedUsers = new Map()
            props.project.team.forEach(user => {
                defaultCheckedUsers.set(user.id + "|"+user.userID, true)
            })
            setCheckedUsers(defaultCheckedUsers)    
        }
    }, [props.project, props.submitting])

    const updateCheckedTasks = (k, v) => {
        // pass a clone of storeMap
        setCheckedTasks(new Map(checkedTasks.set(k, v)));
    };
    const updateTasksBudget = (k, v) => {
        // pass a clone of storeMap
        setTasksBudget(new Map(tasksBudget.set(k, v)));
    };
    const updateTasksRate = (k, v) => {
        // pass a clone of storeMap
        setTasksRate(new Map(tasksRate.set(k, v)));
    };
    const updateCheckedUsers = (k, v) => {
        // pass a clone of storeMap
        setCheckedUsers(new Map(checkedUsers.set(k, v)));
    };
    const onProjectFieldChange = (propertyName) => (event) => {
        setProject({...project,  [propertyName]: event.target.value})
    }
    const onProjectFieldChangeBool = (propertyName) => (event) => {
        setProject({...project,  [propertyName]: event.target.value === "enabled" ? true : false})
    }
    const onProjectFieldChangeColor = (color) => {
        setProject({...project, color})
    }
    const onProjectBudgetFieldChange = (propertyName) => (event) => {
        if(propertyName === "hours") {
            if(!isNumeric(event.target.value) && event.target.value !== "") {
                return
            }
            setProject({
                ...project,
                budget: { 
                    ...project.budget, 
                    [propertyName]: parseFloat(event.target.value),
                    strHours : event.target.value,
                }
              })
        } else {
            setProject({...project, budget: { ...project.budget, [propertyName]: event.target.value } })
        }
    }

    const setCurrency = (currency) => {
        setProject({...project,  currency})
    };

    function submitEditProject() {
        let projectToSubmit = project
        projectToSubmit.tasks = []
        projectToSubmit.team = []
        checkedTasks.forEach((checked, key) => {
            if(checked) {
                let task = getFullIDFromClientOrTask(key)
                if(projectToSubmit.budget.type === "task" && tasksBudget.has(key)) {
                    task.budget = {
                        type: "task",
                        frequency: tasksBudget.get(key).frequency,
                        hours: tasksBudget.get(key).value
                    }
                }
                if(projectToSubmit.rateType === "task" && tasksRate.has(key)) {
                    task.hourlyRate = tasksRate.get(key).value
                }
                projectToSubmit.tasks.push(task)
            }
        })
        checkedUsers.forEach((checked, key) => {
            if(checked) {
                projectToSubmit.team.push(getFullIDFromUser(key))
            }
        })
        if(projectToSubmit.rateType === "") {
            projectToSubmit.rateType = "project"
        }
        if(projectToSubmit.rateType === "project") {
            projectToSubmit.hourlyRate = parseFloat(projectToSubmit.hourlyRate)
        } else {
            projectToSubmit.hourlyRate = 0
        }
        props.onSubmit(projectToSubmit)
    }
    function getFullIDFromUser(key) {
        let keySplitted = key.split("|")
        if(keySplitted.length > 1) {
            const userID = keySplitted.pop()
            const id = keySplitted.join("|")
            return { id, userID }
        }
        return {}
    }
    function getFullIDFromClientOrTask(key) {
        let keySplitted = key.split("|")
        if(keySplitted.length > 1) {
            const issueDate = keySplitted.pop()
            const id = keySplitted.join("|")
            return {
                id: id,
                issueDate: issueDate,
            }
        }
        return {}
    }
    function setClient(newClient) {
        setProject({...project, client: { id: newClient.id, issueDate: newClient.issueDate } })
    }

    function handleTaskChange(target) {
        const key = target.name;
        const isChecked = target.checked;
        updateCheckedTasks(key, isChecked)
    }
    function handleTaskBudgetChange(field, target) {
        const frequency = tasksBudget.has(target.name) ? tasksBudget.get(target.name).frequency : ""
        const value = tasksBudget.has(target.name) ? tasksBudget.get(target.name).value : "" 
        const valueStr = tasksBudget.has(target.name) ? tasksBudget.get(target.name).valueStr : "" 
        if(field === "hours") {
            if(!isNumeric(target.value) && target.value !== "") {
                return
            }
            updateTasksBudget(target.name, {
                frequency: frequency === "" ? "monthly" : frequency,
                value: parseFloat(target.value),
                valueStr: target.value
            })
        } else {
            updateTasksBudget(target.name, {
                frequency: target.value,
                value: value,
                valueStr: valueStr,
            })
        }
    }
    function handleTaskRateChange(target) {
        if(!isNumeric(target.value) && target.value !== "") {
            return
        }
        updateTasksRate(target.name, {
            value: parseFloat(target.value),
            valueStr: target.value
        })
    }
    function handleUserChange(target) {
        const key = target.name;
        const isChecked = target.checked;
        updateCheckedUsers(key, isChecked)
    }
    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
    
    return (
    <Form.Group>
    <Row className="mt-5 d-flex align-items-center">
        <Col md="4">
            Project Name
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="Project name" onChange={onProjectFieldChange('name')} value={project.name}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Client
        </Col>
        <Col md="4">
        <SelectContact onChange={setClient} default={project.client.id + "|" + project.client.issueDate} org={props.org} displayName="Client" />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Currency
        </Col>
        <Col md="4">
            <SelectCurrency onChange={setCurrency} default={project.currency} />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Rate
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onProjectFieldChange('rateType')} value={project.rateType}>
                <option key="project" value="project">Single rate for project</option>
                <option key="task" value="task">Different rate for every task</option>
            </Form.Select>
        </Col>
    </Row>
    {project.rateType !== "task" ?
        <Row className="mt-3">
            <Col md="4">
                Hourly Rate
            </Col>
            <Col md="4">
                <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text>{project.currency}</InputGroup.Text>
                    <Form.Control size="sm" type="text" placeholder="0.00" onChange={onProjectFieldChange('hourlyRate')} value={project.hourlyRate}/>
                </InputGroup>
            </Col>
        </Row>
    :
        null
    }
    <Row className="mt-3">
        <Col md="4">
            Budget
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onProjectBudgetFieldChange('type')} value={project.budget.type}>
                <option key="none" value="none">None</option>
                <option key="project" value="project">Single Budget for project</option>
                <option key="task" value="task">Different budget for every task</option>
            </Form.Select>
        </Col>
    </Row>
    {project.budget.type === "project" ?
        <Row className="mt-3">
            <Col md="4">
            </Col>
            <Col md="4">
                <InputGroup className="mb-3" size="sm">
                    <Form.Control size="sm" type="text" placeholder="0.00" onChange={onProjectBudgetFieldChange('hours')} value={project.budget.strHours}/>
                    <InputGroup.Text>hours</InputGroup.Text>
                    <Form.Select className="ms-2 form-select form-select-sm" onChange={onProjectBudgetFieldChange('frequency')} value={project.budget.frequency}>
                        <option value="monthly">Monthly</option>
                        <option value="fixed">Fixed</option>
                    </Form.Select>
                </InputGroup>
            </Col>
        </Row>
    : null
    }
    <Row className="mt-3">
    <Col md="4">
            Tasks
        </Col>
        <Col md={4 + (project.rateType === "task" ? 1 : 0) + (project.budget.type === "task" ? 1 : 0)}>
            <SelectTasks 
                org={props.org}
                checkedTasks={checkedTasks}
                handleTaskChange={handleTaskChange}
                handleTaskBudgetChange={handleTaskBudgetChange}
                budgetPerTask={project.budget.type === "task" ? true : false}
                tasksBudget={tasksBudget}
                handleTaskRateChange={handleTaskRateChange}
                ratePerTask={project.rateType === "task" ? true : false}
                tasksRate={tasksRate}
                currency={project.currency}
            />
        </Col>
    </Row>
    <Row className="mt-3">
    <Col md="4">
            Team
        </Col>
        <Col md={4 + (project.rateType === "task" ? 1 : 0) + (project.budget.type === "task" ? 1 : 0)}>
            <SelectUsers org={props.org} checkedUsers={checkedUsers} handleUserChange={handleUserChange} />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Monthly Automatic Invoicing
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onProjectFieldChangeBool('autoInvoice')} value={project.autoInvoice ? "enabled" : "disabled"}>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
            </Form.Select>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Project Color
        </Col>
        <Col md="4">
            <ColorPicker
            color={ project.color }
            onChange={onProjectFieldChangeColor}
            />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="2">
            <Button variant="primary" disabled={props.submitting} onClick={submitEditProject}>
                <span>Save <Save size={18} /></span>
            </Button>
        </Col>
    </Row>
    </Form.Group>
    )
}
export default EditProject
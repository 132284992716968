import Container from 'react-bootstrap/Container';
import Alert from "react-bootstrap/Alert";
import { useState } from 'react';

import { API } from '@aws-amplify/api';

import EditClientContract from './EditClientContract';
import {useNavigate} from 'react-router-dom';


function NewClientContract(props) {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)

    const emptyContract = {
        name: "",
        tax: 0.00,
        strTax: "",
        contact: {
            currency: props.preferredCurrency,
        },
        billables: [{
            frequency: "monthly",
            lineItems: [
                {
                    type: "product",
                    description: "",
                    quantity: 0,
                    unitPrice: 0,
                }
            ],
        }],
        paymentTermsInDays: 30,
        purchaseOrderReference: "",
        startDate: new Date().toISOString().split("T")[0],
        endDate: "",
        hasEndDate: false,
        autoInvoice: true,
    }

    function onSubmit(contract) {
        setSubmitting(true)
        const params = {
            body: {
                action: "newClientContract",
                clientContract: { ...contract, 
                    startDate: new Date(contract.startDate).toISOString(),
                    endDate: contract.hasEndDate ? new Date(contract.endDate).toISOString() : new Date("0001-01-01").toISOString() 
                },
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contract => {
            navigate(props.myroute + "?showSaved=1")
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <Container>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">New Contract</h1>
        </div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }

        <EditClientContract submitting={submitting} onSubmit={onSubmit} id="" issueData="" contract={emptyContract} org={props.org} />

    </Container>
    )
}
export default NewClientContract;
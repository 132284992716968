import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

function PlanningLegend(props) {
    const swatch = {
        padding: '3px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        float: "left",
        verticalAlign: "center"
    }
    const timeoffColor = {
        width: '20px',
        height: '20px',
        borderRadius: '2px',
        background: "rgb(162, 96, 175)",
        fontWeight: "bold",
        textAlign: "center",
    }
    function getElementStyle(project) {
        let res = {}
        props.activeCell.forEach(activeProject => {
            if(activeProject.type === "project" && activeProject.issueDate === project.issueDate) {
                res = {
                    color: "black",
                    fontWeight: "bold",
                }
            }
        })
        return res
    }
    function getElementStyleTimeOff() {
        let res = {}
        props.activeCell.forEach(activeProject => {
            if(activeProject.type === "timeoff") {
                res = {
                    color: "black",
                    fontWeight: "bold",
                }
            }
        })
        return res
    }
    return (
        <Row className="planning-legend-footer">
            <Col className="planning-legend-footer-element">
                <div style={swatch}>
                    <div style={timeoffColor} />
                </div>
                <div style={{verticalAlign: "middle", marginLeft: "35px", paddingTop: "3px", ...getElementStyleTimeOff()}}>
                Time Off
                </div> 
            </Col>
            {props.projects.map(project => {
                const elementStyle = getElementStyle(project)
                const color = {
                    width: '20px',
                    height: '20px',
                    borderRadius: '2px',
                    background: project.color,
                    fontWeight: "bold",
                    textAlign: "center",
                }
                if(props.projectsShown.includes(project.issueDate)) {
                    return (
                        <Col key={project.id + "|" + project.issueDate} className="planning-legend-footer-element" style={elementStyle}>
                                <div style={swatch}>
                                    <div style={color}>{project.client.name.charAt(0).toUpperCase()}</div>
                                </div>
                                <div style={{verticalAlign: "middle", marginLeft: "35px", paddingTop: "3px"}}>
                                {project.client.name}: {project.name}
                                </div> 
                        </Col>
                    )
                } else {
                    return null
                }
            })}
        </Row>
    )

}

export default PlanningLegend;
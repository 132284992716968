import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Trash2 } from 'react-feather';


function EditInvoiceLineItem(props) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.currency,
    });

    function getRowTotal() {
        if(!Number.isNaN(props.lineItem.quantity) && !Number.isNaN(props.lineItem.unitPrice)) {
            return props.lineItem.quantity*props.lineItem.unitPrice
        }
        return 0
    }

    if(props.lineItem.hasOwnProperty("readonly") && props.lineItem.readonly) {
        return (
            <tr key={"lineItem-" + props.lineItemKey}> 
                <td>{props.lineItem.type}</td>
                <td className="document-description">{props.lineItem.description}</td>
                <td>{props.lineItem.quantity}</td>
                <td>{formatter.format(props.lineItem.unitPrice)}{props.lineItem.hasOwnProperty("unit") ? " / " + props.lineItem.unit :""}</td>
                <td>{formatter.format(props.lineItem.quantity*props.lineItem.unitPrice)}</td>
                <td></td>
            </tr>
            )
    } else {
        return (
            <tr key={"lineItem-" + props.lineItemKey}> 
                <td>
                    <Form.Select className="form-select form-select-sm" onChange={props.onChange('type', props.lineItemKey)} value={props.lineItem.type}>
                        <option value="service">Service</option>
                        <option value="product">Product</option>
                        <option value="extra">Extra</option>
                    </Form.Select>
                </td>
                <td className="document-description"><Form.Control as="textarea" rows={2} size="sm" type="text" onChange={props.onChange('description', props.lineItemKey)} value={props.lineItem.description}/></td>
                <td><Form.Control size="sm" type="text" onChange={props.onChange('quantity', props.lineItemKey)} value={props.lineItem.hasOwnProperty("strQuantity") ? props.lineItem.strQuantity : props.lineItem.quantity}/></td>
                <td><Form.Control size="sm" type="text" onChange={props.onChange('unitPrice', props.lineItemKey)} value={props.lineItem.hasOwnProperty("strUnitPrice") ? props.lineItem.strUnitPrice : props.lineItem.unitPrice}/></td>
                <td>{formatter.format(getRowTotal())}</td>
                <td><Button variant="outline-dark" onClick={() => props.removeRow(props.lineItemKey)}><Trash2 size={16} /></Button></td>
            </tr>
        )
    }
}

export default EditInvoiceLineItem;
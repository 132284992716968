import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { API } from '@aws-amplify/api';

import React, {useState} from "react";
import Spinner from 'react-bootstrap/esm/Spinner';

function SubmitApproval(props) {
    const [daysChecked, setDays] = useState([false, false, false, false, false, false, false])
    const [loading, setLoading] = useState(true)
    const [submittedTimesheets, setSubmittedTimesheets] = useState([])
    const [errorMessage, setErrorMessage] = useState('');
    
    React.useEffect(() => {
        setLoading(true)
        const today = new Date()
        let checkedDays = [false, false, false, false, false, false, false]
        props.days.forEach((day, index) => {
            if(day <= today) {
                checkedDays[index] = true
            }
        })
        setDays(checkedDays)
        // retrieve submitted timesheet status
        API
        .get("billing", "/billing?action=listUserSubmittedTimesheets&org="+props.org+"&startDate="+props.days[0].toISOString()+"&selectedUserID="+props.selectedUserID, {})
        .then(res => {
            setSubmittedTimesheets(res)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });

    }, [props.days, props.selectedUserID, props.org])

    function changeDayChecked(e, index) {
        setDays([
            ...daysChecked.slice(0,index),
            e.target.checked,
            ...daysChecked.slice(index + 1)
        ])
    }
    function onSubmit() {
        setLoading(true)
        let daysApproved = []
        for(let i = 0; i < 7; i++) {
            if(daysChecked[i]) {
                daysApproved.push(props.days[i].toISOString())
            }
        }
        const params = {
            body: {
                action: "submitTimesheet",
                timesheetApproval: {
                    id: props.org + "|timesheetApproval",
                    days: daysApproved,
                    startDate: props.days[0].toISOString(),
                    userID: props.selectedUserID,
                },
                org: props.org,
            },
        }
        API
        .post("billing", "/billing", params)
        .then(entry => {
            setSubmittedTimesheets([...submittedTimesheets, entry])
            setLoading(false)
        })
        .catch(error => {
            props.setErrorMessage(error.message);
            setLoading(false)
        });

    }

    function timesheetApproved() {
        let approved = false
        submittedTimesheets.forEach(timesheetApproval => {
            if(timesheetApproval.approved) {
                approved = true
            }
        })
        return approved
    }

    if(loading) {
        return (
            <Spinner animation="border"/>
        )
    }

    if(errorMessage !== "") {
        return (
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
        )
    }

    if(timesheetApproved()) {
        return (
            <div className="timesheet-approved">
                Timesheet approved!
            </div>
        )
    }

    return (
        <div>
            <DropdownButton id="dropdown-basic-button" title={submittedTimesheets.length > 0 ? "Resubmit For Approval" : "Submit For Approval"}>
                <Form className="px-4 py-3">
                    <Container>
                    {props.days.map((day, i) => (
                        <Row key={day.toISOString()}>
                            <Col>
                                <Form.Check type="checkbox" label="" name={day.toISOString()} checked={daysChecked[i]} onChange={(e) => changeDayChecked(e, i)} />
                            </Col>
                            <Col>
                                {day.getUTCDate()}&nbsp;{props.monthFormatter.format(day)}
                            </Col>
                        </Row>
                    ))}
                    
                    <Button className="mt-2" onClick={(e) => onSubmit()}>Submit</Button>
                    </Container>
                </Form>
            </DropdownButton>
            {submittedTimesheets.length > 0 ?
                <span className="timesheet-waiting-for-approval">Submitted on {submittedTimesheets[submittedTimesheets.length-1].issueDate.split("T")[0]} and waiting for approval.</span>
            :
                null
            }
        </div>
    )
}

export default SubmitApproval;
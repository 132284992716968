import Dropdown from "react-bootstrap/Dropdown"
import { ChevronLeft, ChevronsDown, Copy, Sun, Trash2, Truck, XCircle, ZoomIn } from 'react-feather'

import React, {useState} from "react"

function PlanningDropdown(props) {
    const [showProjects, setShowProjects] = useState(false)

    function dropdownClickProject(project) {
        setShowProjects(false)
        props.dropdownClickProject(project)
    }

    if(showProjects) {
        return (
            <div className="dropdown-planning">
                <Dropdown.Item onClick={() => setShowProjects(false)}><ChevronLeft size={16} /> Back</Dropdown.Item>
                {props.projects.map((project, projectIndex) => {
                    const swatch = {
                        marginTop: "3px",
                        padding: '3px',
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        float: "left",
                    }
                    const color = {
                        width: '30px',
                        height: '14px',
                        borderRadius: '2px',
                        background: project.color,
                    }
                    const projectName = {
                        marginLeft: "10px",
                        paddingBottom: "5px",

                    }

                    return (
                        <div key={project.id + "-" + project.issueDate}>
                            {projectIndex === 0 || project.client.name !== props.projects[projectIndex-1].client.name ?
                                <Dropdown.Header>{project.client.name}</Dropdown.Header>
                            : null}
                            
                            <Dropdown.Item onClick={() => dropdownClickProject(project)}>
                            
                                <div style={swatch}>
                                <div style={color} />
                                </div>
                                <span style={projectName}>{project.name}</span>                        
                        
                            </Dropdown.Item>
                        </div>
                    )
                })}
            </div>

        )
    }
    if(props.selectedRange.length === 2 && props.selectedRange[1] === props.index && props.isRangeCorrect()) {
        return (
            <div className="dropdown-planning">
                <Dropdown.Item onClick={() => props.dropdownClick("cancel")}><XCircle size={16} /> Cancel</Dropdown.Item>
                <Dropdown.Item onClick={() => props.dropdownClick("timeoff")}><Sun size={16} /> Add Time Off</Dropdown.Item>
                {props.role === "admin" && props.index > 6 ?
                    <Dropdown.Item onClick={() => props.dropdownClick("copy")}><Copy size={16} /> Copy from last week</Dropdown.Item>
                : null}
                {props.role === "admin" ?
                    <Dropdown.Item onClick={() => setShowProjects(true)}><Truck size={16} /> Add Project <ChevronsDown size={16} /></Dropdown.Item>
                : null}
            </div>
        )
    }
    if(props.selectedRange.length === 2 && props.selectedRange[1] === props.index && !props.isRangeCorrect()) {
        return (
            <div className="dropdown-planning">
                <Dropdown.Item onClick={() => props.dropdownClick("cancel")}><XCircle size={16} /> Cancel</Dropdown.Item>
            </div>
        )
    }
    
    if(props.selectedExistingEntry !== -1 && props.selectedExistingEntry === props.index) {
        return (
            <div className="dropdown-planning">
                <Dropdown.Item onClick={() => props.dropdownClick("zoomIn")}><ZoomIn size={16} /> Zoom In</Dropdown.Item>
                <Dropdown.Item onClick={() => props.dropdownClick("zoomOut")}><ZoomIn size={16} /> Zoom Out</Dropdown.Item>
                <Dropdown.Item onClick={() => props.dropdownClick("cancel")}><XCircle size={16} /> Cancel</Dropdown.Item>
                {(props.role === "admin" && props.selectedPlanningItem.entries.length > 0) || (props.selectedPlanningItem.entries.length > 0 && props.selectedPlanningItem.type !== "project") ?
                    <Dropdown.Item onClick={() => props.dropdownClick("clear")}><Trash2 size={16} /> Clear entry</Dropdown.Item>
                :
                    null
                }
            </div>
        )
    }

    return null
}

export default PlanningDropdown
import ProgressBar from 'react-bootstrap/ProgressBar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
function Budget(props) {
    const renderTooltip = (
        <Tooltip id="budget-tooltip">
            {props.budget.hoursUsed} hours used out of {props.budget.totalHours}
        </Tooltip>
    )
    if(props.budget.percentageUsed === -1) {
        return null
    }
    return (
        <div>
            <OverlayTrigger
                placement={props.placement}
                delay={{ show: 100, hide: 200 }}
                overlay={renderTooltip}>
            <ProgressBar className={props.size === "large" ? "progress-lg": "progress-sm"} variant={props.budget.percentageUsed > .9 ? "danger" : props.budget.percentageUsed > .7 ? "warning" : "info"} now={props.budget.percentageUsed*100} />
            </OverlayTrigger>
        </div>
    )

}

export default Budget;
import Table from 'react-bootstrap/Table'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import ContactListItem from './ContactListItem'
import SelectArchived from '../common/SelectArchived'
import SelectContactAction from './SelectContactAction'

function Header(props) {
    function onChangeSelectArchived(value) {
        props.onChangeSelectArchived(value)
    }
    function onChangeSelectContactAction(value) {
        props.onChangeSelectContactAction(value)
    }
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Contacts</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="Select action">
                <SelectContactAction onChange={onChangeSelectContactAction} selected="" page={props.selectedArchivedValue} />
            </ButtonGroup>
            <ButtonGroup aria-label="Select active / archived" className="mx-3">
                <SelectArchived onChange={onChangeSelectArchived} type="Contacts" selected={props.selectedArchivedValue} />
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function ListContacts(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showArchived, setShowArchived] = useState('active')

    const updateCheckedItems = (k, v) => {
        // pass a clone of storeMap
        setCheckedItems(new Map(checkedItems.set(k, v)));
    };

    React.useEffect(() => {
        let url = "/billing?action=listContacts&org="+props.org
        if(showArchived === "archived") {
            url += "&archived=true"
        }
        API
        .get("billing", url, {})
        .then(contacts => {
            setContacts(contacts)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, showArchived])

    function onChangeSelectArchived(value) {
        setLoading(true)
        setShowArchived(value)
    }
    function handleCheckboxChange(target) {
        const key = target.name;
        const isChecked = target.checked;
        updateCheckedItems(key, isChecked)
    }

    function onChangeSelectContactAction(value) {
        switch(value) {
            case "archive":
                patchSelectedContact({archived: true})
            break;
            case "restore":
                patchSelectedContact({archived: false})
            break;
            default:
                return
        }
    }

    function patchSelectedContact(patch) {
        setLoading(true)
        let contactsToPatch = []
        checkedItems.forEach((checked, key) => {
            if(checked) {
                const keySplit = key.split("|")
                contactsToPatch.push({
                    id: keySplit[0] + "|" + keySplit[1],
                    issueDate: keySplit[2],
                    ...patch,
                })
            }
        })
        const params = {
            body: {
                action: "patchContacts",
                contacts: contactsToPatch,
                org: props.org,
            },
        };
        API
        .post("billing", "/billing", params)
        .then(response => {
            let newContacts = []
            contacts.forEach(contact => {
                let found = false
                contactsToPatch.forEach(contactToPatch => {
                    if(contactToPatch.id === contact.id && contactToPatch.issueDate === contact.issueDate) {
                        found = true
                        if(showArchived === "archived" && contactToPatch.archived) {
                            newContacts.push(contact)
                        }
                        if(showArchived !== "archived" && !contactToPatch.archived) {
                            newContacts.push(contact)
                        }
                    }    
                })
                if(!found) {
                    newContacts.push(contact)
                }
            })
            setContacts(newContacts)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }


    if(loading) {
        return (
            <div>
                <Header onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} onChangeSelectContactAction={onChangeSelectContactAction} />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }

    return (
        <Container>
            <Header onChangeSelectArchived={onChangeSelectArchived} selectedArchivedValue={showArchived} onChangeSelectContactAction={onChangeSelectContactAction} />
            {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Contact saved
            </Alert>
            : ""
            }   
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
            }
            <Table striped bordered hover>
                    <thead>
                        <tr key="header">
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Has login</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact, index) => (
                            <ContactListItem
                            key={contact.id + "|" + contact.issueDate}
                            contact={contact}
                            org={props.org}
                            myroute={props.myroute}
                            checkedItems={checkedItems}
                            handleCheckboxChange={(e) => handleCheckboxChange(e)}
                            />
                        ))
                        }
                    </tbody>
                </Table>
        </Container>
    )
}
export default ListContacts;
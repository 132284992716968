import React from "react";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import {useNavigate} from 'react-router-dom';
import { Archive, FilePlus, RotateCcw } from "react-feather";


function SelectContactAction(props) {
    const navigate = useNavigate();

    const onChange = (value) => {
        props.onChange(value)
    };
    
    const newContact = () => navigate("/newContact");


    return (
        <DropdownButton id="dropdown-basic-button" title="Action" variant="white border-secondary">
            <Dropdown.Item onClick={() => newContact()}><FilePlus size={16} /> New Contact</Dropdown.Item>
            <Dropdown.Divider />
            {props.page === "active" ?
                <Dropdown.Item onClick={(e) => onChange("archive")}><Archive size={16} /> Archive Selected Contacts</Dropdown.Item>
                :
                null
            }
            {props.page === "archived" ?
                <Dropdown.Item onClick={(e) => onChange("restore")}><RotateCcw size={16} /> Restore Selected Contacts</Dropdown.Item>
                :
                null
            }
        </DropdownButton>   
    )
}

export default SelectContactAction;
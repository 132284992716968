import { Amplify, Auth } from 'aws-amplify';
import awsExports from "./constants/aws-exports";
Amplify.configure(awsExports);

async function GetUserAttributes(activeOrg) {
    let roleMap = await Auth.currentUserInfo()
    .then(user => {
        if(user === null) {
            return null // user is not logged in, redirect to federated auth
        }

        if(!user.hasOwnProperty("attributes")) {
            return null // user was logged in, but got 400 from cognito endpoint (user was removed)
        }

        if(!user.attributes.hasOwnProperty("custom:role") || !user.attributes.hasOwnProperty("custom:userID")) {
            return [user, "", "", ""]
        }
        
        let defaultOrg = ""
        const roles = user.attributes["custom:role"]
        const userIDsParts = user.attributes["custom:userID"].split(";")
        let result = new Map();
        let userIDs = new Map();
        roles.split(";").forEach(function (role, index) {
            const roleParts = role.split(":")
            if(index === 0) {
                result.set("__default", roleParts[1])
                userIDs.set("__default", userIDsParts[index])
                defaultOrg = roleParts[0]
            }
            if(roleParts.length === 2) {
                result.set(roleParts[0], roleParts[1])
                userIDs.set(roleParts[0], userIDsParts[index])
            }
        })

        if(result.has(activeOrg)) {
            return [user, result.get(activeOrg), activeOrg === "__default" ? defaultOrg : activeOrg, userIDs.get(activeOrg)]
        }

        return [user, "", "", ""]
        
    })
    return roleMap
}

export default GetUserAttributes;
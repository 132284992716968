
import React, { useState } from "react";

import currencies from '../constants/currencies'
import Form from 'react-bootstrap/Form';

function SelectCurrency(props) {
    const [selected, setSelected] = useState("")

    React.useEffect(() => {
        setSelected(props.default)
    }, [props.default])

    const onChange = (e) => {
        setSelected(e.target.value)
        props.onChange(e.target.value)
    };

    return (
        <Form.Select className="form-select form-select-sm" onChange={(e) => onChange(e)} value={selected}>
            <option key="default-eur" value="EUR">Euro</option>
            <option key="default-usd" value="USD">USD</option>
            <option key="default-line" value="">---------------</option>
        {Object.entries(currencies).map(([key, currency], index) => (
            <option key={key} value={key}>{currency}</option>
        ))}
        </Form.Select>
    )
}

export default SelectCurrency;
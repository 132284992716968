import React, {useCallback, useState} from "react";
import { Container, Table } from "react-bootstrap";

import {useDropzone} from 'react-dropzone'

import { API } from "aws-amplify";
import Spinner from 'react-bootstrap/Spinner';

function Time(props) {
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState({entries: [], inputs: []});

    function ImportResults(props) {
        const rows = [];
        for(let i=0; i< props.records.inputs.length; i++) {
            const status = props.records.errors[i] === "" ? "SUCCESS" : props.records.errors[i]
            rows.push(<tr key={i}><td>{props.records.inputs[i]}</td><td>{status}</td></tr>)
        }
        return (
            <Table striped bordered hover>
                <thead key="header">
                    <tr><th>Input</th><th>Status</th></tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        )
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            setLoading(true)
            // prepare post
            const params = {
                body: {
                    action: "newTimeImport",
                    data: reader.result,
                    org: props.org,
                },
            };
            // do post
            API
            .post("billing", "/billing", params)
            .then(result => {
                console.log(result)
                setRecords(result)
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            });
          }
          reader.readAsText(file)
        })
        
      }, [props.org])

    const {getRootProps, getInputProps} = useDropzone({onDrop, size: 50, multiple: false, accept: "text/csv"})

    if(loading) {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Import Time from CSV</h1>
                </div>
                <Spinner animation="border"/>
            </div>
        )
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Import Time from CSV</h1>
            </div>
            {records.inputs.length === 0 ? 
            <Container className="mt-5">
            <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p className="dropzone-text">Select CSV file to import. Make sure CSV has a header.</p>
            </div>
            </Container>
            :
            <ImportResults records={records} />  
            }
            
        </div>
    )
}

export default Time;
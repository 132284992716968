import Form from 'react-bootstrap/Form';
import Budget from '../projects/Budget';

function EntryLine(props) {

    function onChangeTimesheetTextbox() {
        // do nothing
    }

    let entry = (null)
    props.userProjects.map(userProject => {
        userProject.tasks.map(task => {
            if((props.project.projectID === userProject.id + "|" + userProject.issueDate && props.project.taskID === task.id + "|" + task.issueDate)) {
                const index = props.timesheetEntries.helper.hasOwnProperty(props.project.projectID + "|" + props.project.taskID) ? props.timesheetEntries.helper[props.project.projectID + "|" + props.project.taskID] : []
                entry = <tr key={props.project.projectID + "|" + props.project.taskID}>
                            <td className="timesheet-firstColumn">
                                {userProject.clientName}: {userProject.name}
                                <br />
                                {task.taskName.toLowerCase() === "default" ? "" : task.taskName}
                                <Budget placement="right" budget={userProject.budget} />                                
                            </td>
                
                            {[...Array(7).keys()].map(dayIndex => {
                                if(props.isDayIncluded !== undefined && !props.isDayIncluded(dayIndex)) {
                                    return (
                                        <td key={dayIndex} className="timesheet-entry-disabled"></td>
                                    )
                                }
                                return (
                                    <td key={dayIndex}>
                                        <Form.Control
                                            className="timesheet-textbox"
                                            size="sm"
                                            type="text"
                                            readOnly={props.readOnly ? true : index.length === 0 || index[dayIndex] === -1 ? false : props.timesheetEntries.entries[index[dayIndex]].locked}
                                            onClick={() => props.onClickEditTime(props.project, dayIndex)}
                                            value={index.length === 0 || index[dayIndex] === -1 ? "" : props.durationToString(props.timesheetEntries.entries[index[dayIndex]].duration)}
                                            onChange={onChangeTimesheetTextbox} 
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    
            }
            return entry
        })
        return entry
    })
    return entry
}

export default EntryLine;
import Onboarding from "./onboarding/Onboarding";
import { FileText, DollarSign, Home, CreditCard, Users, Truck, Clock, BarChart, Layers, UserPlus, Activity, AlignJustify, Settings, CheckSquare, FilePlus, PenTool, DownloadCloud } from 'react-feather'
import { Link } from "react-router-dom";
function Navigation(props) {

    switch (props.role)
    {
       case "admin":
           return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3">
              <div>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Reports</span>
                    </h6>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link to="/dashboard" className="nav-link"><BarChart size={16} /> Dashboard</Link>
                      </li>
                    </ul>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link to="/timesheetReports" className="nav-link"><Activity size={16} /> Timesheet Reports</Link>
                      </li>
                    </ul>
              </div>
              <div>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Time Tracking</span>
                    </h6>
                    <ul className="nav flex-column">
                    <li className="nav-item">
                      <Link to="/timesheet" className="nav-link"><Clock size={16} /> Timesheet</Link>
                    </li>
                    { props.organizationAttributes.configuration.timesheetApproval ?
                    <li className="nav-item">
                    <Link to="/submittedTimesheets" className="nav-link"><CheckSquare size={16} /> Timesheet Approvals</Link>
                    </li>
                    : 
                      null
                    }
                    { props.organizationAttributes.configuration.timePlanning ?
                    <li className="nav-item">
                    <Link to="/planning" className="nav-link"><PenTool size={16} /> Planning</Link>
                    </li>
                    : 
                      null
                    }
                    <li className="nav-item">
                      <Link to="/projects" className="nav-link"><Truck size={16} /> Projects</Link>
                    </li>
                    </ul>
              </div>
              <div>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Bill Management</span>
                    </h6>
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <Link to="/bills" className="nav-link"><DollarSign size={16} /> Bills</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/invoices" className="nav-link"><FileText size={16} /> Invoices</Link>
                      </li>
                      <li className="nav-item">
                          <Link to="/payments" className="nav-link"><CreditCard size={16} /> Payments</Link>
                      </li>
                    </ul>
              </div>
              <div>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Administration</span>
                    </h6>
                    <ul className="nav flex-column">
                    <li className="nav-item">
                      <Link to="/contracts" className="nav-link"><FilePlus size={16} /> Contracts</Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/contacts" className="nav-link"><Users size={16} /> Contacts</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/import" className="nav-link"><DownloadCloud size={16} /> Import</Link>
                    </li>
                    </ul>
              </div>
              <div>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Organization Settings</span>
                    </h6>
                    <ul className="nav flex-column">
                    <li className="nav-item">
                            <Link to="/organizations" className="nav-link"><Home size={16} /> My Organizations</Link>
                    </li>
                    <li className="nav-item">
                            <Link to="/integrations" className="nav-link"><Layers size={16} /> Integrations</Link>
                    </li>
                    <li className="nav-item">
                            <Link to="/users" className="nav-link"><UserPlus size={16} /> Users</Link>
                    </li>
                    <li className="nav-item">
                            <Link to="/auditLog" className="nav-link"><AlignJustify size={16} /> Audit Log</Link>
                    </li>
                    <li className="nav-item">
                            <Link to="/organizationSettings" className="nav-link"><Settings size={16} /> Settings</Link>
                    </li>
                    </ul>
              </div>
            </div>
          </nav>
           )
       case "contractor":
           return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3">
              
              <ul className="nav flex-column">
                <li className="nav-item">
                    <Link to="/timesheet" className="nav-link"><Clock size={16} /> Timesheet</Link>
                </li>
                { props.organizationAttributes.configuration.timePlanning ?
                    <li className="nav-item">
                      <Link to="/planning" className="nav-link"><PenTool size={16} /> Planning</Link>
                    </li>
                    : 
                      null
                    }
                <li className="nav-item">
                  <Link to="/contracts" className="nav-link"><FilePlus size={16} /> Contracts</Link>
                </li>
                <li className="nav-item">
                    <Link to="/contractor/invoices" className="nav-link"><DollarSign size={16} /> Invoices</Link>
                </li>
              </ul>
            </div>
          </nav>
           )
       case "client":
         return (
          <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
          <div className="position-sticky pt-3">
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link to="/timesheetReports" className="nav-link"><Activity size={16} /> Timesheet Reports</Link>
              </li>
              <li className="nav-item">
                      <Link to="/projects" className="nav-link"><Truck size={16} /> Projects</Link>
               </li>
            </ul>
          </div>
        </nav>
         )
       case "": 
       return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div className="position-sticky pt-3">
                
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/setup" className="nav-link"><Onboarding size={16} /> Onboarding</Link>
                    </li>
                </ul>
                </div>
            </nav>
       )
       default: 
           return (
                <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                </nav>
           )
    }
}

export default Navigation;
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import Spinner from "react-bootstrap/Spinner"
import React, {useState} from "react"
import { API } from "aws-amplify";

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Quickbooks</h1>
        </div>
    )
}

function Quickbooks(props) {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [integrationEnabled, setIntegrationEnabled] = useState(false)
    const [integration, setIntegration] = useState({
        id: "",
        syncCustomers: false,
    })

    React.useEffect(() => {
        if(props.callbackCode === "" || props.callbackState === "") {
            setLoading(true)
            API
            .get("billing", "/billing?action=getIntuitIntegration&org="+props.org, {})
            .then(response => {
                if(response.id !== "") {
                    setIntegrationEnabled(true)
                    setIntegration(response)
                }
                setLoading(false)
            })
            .catch(error => {
                setErrorMessage(error.message)
                setLoading(false)
            });
        } else {
            setLoading(true)
            const params = {
                body: {
                    action: "intuitCallback",
                    code: props.callbackCode,
                    state: props.callbackState,
                    realmID: props.realmID,
                    org: props.org,
                },
            };
            API
            .post("billing", "/billing", params)
            .then(response => {
                setIntegrationEnabled(true)
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [props.callbackCode, props.callbackState, props.org, props.realmID])

    function quickbooksAuth() {
        setLoading(true)
        API
        .get("billing", "/billing?action=getIntuitAuthURL&org="+props.org, {})
        .then(response => {
            if(response.url !== "") {
                window.location.href = response.url; 
                return null;           
            }
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }

    if(loading) {
        return (
            <div>
                <Header />
                {errorMessage !== "" ?
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    :
                        <Spinner animation="border"/>
                }
            </div>
        )
    }

    if(integrationEnabled) {
        return (
            <div>
                <Header />
                {errorMessage !== "" ?
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    :
                        null
                }
                <p>Intuit integration enabled.</p>
                <p>Sync customers: {integration.syncCustomers ? "Enabled" : "Disabled"}</p>
                <p>Sync invoices: {integration.syncInvoices ? "Enabled" : "Disabled"}</p>
            </div>
        )
    }

    return (
            <div>
            <Header />
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
            }
            <Button onClick={() => quickbooksAuth()} disabled={loading}>Quickbooks Auth</Button>
            </div>
    )
}

export default Quickbooks;
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom';

function BillListItem(props) {
    const navigate = useNavigate();
    const invoice = props.invoice
    const key = invoice.id + "|" + invoice.issueDate
    const invoiceIssueDate = new Date(invoice.invoiceIssueDate)
    const dueDate = new Date(invoice.dueDate)
    const onRowClick = () => navigate(props.myroute + "?id=" + invoice.id +"&issueDate="+invoice.issueDate);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: invoice.currency,
    });

    function getInvoiceTotal(value) {
        return formatter.format(value)
    }

    return (
        <tr key={key} className="clickable">
            <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
            <td onClick={() => onRowClick()}>{invoice.company.name}</td>
            <td onClick={() => onRowClick()}>{invoice.documentNumber}</td>
            <td onClick={() => onRowClick()}>{invoiceIssueDate.getFullYear() === 1 ? "" : invoiceIssueDate.toISOString().split('T')[0]}</td>
            <td onClick={() => onRowClick()}>{dueDate.getFullYear() === 1 ? "" : dueDate.toISOString().split('T')[0]}</td>
            <td onClick={() => onRowClick()}>{getInvoiceTotal(invoice.invoiceTotal)}</td>
            <td onClick={() => onRowClick()}>{invoice.paymentData === null || invoice.paymentData.length === 0 ? "" : invoice.paymentData[0].accountNumber}</td>
            <td onClick={() => onRowClick()}>{invoice.status}</td>
            <td><Link to={props.myroute + "s"} onClick={() => {props.viewPDF(invoice.id, invoice.issueDate)}}>View PDF</Link></td>
        </tr>
    )
}

export default BillListItem;
import React from "react";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import {useNavigate} from 'react-router-dom';
import { FilePlus, Layers, RefreshCw } from "react-feather";

import { API } from "aws-amplify";

function HeaderInvoiceActions(props) {
    const navigate = useNavigate();

    
    const newBill = () => navigate("/newBill");
    const newInvoice = () => navigate("/newInvoice");

    function runAction(action) {
        // prepare post
        const params = {
            body: {
                action: action,
                org: props.org,
            },
        };
        if(action === "startAutoInvoice") {
            let d = new Date()
            d.setDate(d.getDate()-7) // first week still runs last week autoInvoice
            const d2 = new Date(d.getUTCFullYear(), d.getUTCMonth() + 1, 0);
            params.body = {
                ...params.body,
                year: (d2.getUTCFullYear()).toString(),
                month: (d2.getUTCMonth() + 1).toString(),
                day: (d2.getUTCDate()).toString()
            }    
        }
        // do post
        API
        .post("billing", "/billing", params)
        .then(response => {
            props.setMessage(action === "startAutoInvoice" ? "Auto invoice process started" : "Quickbooks sync process started")        
        })
        .catch(error => {
            console.log(error)
        });
    }

    return (
        <DropdownButton id="dropdown-basic-button" title="Action" variant="white border-secondary">
            {props.type === "bill" ?
                <Dropdown.Item onClick={() => newBill()}><FilePlus size={16} /> New Bill</Dropdown.Item>
                :
                <Dropdown.Item onClick={() => newInvoice()}><FilePlus size={16} /> New Invoice</Dropdown.Item>
             }
             {props.type === "invoice" ?
                <Dropdown.Item onClick={() => runAction("startAutoInvoice")}><RefreshCw size={16} /> Run AutoInvoice</Dropdown.Item>
             : null
             }
             <Dropdown.Item onClick={() => runAction("startQuickbooksSync")}><Layers size={16} /> Run Quickbooks Sync</Dropdown.Item>
        </DropdownButton> 
    )
}

export default HeaderInvoiceActions;
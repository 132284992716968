import {useNavigate} from 'react-router-dom';
import React, { useState } from 'react';
import Budget from './Budget';
import Form from 'react-bootstrap/Form';

function ProjectListItem(props) {
    const [client, setClient] = useState({
        id: "",
    })

    const navigate = useNavigate();
    const key = props.project.id + "|" + props.project.issueDate
    const onRowClick = () => {
        if(!props.readOnly) {
            navigate("/getProject?id=" + props.project.id +"&issueDate="+props.project.issueDate);
        }
    }
    React.useEffect(() => {
        if(props.client !== undefined) {
            setClient(props.client)
        }
    }, [props.client])
    if(client.id === "") {
        return (null) // wait until we have clients 
    }
    return (
        <tr key={key} className={props.readOnly ? "" : "clickable"}>
            <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
            <td onClick={() => onRowClick()}>{props.client.issueDate === props.prevClient.issueDate ? "" : props.client.name}</td>
            <td onClick={() => onRowClick()}>{props.project.name}</td>
            <td onClick={() => onRowClick()}>
                {props.budget.length > 0 ?
                    <Budget placement="left" size="large" budget={props.budget[0]} />
                : 
                    null
                }
            </td>
        </tr>
    )

}

export default ProjectListItem;

import React, {useState} from "react";

import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import { FilePlus, RefreshCcw } from "react-feather";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorMessage from '../Error'
import { API } from "aws-amplify";

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Payments</h1>
            <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup aria-label="New Payment group">
                    <Link to="/newPayment"><button type="button" className="btn btn-sm btn-outline-secondary" disabled={true}><FilePlus size={16} /> New Payment</button></Link>
                </ButtonGroup>
            </ButtonToolbar>
        </div>
    )
}

function Payments(props) {
    const [loading, setLoading] = useState(true)
    const [payments, setPayments] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    React.useEffect(() => {
        API.get("billing", "/billing?action=listPayments&org="+props.org, {response: true})
        .then(payments => {
            setPayments(payments.data)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }, [props.org])

    function refreshPaymentStatus(paymentID, paymentIssueDate, pontoID, index) {
        API
        .get("billing", "/billing?action=listPayments&org="+props.org+"&id="+paymentID+"&issueDate="+paymentIssueDate+"&pontoID="+pontoID, {})
        .then(payments => {
            if(payments.length === 1) {
                const newPayments = [
                    ...payments.slice(0, index),
                    payments[0],
                    ...payments.slice(index + 1)
                  ];
                setLoading(false)
                setPayments(newPayments)
            }
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }
    function addPayments() {
        let items = [];
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
        });
    
        if(payments.length > 0) {
            payments.forEach((payment, i) => {
            const key = payment.id + "|" + payment.issueDate
            items.push(
                <tr key={key}>
                    <td>{payment.document.company.name}</td>
                    <td><Link to={"/bills?id="+payment.document.id+"&issueDate="+payment.document.issueDate}>{payment.document.documentNumber}</Link></td>
                    <td>{formatter.format(payment.amount)}</td>
                    <td>
                        {payment.status === "unsigned" ?
                            <a href={payment.redirectLink}>Sign</a>
                        :
                            payment.status
                        }
                        &nbsp;<Button variant="outline-secondary" size="sm" disabled={loading} onClick={(e) => refreshPaymentStatus(payment.id, payment.issueDate, payment.pontoID, i)}><RefreshCcw size={16}/></Button>
                    </td>
                </tr>);
          })
        }
        return items
    }

    if(loading) {
        return (
                <div>
                    <Header />
                    <Spinner animation="border"/>
                </div>
            )
    }
    return (
        <Container>
            <Header />
            <ErrorMessage body={errorMessage} />
            <div>
            <Form>
            <Table striped bordered hover>
                <thead>
                    <tr key="header">
                    <th>Company</th>
                    <th>Document Number</th>
                    <th>Amount</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {addPayments()}
                </tbody>
            </Table>
            </Form>
        </div>
        </Container>
    )
}

export default Payments;
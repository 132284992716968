import { Settings } from "react-feather"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import {useNavigate} from 'react-router-dom';

function Integrations(props) {
    const navigate = useNavigate();
    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Integrations</h1>
            </div>

            <Card style={{ width: '18rem', display: 'inline-block', height: '15rem' }}>
                <Card.Body>
                    <Card.Title>Ponto</Card.Title>
                    <Card.Text>
                        Integration for payments to European banks (Ponto is part of Isabel Group).
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/integrations/ponto")}><Settings size={16} /> Configuration</Button>
                </Card.Body>
            </Card>
            <Card style={{ width: '18rem', display: 'inline-block', height: '15rem' }} className="mx-3 align-top">
                <Card.Body>
                    <Card.Title>Quickbooks</Card.Title>
                    <Card.Text>
                        Integration to sync invoices/bills with Quickbooks.
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/integrations/quickbooks")} ><Settings size={16} /> Configuration</Button>
                </Card.Body>
            </Card>
            <Card style={{ width: '18rem', display: 'inline-block', height: '15rem' }} className="mx-3 align-top">
                <Card.Body>
                    <Card.Title>Xero</Card.Title>
                    <Card.Text>
                        Integration to sync invoices/bills with Xero.
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/integrations/xero")} ><Settings size={16} /> Configuration</Button>
                </Card.Body>
            </Card>
        </div>
        
    )
}

export default Integrations;
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'


import Button from 'react-bootstrap/Button';

import React, { useState } from "react";
import { Save } from 'react-feather';
import SelectContact from '../contacts/SelectContact';



function EditContractorContract(props) {
    const [contract, setContract] = useState(props.contract)
    const [currency, setCurrency] = useState(props.contract.company.currency)

    const onContractFieldChange = (propertyName) => (event) => {
        if(propertyName === "dayRate") {
            if(!isNumeric(event.target.value) && event.target.value !== "") {
                return
            }
            setContract({
                ...contract,
                [propertyName]: parseFloat(event.target.value),
                strDayRate : event.target.value,
              })
        } else {
            setContract({...contract,  [propertyName]: event.target.value})
        }
    }

    const onContractFieldChangeBool = (propertyName) => (event) => {
        setContract({...contract,  [propertyName]: event.target.value === "on" || event.target.value === "enabled" ? true : false})
    }

    function onCompanyChange(contact) {
        setContract({...contract, company: { id: contact.id, issueDate: contact.issueDate }})
        setCurrency(contact.currency)
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

   


    function submitEditContract() {
        props.onSubmit(contract)
    }

    if(contract.length === 0) {
        return null
    }


    return (
    <Form.Group>
    <Row className="mt-5 d-flex align-items-center">
        <Col md="4">
            Contract Description
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="Contract name" onChange={onContractFieldChange('description')} value={contract.description}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Vendor
        </Col>
        <Col md="4">
            <SelectContact onChange={onCompanyChange} default={contract.company.id + "|" + contract.company.issueDate} org={props.org} displayName="Vendor" />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Day Rate
        </Col>
        <Col md="4">
            <InputGroup className="mb-3" size="sm">
                <InputGroup.Text>{currency}</InputGroup.Text>
                <Form.Control size="sm" type="text" placeholder="day rate" onChange={onContractFieldChange('dayRate')} value={contract.strDayRate}/>
            </InputGroup>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Payment terms in days
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="payment terms" onChange={onContractFieldChange('paymentTermsInDays')} value={contract.paymentTermsInDays}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Start date
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="yyyy-mm-dd" onChange={onContractFieldChange('startDate')} value={contract.startDate}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            End date
        </Col>
        <Col md="2">
            <Form.Check type="checkbox" label="Contract has end date" checked={contract.hasEndDate} onChange={onContractFieldChangeBool("hasEndDate")} />
        </Col>
        {contract.hasEndDate ?
            <Col md="2">
                <Form.Control size="sm" type="text" placeholder="yyyy-mm-dd" onChange={onContractFieldChange('endDate')} value={contract.endDate}/>
            </Col>
        : null }
    </Row>
    {props.role === "admin" ?
        <Row className="mt-3">
            <Col md="2">
                <Button variant="primary" disabled={props.submitting} onClick={submitEditContract}>
                    <span style={{whiteSpace: "nowrap"}}>Save Contract <Save size={18} /></span>
                </Button>
            </Col>
        </Row>
    : null}



    </Form.Group>
    )
}
export default EditContractorContract
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

import { FilePlus } from 'react-feather'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import EditUser from './EditUser'

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">User</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="New users group">
                <Link to="/newUser"><button type="button" className="btn btn-sm btn-outline-secondary"><FilePlus size={16} /> New User</button></Link>
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function GetUser(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [submitting, setSubmitting] = useState(false)

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [user, setUser] = useState({})


    React.useEffect(() => {

        API
        .get("billing", "/billing?action=getUser&org="+props.org + "&id=" + props.id + "&userID=" + props.userID, {})
        .then(user => {
            if(user.length > 0) {
                setUser(user[0])
            } else {
                setErrorMessage("No user returned")
            }
            setLoading(false)

        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, props.id, props.userID])

    function onSubmit(newUser) {
        setSubmitting(true)
        const params = {
            body: {
                action: "updateUser",
                user: newUser,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(user => {
            setSubmitting(false)
            setShowSaved(true)
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    if(loading) {
        return (
            <div>
                <Header />
                <Spinner animation="border"/>
            </div>
        )
    }

    return (
        <div>
        <Header />
        {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                User saved
            </Alert>
            : ""
            }  
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <EditUser user={user} onSubmit={onSubmit} submitting={submitting} emailReadonly={true} />
        </div>
    )
    
}
export default GetUser;
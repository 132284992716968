import React, { useState } from "react";


import Container from 'react-bootstrap/Container';

import Alert from "react-bootstrap/Alert";
import { API } from "aws-amplify";
import {useNavigate} from 'react-router-dom';

import EditInvoice from "./EditInvoice";

function Invoices(props) {
    const navigate = useNavigate();
    const [showSaved, setShowSaved] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)
    const [invoiceID, setInvoiceID] = useState({
        id: props.id,
        issueDate: props.issueDate
    })

    React.useEffect(() => {
        setInvoiceID({
            id: props.id,
            issueDate: props.issueDate
        })
        if(props.id === "") {
            setShowSaved(false)
        }
        
    }, [props.id, props.issueDate])

    function updateDocument(document) {
        // prepare post
        setSubmitting(true)
        const params = {
            body: {
                action: props.type === "bill" ? "updateBill" : "updateInvoice",
                document: document,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(invoice => {
            if(invoice.issueDate !== props.issueDate) {
                setInvoiceID({
                    id: invoice.id,
                    issueDate: invoice.issueDate
                })
                navigate(props.myroute + "?id="+invoice.id+"&issueDate="+invoice.issueDate)
            }
            setShowSaved(true)
            setSubmitting(false)
        
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    if(invoiceID.id !== "" && invoiceID.issueDate !== "") {
        return (
            <Container>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">{props.role === "admin" ? props.type === "bill" ? "Bill" : "Invoice" : "Invoice" }</h1>
                </div>
                {showSaved ?
                    <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                        Document saved
                    </Alert>
                    : ""
                }
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                    : ""
                }

                <EditInvoice {...props} id={invoiceID.id} issueDate={invoiceID.issueDate} submitButton="save" onSubmit={updateDocument} submitting={submitting} />
            </Container>
        )
    } else {
        return (
            <Container>

            </Container>
        )
    }
}

export default Invoices;
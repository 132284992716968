import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import {getWeekNumber, getDateOfISOWeek} from "../common/Utils"
import PlanningHeader from './PlanningHeader'
import PlanningEntry from './PlanningEntry'

import ButtonGroup from "react-bootstrap/ButtonGroup"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"

import SelectUsers from '../users/SelectUsers'
import { Paperclip, Sun, Sunrise, Sunset } from 'react-feather'

import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"

import {useNavigate, useParams} from 'react-router-dom';
import PlanningLegend from './PlanningLegend'

function Header(props) {

    function onViewChange(view) {
        props.onViewChange(view)
    }

    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Planning</h1>
            <ButtonToolbar aria-label="Toolbar with button groups">
                <DropdownButton id="dropdown-basic-button" title={"View: " + props.view.charAt(0).toUpperCase() + props.view.slice(1)} variant="white border-secondary" className="mx-3 border-dark">
                    <Dropdown.Item onClick={() => onViewChange("day")}><Sun size={16} /> Daily view</Dropdown.Item>
                    <Dropdown.Item onClick={() => onViewChange("half day")}><Sunrise size={16} /> Half daily view</Dropdown.Item>
                    <Dropdown.Item onClick={() => onViewChange("work day")}><Paperclip size={16} /> Work Day</Dropdown.Item>
                    <Dropdown.Item onClick={() => onViewChange("full day")}><Sunset size={16} /> Full Day</Dropdown.Item>
                </DropdownButton>
                {props.role === "admin" ? 
                    <ButtonGroup aria-label="team">
                        <SelectUsers org={props.org} type="selectbox" handleUserChange={props.handleUserChange} selected={props.selectedUserID} defaultValue="All Users" selectClassName="form-select form-select border-dark" />
                    </ButtonGroup>
                : null
                }
            </ButtonToolbar>
        </div>
    )
}

function Planning(props) {
    const navigate = useNavigate();
    const {year, month, day, userID} = useParams();
    const [loading, setLoading] = useState(true);
    const [week, setWeek] = useState(getWeekNumber(year === undefined ? new Date() : new Date(year + "-" + month + "-" + day)))
    const [days, setDays] = useState({
        days: [],
        entries: [],
    })
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState([])
    const [view, setView] = useState("day")
    const [selectedUserID, setSelectedUserID] = useState(userID === undefined ? "" : userID)
    const [planningItems, setPlanningItems] = useState([]) 
    const [selectedPlanningItem, setSelectedPlanningItem] = useState({id: "", issueDate: "", userID: "", entries: []})
    const [lock, setLock] = useState(false)
    const [projects, setProjects] = useState([]);
    const [activeCell, setActiveCell] = useState([])

    function handleUserChange(userID) {
        setSelectedUserID(userID)
        updateUsers(users, userID)
        navigate("/planning/" + days.days[0].getUTCFullYear() + "/" + (days.days[0].getUTCMonth() + 1).toString().padStart(2, '0') + "/" + days.days[0].getUTCDate().toString().padStart(2, '0') + "/" + userID)
    }
    function updateUsers(userList, userID) {
        let newUsers = []
        userList.forEach(user => {
            if(userID !== "" && user.userID !== userID) {
                user.hide = true
            } else {
                user.hide = false
            }
            newUsers.push(user)
        })
        setUsers(newUsers)
    }

    function onViewChange(view) {
        setView(view)
    }

    React.useEffect(() => {
        setLoading(true)
        let days = []
        let d = getDateOfISOWeek(week[1], week[0])
        d.setUTCHours(0, 0, 0)      
        for (let i = 0; i < 28; i++) {
            days.push(new Date(d.valueOf()));
            d.setUTCDate(d.getUTCDate() + 1)
        }
        // list users
        let url = "/billing?action=getUser&org="+props.org+"&userID="+props.userID
        if(props.role === "admin" ) {
            url = "/billing?action=listUsers&org="+props.org
        }
        API
        .get("billing", url, {})
        .then(users => {
            updateUsers(users, selectedUserID)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
        // list planning items
        let planningURL = "/billing?action=listPlanningItems&org="+props.org+"&startDate="+days[0].toISOString()+"&endDate="+days[days.length-1].toISOString()
        if(props.role !== "admin") {
            planningURL += "&selectedUserID=" + props.userID
        }
        API
        .get("billing", planningURL, {})
        .then(res => {
            setPlanningItems(res)
            let newEntries = Array.from(days, _ => [])
            res.forEach(planningItem => {
                planningItem.entries.forEach(planningEntry => {
                    days.forEach((day, index) => {
                        if(planningEntry.startDate.split("T")[0] === day.toISOString().split("T")[0]) {
                            const project = { issueDate: planningItem.project.issueDate, name: planningItem.project.name, clientName: planningItem.project.client.name, color: planningItem.project.hasOwnProperty("color") ? planningItem.project.color : "" }
                            newEntries[index].push({...planningEntry, userID: planningItem.userID, type: planningItem.type, id: planningItem.id, issueDate: planningItem.issueDate, project})
                        }
                    })
                })
            })
            setDays({days, entries: newEntries})
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
        // list projects
        let projectURL = "/billing?action=listProjectsForUser&org="+props.org+"&showClients=true"
        if(props.role === "admin") {
            projectURL = "/billing?action=listProjects&org="+props.org+"&showClients=true"
        }
        API
        .get("billing", projectURL, {})
        .then(res => {
            setProjects(res.projects)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [week, props.org, selectedUserID, props.role, props.userID])


    function getRows() {
        if(view === "day") {
            return 1
        } else if(view === "half day") {
            return 2
        } else if(view === "work day") {
            return 10
        } else if(view === "full day") {
            return 24
        } else {
            return 0
        }
    }
    function getHours() {
        if(view === "day") {
            return 8
        } else if(view === "half day") {
            return 4
        }
        return 1
    }

    function onSubmitSelectedRange(selectedRange, type, user, project) {
        const params = {
            body: {
                action: "putPlanningItem",
                planningItem: {
                    id: props.org + "|planningItem",
                    issueDate: selectedRange[0].toISOString(),
                    userID: user.userID,
                    type: type,
                    totalHours: selectedRange.length * getHours(),
                    entries: selectedRange.map(day => { return { startDate: day.toISOString(), durationHours: getHours() }}),
                    project: project,
                },
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(res => {
            let newEntries = [...days.entries]
            res.planningItem.entries.forEach(planningEntry => {
                days.days.forEach((day, index) => {
                    if(planningEntry.startDate.split("T")[0] === day.toISOString().split("T")[0]) {
                        const project = { issueDate: res.planningItem.project.issueDate, name: res.planningItem.project.name, clientName: res.planningItem.project.client.name, color: res.planningItem.project.hasOwnProperty("color") ? res.planningItem.project.color : "" }
                        newEntries[index].push({...planningEntry, userID: res.planningItem.userID, type: res.planningItem.type, id: res.planningItem.id, issueDate: res.planningItem.issueDate, project})
                    }
                })
            })
            setDays({days: days.days, entries: newEntries})
            setPlanningItems([...planningItems, res.planningItem])
        })
        .catch(error => {
            setErrorMessage(error.message);
        });
    }

    function previousWeek() {
        let newDate = days.days[0]
        newDate.setUTCDate(newDate.getUTCDate() - 1) // this will get us in the previous week
        setWeek(getWeekNumber(newDate))
        navigate("/planning/" + newDate.getUTCFullYear() + "/" + (newDate.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + newDate.getUTCDate().toString().padStart(2, '0') + "/" + selectedUserID)
    }
    function nextWeek() {
        let newDate = days.days[6]
        newDate.setUTCDate(newDate.getUTCDate() + 1) // this will get us in the next week
        setWeek(getWeekNumber(newDate))
        navigate("/planning/" + newDate.getUTCFullYear() + "/" + (newDate.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + newDate.getUTCDate().toString().padStart(2, '0') + "/" + selectedUserID)
    }

    function onClickExistingEntry(userID, day, planType) {
        let newPlanningItem = { id: "", issueDate: "", entries: []}
        planningItems.forEach(planningItem => {
            if(planningItem.userID === userID && planningItem.type === planType) {
                planningItem.entries.forEach(planningEntry => {
                    let startDate = new Date(planningEntry.startDate)
                    let endDate = new Date(planningEntry.startDate)
                    endDate.setUTCHours(endDate.getUTCHours() + planningEntry.durationHours)
                    if(day >= startDate && day <= endDate) {
                        if((getRows() >= 1 && planningEntry.durationHours === 8) || (getRows() >= 2 && planningEntry.durationHours === 4) || (getRows() > 2 && planningEntry.durationHours === 1)) {
                            newPlanningItem = planningItem
                        }
                    }
                })
            } 
        })
        setSelectedPlanningItem(newPlanningItem)
        setLock(true) // set a lock so no other components will react to clicks
    }

    function unSelectPlanningItem() {
        setSelectedPlanningItem({id: "", issueDate: "", userID: "", entries: []})
    }

    function deleteSelectedPlanningItem() {
        const params = {
            body: {
                action: "deletePlanningItem",
                planningItem: {
                    id: selectedPlanningItem.id,
                    issueDate: selectedPlanningItem.issueDate,
                    userID: selectedPlanningItem.userID,
                },
                org: props.org,
            },
        };
        API
        .post("billing", "/billing", params)
        .then(res => {
            let newEntries = Array.from({length: days.entries.length}, _ => [])
            for(let i = 0; i < days.entries.length; i++) {
                days.entries[i].forEach(planningEntry => {
                    if(!(planningEntry.id === selectedPlanningItem.id && planningEntry.issueDate === selectedPlanningItem.issueDate)) {
                        newEntries[i].push(planningEntry)
                    }
                })
            }
            setDays({days: days.days, entries: newEntries})
            setSelectedPlanningItem({id: "", issueDate: "", userID: "", entries: []})
        })
        .catch(error => {
            setErrorMessage(error.message);
        });
    }

    function getProjectsShown() {
        let projects = []
        for(let i = 0; i < days.entries.length; i++) {
            days.entries[i].forEach(planningEntry => {
                if(!projects.includes(planningEntry.project.issueDate)) {
                    projects.push(planningEntry.project.issueDate)
                }
            })
        }
        return projects
    }

    if(loading || days.days.length === 0 || users.length === 0) {
        return (
            <div>
                <Header {...props} handleUserChange={handleUserChange} onViewChange={onViewChange} view={view} selectedUserID={selectedUserID} />
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    <Spinner animation="border"/>
                }
            </div>
        )
    }
    return (
        <div>
            <Header {...props} handleUserChange={handleUserChange} onViewChange={onViewChange} view={view} selectedUserID={selectedUserID} />
            {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    null
            }
            <Table bordered responsive>
            <PlanningHeader {...props} days={days.days} nextWeek={nextWeek} previousWeek={previousWeek} />
            <tbody>
            {users.filter(user => !(user.hasOwnProperty("hide") && user.hide)).map((user, userIndex) => {
                let entries = []
                for(let y=0; y < getRows(); y++) {
                    entries.push(
                        <PlanningEntry
                            key={user.userID + "-" + y + "-" + userIndex}
                            {...props}
                            days={days.days}
                            entries={days.entries}
                            user={user}
                            view={view}
                            setView={setView}
                            row={y}
                            totalRows={getRows()}
                            onSubmitSelectedRange={onSubmitSelectedRange}
                            onClickExistingEntry={onClickExistingEntry}
                            selectedPlanningItem={selectedPlanningItem}
                            unSelectPlanningItem={unSelectPlanningItem}
                            deleteSelectedPlanningItem={deleteSelectedPlanningItem}
                            lock={lock}
                            setLock={setLock}
                            projects={projects}
                            setActiveCell={setActiveCell}
                        />
                    )
                }
                return entries
            })}
            </tbody>
            </Table>
            <PlanningLegend projects={projects} projectsShown={getProjectsShown()} activeCell={activeCell} />
        </div>
    )
}

export default Planning;
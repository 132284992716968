
import React, { useState } from "react";

import countries from '../constants/countries'
import Form from 'react-bootstrap/Form';

function SelectCountry(props) {
    const [selected, setSelected] = useState("")

    React.useEffect(() => {
        setSelected(props.default)
    }, [props.default])

    const onChange = (e) => {
        setSelected(e.target.value)
        props.onChange(e.target.value)
    };

    return (
        <Form.Select className="form-select form-select-sm" onChange={(e) => onChange(e)} value={selected}>
            <option value="">Select country</option>
        {countries.map(country => (
            <option key={country.id} value={country.id}>{country.name}</option>
        ))}
        </Form.Select>
    )
}

export default SelectCountry;
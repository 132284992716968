import React, { useState } from "react";


import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';


import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import { API } from "aws-amplify";

import Spinner from 'react-bootstrap/Spinner';

import Dropzone from 'react-dropzone'
import axios from 'axios'

import EditInvoice from "./EditInvoice";
import SelectContract from "../contracts/SelectContract"

function NewInvoice(props) {
    //const [date, setDate] = useState(new Date());
    const [formInput, setFormInput] = useState({step: "", contractID: 0});
    
    const [contract, setContract] = useState({id: ""});
    const [loading, setLoading] = useState(true);
    const [invoiceDateYear, setInvoiceDateYear] = useState(new Date().getFullYear());
    const [invoiceDateMonth, setInvoiceDateMonth] = useState(new Date().getMonth());
    const [document, setDocument] = useState({})
    const [defaultDocument, setDefaultDocument] = useState({
        documentNumber: "",
        invoiceIssueDate: today(),
        dueDate: today(),
        currency: props.preferredCurrency,
        exchangeRate: 0,
        strExchangeRate: "",
        taxRate: 0,
        strTaxRate: "",
        lineItems: [],
        company: {},
    })

    function today() {
        return new Date().toISOString()
    }

    function addMonths() {
        let items = [];
        const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const d = new Date();
        d.setDate(1);
        for (let i=0; i<=11; i++) {
            items.push(<option key={d.getFullYear() + "-" + d.getMonth()} value={d.getFullYear() + "-" + d.getMonth()}>{monthName[d.getMonth()] + ' ' + d.getFullYear()}</option>);
            d.setMonth(d.getMonth() - 1);
        }
        return items
    }
    function onMonthChange(newInvoiceDate) {
        const newInvoiceDateSplitted = newInvoiceDate.split("-")
        if(newInvoiceDateSplitted.length === 2) {
            const invoiceDateYear = parseInt(newInvoiceDateSplitted[0])
            const invoiceDateMonth = parseInt(newInvoiceDateSplitted[1])
            getContractorTime(invoiceDateYear, invoiceDateMonth, contract)
        }
        
    }

    function submitContract(selectedContract) {
        setContract(selectedContract)
         getContractorTime(invoiceDateYear, invoiceDateMonth, selectedContract)
    }

    function calculateDueDate(days) {
        let result = new Date();
        result.setDate(result.getDate() + days);
        return result.toISOString()
      }
  
    function getContractorTime(invoiceDateYear, invoiceDateMonth, contract) {
        setInvoiceDateYear(invoiceDateYear)
        setInvoiceDateMonth(invoiceDateMonth)
        // set default contract ID (if nothing was selected), and set loading to true
        setLoading(true)
        let newFormInput = formInput
        newFormInput.step = "submitInvoiceDetails"
        setFormInput(newFormInput)
        
        // getContractorTime API call
        API
        .get("billing", "/billing?action=getContractorTime&org="+props.org+"&year="+invoiceDateYear+"&month="+(invoiceDateMonth + 1), {})
        .then(response => {
            let newFormInput = formInput
            newFormInput.billables = response
            setFormInput(newFormInput)
            // set document defaults
            let newDocument = defaultDocument
            newDocument.dueDate = calculateDueDate(contract.paymentTermsInDays)
            newDocument.lineItems = [{
                key: "billables",
                type: "Service",
                description: contract.description + " (period: "+invoiceDateYear+"-"+(invoiceDateMonth + 1).toString().padStart(2, "0")+")",
                quantity: newFormInput.hasOwnProperty("billables") ? newFormInput.billables.totalDays : 0,
                unitPrice: contract.dayRate,
                amount: Math.round(((formInput.billables.totalDays * contract.dayRate) + Number.EPSILON) * 100) / 100,
                readonly: true,
                unit: "day",
                org: props.org,
            }]
            if(contract.company.bankData === null || contract.company.bankData.length === 0) {
                newDocument.paymentData = [{
                    accountNumber: "",
                    holderName: "",
                    paymentReference: "",
                }]
            } else {
                newDocument.paymentData = contract.company.bankData
            }
            newDocument.company = contract.company
            newDocument.taxRate = contract.company.tax === "" ? 0 : contract.company.tax
            newDocument.strTaxRate = contract.company.tax.toString()
            setDefaultDocument(newDocument)
            setLoading(false)
        })
        .catch(error => {
            console.log("ERROR", error)
            console.log(error.response);
        });
    }
    function submitInvoiceDetails(documentData) {
        let newFormInput = formInput
        newFormInput.step = "uploadInvoice"
        setFormInput(newFormInput)
        setDocument(documentData)
    }
    
    function uploadInvoice(files) {
        const file = files[0];
        setLoading(true)
        API
        .get("billing", "/billing?action=getPresignedUploadURL&org="+props.org, {})
        .then(response => {
            const signedUrl = response.url
            const targetFilename = response.filename
      
            const options = {
              headers: {
                'Content-Type': file.type,
                "Content-Disposition": "attachment; filename=\""+targetFilename+".pdf\"",
              }
            };            
            axios.put(signedUrl, file, options)
            .then((res) => {
                submitUploadAndInvoice(targetFilename)
            })
            .catch((err) => {
                console.log(err);
            });
        })
        .catch(error => {
            console.log(error.response);
        });
    }
    function submitUploadAndInvoice(uploadKey) {
        // set page to submitted
        setLoading(true)
        let newFormInput = formInput
        newFormInput.step = "submitted"
        setFormInput(newFormInput)
        // prepare post
        const params = {
            body: {
                action: "newBill",
                contract: contract,
                uploadKey: uploadKey,
                year: invoiceDateYear.toString(),
                month: (invoiceDateMonth + 1).toString().padStart(2, '0'),
                document: document,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contracts => {
            setLoading(false)
        })
        .catch(error => {
            console.log(error);
        });
    }

        if(formInput.step === "submitted") {
            return (
                <div>
                    {!loading ?
                        <Container>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Submitted</h1> 
                            </div>
                            <p>Your invoice has been successfully submitted.</p>  
                        </Container>
                     :
                      <Spinner animation="border"/>
                    }
                </div>
            )
        } else if(formInput.step === "uploadInvoice") {
            return (
                <Container>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Submit new invoice</h1>
                    </div>
                    <Container className="mt-5">
                        {!loading ?
                            <Dropzone onDrop={acceptedFiles => uploadInvoice(acceptedFiles)} size={ 150 } multiple={false} accept="application/pdf">
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()} className="dropzone">
                                        <input {...getInputProps()} />
                                        <p className="dropzone-text">Drag and drop your invoice here, or click to select (PDF only)</p>
                                    </div>
                                    </section>
                                )}
                            </Dropzone>
                        :
                            <Spinner animation="border"/>
                        }
                    </Container>
                </Container>
            )
        } else if(formInput.step === "submitInvoiceDetails" && contract.id !== "") {
            return (
                <Container>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Submit new invoice</h1>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup aria-label="New Invoice group">
                            <Form.Select className="form-select form-select-sm" onChange={e => onMonthChange(e.target.value)}>
                                {addMonths()}
                            </Form.Select>
                            </ButtonGroup>
                         </ButtonToolbar>
                    </div>
                    {!loading ?
                        <EditInvoice {...props} preferredCurrency={props.preferredCurrency} submitting={loading} onSubmit={submitInvoiceDetails} defaults={defaultDocument} id="" issueData="" submitButton="next" />
                         :
                        <Spinner animation="border"/>
                    }
                    </Container>
            )
       } else {
                        
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h2 className="h3">Submit new invoice</h2>
                </div>
                <SelectContract {...props} onSubmit={submitContract} />
                
            </div>
        )
        }        
}


export default NewInvoice;
import { useState } from "react";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import { useCookies } from 'react-cookie';
import { API, Auth } from "aws-amplify";
import { Save } from "react-feather";

function Onboarding(props) {
    const [organization, setOrganization] = useState({
        name: "",
    })
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
    })
    const [errorMessage, setErrorMessage] = useState('');
    const [submitting, setSubmitting] = useState(false)
    const [, setCookie] = useCookies(['activeOrg']);

    const onOrganizationFieldChange = (propertyName) => (event) => {
        setOrganization({...organization,  [propertyName]: event.target.value})
    }
    const onUserFieldChange = (propertyName) => (event) => {
        setUser({...user,  [propertyName]: event.target.value})
    }

    function submitCreateOrganization() {
        if(organization.name === "") {
            setErrorMessage("Organization name cannot be empty")
            return
        }
        if(organization.firstName === "") {
            setErrorMessage("Firstname name cannot be empty")
            return
        }
        if(organization.lastName === "") {
            setErrorMessage("Lastname name cannot be empty")
            return
        }
        setSubmitting(true)
        const params = {
            body: {
                action: "newOrganization",
                organization: organization,
                user: user,
                org: "",
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(organization => {
            Auth.currentAuthenticatedUser()
            .then(cognitoUser => {
                const { refreshToken } = cognitoUser.getSignInUserSession();
                cognitoUser.refreshSession(refreshToken, (err, session) => {
                    setCookie("activeOrg", organization.orgID, { path: '/' });
                    // full reload page to get the new claims
                    window.location.href = '/'
                })
            })
        })
        .catch(error => {
            setErrorMessage(error.message);
            setSubmitting(false)
        });
    }

    return (
        <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">New Organization Setup</h1>
        </div>
        <div>
        {errorMessage !== "" ?
            <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
        }
        <Form.Group>
            <Row className="mt-5 d-flex align-items-center">
                <Col md="4">
                    Your Organization/Company Name
                </Col>
                <Col md="4">
                    <Form.Control size="sm" type="text" placeholder="Organization/Company name" onChange={onOrganizationFieldChange('name')} value={organization.name}/>
                </Col>
            </Row>
            <Row className="mt-5 d-flex align-items-center">
                <Col md="4">
                    Your Name
                </Col>
                <Col md="2">
                    <Form.Control size="sm" type="text" placeholder="First name" onChange={onUserFieldChange('firstName')} value={user.firstName}/>
                </Col>
                <Col md="2">
                    <Form.Control size="sm" type="text" placeholder="Last name" onChange={onUserFieldChange('lastName')} value={user.lastName}/>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md="2">
                    <Button variant="primary" disabled={submitting} onClick={submitCreateOrganization}>
                        <span>Create <Save size={18} /></span>
                    </Button>
                </Col>
            </Row>
        </Form.Group>
        </div>
        </div>
    )
}

export default Onboarding;
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import EntriesHeader from './EntriesHeader';
import Table from 'react-bootstrap/Table'
import EntryLine from './EntryLine';
import { API } from '@aws-amplify/api';
import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

function ListSubmittedItem(props) {
    const [days, setDays] = useState([])
    const [projectsToShow, setProjectsToShow] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [timesheetEntries, setTimesheetEntries] = useState([])
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('');
    const [collapsed, setCollapsed] = useState(true)

    React.useEffect(() => {
        if(props.timesheet.stopLoading) {
            setLoading(false)
        }
    }, [props.timesheet.stopLoading])

    React.useEffect(() => {
        if(collapsed) return // only load when uncollapsed
        let days = []
        const b = props.timesheet.startDate.split(/\D+/);
        let d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        d.setUTCHours(0, 0, 0)      
        for (let i = 0; i < 7; i++) {
            days.push(new Date(d.valueOf()));
            d.setDate(d.getDate() + 1)
        }
        setDays(days)       
        const start = days[0].toISOString()
        let endDate = days[6]
        endDate.setUTCHours(23, 59, 59, 999)
        const end = endDate.toISOString()
        API
        .get("billing", "/billing?action=listTimeAndProjectsForUser&org="+props.org+"&start="+start+"&end="+end+"&selectedUserID="+props.timesheet.user.userID, {})
        .then(res => {
            let newProjectsToShow = []
            Object.entries(res.helper).forEach( helperKey => {
                const helperKeyParts = helperKey[0].split("|")
                if(helperKeyParts.length === 6) {
                    newProjectsToShow.push({
                        projectID: helperKeyParts[0] + "|" + helperKeyParts[1] + "|" + helperKeyParts[2],
                        taskID: helperKeyParts[3] + "|" + helperKeyParts[4] + "|" + helperKeyParts[5]
                    })
                }
            })
            if(newProjectsToShow.length > 0) { // if we have projects to show (rows), then set the state
                setProjectsToShow(newProjectsToShow)
            }
            setUserProjects(res.projectNames)
            setTimesheetEntries({
                entries: res.timesheetEntries,
                helper: res.helper,
            })
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.org, props.timesheet.startDate, props.timesheet.user.userID, collapsed])
    function doNothing() {
        return
    }
    function durationToString(duration) {
        const durationHours = duration / 3600
        const durationMinutes = Math.round((durationHours - Math.floor(duration / 3600)) * 60)
        return Math.floor(durationHours).toString() + ":" + durationMinutes.toString().padStart(2, '0')
    }
    function getEntryTotals() {
        let totals = [0, 0, 0, 0, 0, 0, 0]

        if(!timesheetEntries.hasOwnProperty("helper")) {
            return totals
        }

        userProjects.forEach(userProject => {
            userProject.tasks.forEach(task => {
                const helperIndex = userProject.id + "|" + userProject.issueDate + "|" + task.id + "|" + task.issueDate
                const index = timesheetEntries.helper.hasOwnProperty(helperIndex) ? timesheetEntries.helper[helperIndex] : []
                for(let i = 0; i < index.length; i++) {
                    if (index[i] !== -1) {
                        totals[i] += timesheetEntries.entries[index[i]].duration
                    }
                }
            })
        })
        return totals
    }
    
    /*function isDayIncluded(day) {
        if(props.timesheet.days === undefined) {
            return true
        }
        let found = false
        props.timesheet.days.forEach(onlyShowDay => {
            if(day.toISOString().split("T")[0] === onlyShowDay.split("T")[0]) {
                found = true
            }
        })
        if(found) {
            return true
        }
        return false
    }*/
    function isDayIncludedByIndex(index) {
        if(props.timesheet.days === undefined) {
            return true
        }
        let found = false
        props.timesheet.days.forEach((_, includeIndex) => {
            if(index === includeIndex) {
                found = true
            }
        })
        if(found) {
            return true
        }
        return false
    }

    function approveTimesheet() {
        setLoading(true)
        props.patchTimesheetApproval({ ...props.timesheet, approved: true })
    }
    function unapproveTimesheet() {
        setLoading(true)
        props.patchTimesheetApproval({ ...props.timesheet, approved: false })
    }

    function onClickHeader(e) {
        setCollapsed(!collapsed)
    }

    if(loading) {
        return (
            <Accordion.Item eventKey={props.timesheet.id + "|" + props.timesheet.issueDate}>
            <Accordion.Header onClick={(e) => onClickHeader(e)}>{props.timesheet.user.firstName} {props.timesheet.user.lastName} for week starting {props.timesheet.startDate.split("T")[0]}<span className="mx-2">{props.timesheet.approved ? <Badge pill bg="success">Approved</Badge> : <Badge pill bg="info">Unapproved</Badge>}</span></Accordion.Header>
                <Accordion.Body>
                    {errorMessage !== "" ?
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    :
                        <Spinner animation="border"/>
                    }
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    return (
        <Accordion.Item eventKey={props.timesheet.id + "|" + props.timesheet.issueDate}>
            <Accordion.Header onClick={(e) => onClickHeader(e)}>{props.timesheet.user.firstName} {props.timesheet.user.lastName} for week starting {props.timesheet.startDate.split("T")[0]}<span className="mx-2">{props.timesheet.approved ? <Badge pill bg="success">Approved</Badge> : <Badge pill bg="info">Unapproved</Badge>}</span></Accordion.Header>
            <Accordion.Body>
                {errorMessage !== "" ?
                    <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                        {errorMessage}
                    </Alert>
                :
                    null
                }
                <Table striped bordered hover className="timesheet-table">
                <thead>
                    <EntriesHeader {...props} showPreviousNextWeek={false} days={days} />
                </thead>
                <tbody>
                    {projectsToShow.map(project => (
                        <EntryLine key={project.projectID + "|" + project.taskID} project={project} userProjects={userProjects} timesheetEntries={timesheetEntries} durationToString={durationToString} readOnly={true} onClickEditTime={doNothing} isDayIncluded={isDayIncludedByIndex} />
                    ))}
                    <tr>
                        <td key="addRow"></td>
                        {getEntryTotals().map((value, index) => {
                            if(!isDayIncludedByIndex(index)) {
                                return (
                                    <td className="timesheet-totals-disabled" key={index}></td>
                                )
                            }
                            return (
                                <td className="timesheet-totals" key={index}>{projectsToShow.length === 0 ? "" : durationToString(value)}</td>
                            )
                        })}
                    </tr>
                    {props.timesheet.approved ?
                        <tr className="timesheet-approval">
                            <td colSpan="8" className="timesheet-approval">
                                <Button onClick={() => unapproveTimesheet()}>Unapprove</Button>
                            </td>
                        </tr>
                    :
                        <tr className="timesheet-approval">
                            <td colSpan="8" className="timesheet-approval">
                                <Button onClick={() => approveTimesheet()}>Approve</Button>
                            </td>
                        </tr>
                    }
                </tbody>
                </Table>
            </Accordion.Body>
        </Accordion.Item>
    )
}
export default ListSubmittedItem;
import { ChevronLeft, ChevronRight } from 'react-feather';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

function EntriesHeader(props) {
    const monthFormatter = new Intl.DateTimeFormat('en', { month: 'short', timeZone: "UTC" });
    
    return (
        <tr key="header">
        <th className="timesheet-topleft">
            {props.showPreviousNextWeek ?
                    <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="previous or next timesheet group">
                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => props.previousWeek()}><ChevronLeft size={22} /></button>
                        <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => props.nextWeek()}><ChevronRight size={22} /></button>
                    </ButtonGroup>
                    </ButtonToolbar>
                :
                    null
            }
        </th>
        
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((dayName, index) => {
            if(props.isDayIncluded !== undefined && !props.isDayIncluded(props.days[index])) {
                return (
                    <th key={dayName}></th>
                )
            }
            return (
                <th key={dayName}>{dayName}<br />{props.days[index].getUTCDate()}&nbsp;{monthFormatter.format(props.days[index])}.</th>
            )

        })}
    </tr>

    )
}
export default EntriesHeader
import React from "react";
import { Link } from "react-router-dom";

import Table from 'react-bootstrap/Table'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { API } from "aws-amplify";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Activity, ChevronRight, HelpCircle } from "react-feather";

import { useCookies } from 'react-cookie';

function Switch(props) {
    const [cookies, setCookie] = useCookies(['activeOrg']);
  

    function switchOrganization() {
        setCookie("activeOrg", props.id, { path: '/' });
     }
  
    return (
      <div>
        {(cookies.activeOrg && cookies.activeOrg === props.id) || props.current === props.id ?
            ""
        :
        <Button variant="outline-secondary" size="sm" onClick={switchOrganization}>
            Switch to {props.name}<ChevronRight size={16} />
        </Button>
        }
        
      </div>

    );
  }

  function PontoIntegration(props) {
    const renderTooltip1 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Switch to this organization first to enable
      </Tooltip>
    );
    const renderTooltip2 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Ponto integration needs to be enabled for every user who wants to initate payments
      </Tooltip>
    );
    if(props.active) {
      return (
        <span className="active">
          <Activity size={16} /> Active
        </span>
      )
    } else {
      if(props.id === props.current) {
        return (
          <span>
            <Link to="/ponto/auth">Enable now</Link>&nbsp;
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip2}
            >
            <HelpCircle size={16}/>
            </OverlayTrigger>
          </span>
        )
      } else {
        return (
          <span>
            
            Not Active&nbsp;
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip1}
            >
            <HelpCircle size={16}/>
            </OverlayTrigger>
          </span>
        )
      }
      
    }
  }

class Organizations extends React.Component {
   
  constructor(props) {
    super(props);
    this.state = {
        organizations: [],
        pontoIntegrations: [],
        loading: false,
    };
  }
  componentDidMount() {
    this.setState({loading: true})
    API
    .get("billing", "/billing?action=listOrganizations&org="+this.props.org, {})
    .then(response => {
        const loading = false
        const organizations = response.organizations
        const pontoIntegrations = response.pontoIntegrations
        this.setState({organizations, pontoIntegrations, loading})
    })
    .catch(error => {
        console.log(error);
    });
  }

  addOrganizations(organizations) {
    let items = [];

    organizations.forEach((organization, index) => {
        items.push(<tr key={organization.orgID}>
            <td>{organization.name}</td>
            {this.props.role === "admin" ? <td>{organization.documentEmail}</td> : null }
            {this.props.role === "admin" ? <td><PontoIntegration active={this.state.pontoIntegrations[index]} id={organization.orgID} current={this.props.org} /></td> : null}
            <td>
                <Switch id={organization.orgID} current={this.props.org} name={organization.name} />
            </td>
            </tr>);
      })

    return items
  }

  render() {
    if(this.state.loading) {
      return (
        <div>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Organizations</h1>
          </div>
          <Spinner animation="border"/>
        </div>
      )
    }
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Organizations</h1>
        </div>
          <Table striped bordered hover>
            <thead>
                <tr key="header">
                <th>Organization</th>
                {this.props.role === "admin" ? <th>Mailbox</th> : null }
                {this.props.role === "admin" ? <th>Ponto Integration</th> : null }
                <th></th>
                </tr>
            </thead>
            <tbody>
                {this.addOrganizations(this.state.organizations)}
            </tbody>
          </Table>
      </div>
    );
  }
}
  
  export default Organizations;
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import InputGroup from "react-bootstrap/InputGroup"

import Button from 'react-bootstrap/Button';

import React, { useState } from "react";
import { Save } from 'react-feather';

import { PlusCircle } from 'react-feather';

import SelectContact from '../contacts/SelectContact';

import EditInvoiceLineItem from "../invoices/EditInvoiceLineItem"

function EditClientContract(props) {
    const [contract, setContract] = useState(props.contract)
    const [formatter, setFormatter] = useState(new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.contract.contact.currency === "" ? props.preferredCurrency : props.contract.contact.currency,
    }))
    const [currency, setCurrency] = useState(props.contract.contact.currency)

    const onContractFieldChange = (propertyName) => (event) => {
        if(propertyName === "tax") {
            if(!isNumeric(event.target.value) && event.target.value !== "") {
                return
            }
            setContract({
                ...contract,
                [propertyName]: parseFloat(event.target.value),
                strTax : event.target.value,
              })
        } else {
            setContract({...contract,  [propertyName]: event.target.value})
        }
    }

    const onContractFieldChangeBool = (propertyName) => (event) => {
        setContract({...contract,  [propertyName]: event.target.value === "on" || event.target.value === "enabled" ? true : false})
    }

    const onContractBillablesFieldChange = (propertyName) => (event) => {
        setContract({...contract,  billables: [ { ...contract.billables[0], [propertyName]: event.target.value } ] })
    }
    
    const updateLineItems = (newItem, index) => {
        const newLineItems = [
            ...contract.billables[0].lineItems.slice(0,index),
            newItem,
            ...contract.billables[0].lineItems.slice(index+1)
        ]
        setContract({...contract, billables: [ { ...contract.billables[0], lineItems: newLineItems } ] })
    };

    const addLineItem = (newLineItem) => {
        const newLineItems = [...contract.billables[0].lineItems, newLineItem]
        setContract({...contract, billables: [ { ...contract.billables[0], lineItems: newLineItems } ] })
    };

    function removeRow(rowNumber) {
        let newLineItems = [...contract.billables[0].lineItems]
        newLineItems.splice(rowNumber, rowNumber)
        setContract({...contract, billables: [ { ...contract.billables[0], lineItems: newLineItems } ] })
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const oneLineItemChange = (propertyName, index) => (event) => {
        const value = event.target.value
        if(propertyName === "quantity") {
            if(!isNumeric(value) && value !== "") {
                return
            }
            updateLineItems({
                ...contract.billables[0].lineItems[index],
                [propertyName]: parseFloat(value),
                strQuantity : value,
              }, index)
        } else if(propertyName === "unitPrice") {
            if(!isNumeric(value) && value !== "") {
                return
            }
            updateLineItems({
                ...contract.billables[0].lineItems[index],
                [propertyName]: parseFloat(value),
                strUnitPrice : value,
              }, index)
        } else {
            updateLineItems({
                ...contract.billables[0].lineItems[index],
                [propertyName]: value
              }, index)
        }
    }

    function onSetContact(contact) {
        setContract({...contract, contact: {
            id: contact.id,
            issuedate: contact.issueDate,
        } })
        setFormatter(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: contact.currency,
        }))
        setCurrency(contact.currency)
    }
    function submitEditContract() {
        props.onSubmit(contract)
    }

    function addExtraRow() {
        addLineItem({
            type: "product",
            description: "",
            quantity: 0.00,
            unitPrice: 0.00,
        })
    }

    function getTotal() {
        let total = 0
        if(contract.billables[0].lineItems === null) {
            return total
        }
        for(let i = 0; i < contract.billables[0].lineItems.length; i++) {
            if(!Number.isNaN(contract.billables[0].lineItems[i].quantity) && !Number.isNaN(contract.billables[0].lineItems[i].unitPrice)) {
                total += contract.billables[0].lineItems[i].quantity * contract.billables[0].lineItems[i].unitPrice
            }
        }
        return total
    }

    if(contract.length === 0) {
        return null
    }


    return (
    <Form.Group>
    <Row className="mt-5 d-flex align-items-center">
        <Col md="4">
            Contract Name
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="Contract name" onChange={onContractFieldChange('name')} value={contract.name}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Client
        </Col>
        <Col md="4">
            <SelectContact onChange={onSetContact} default={contract.contact.id + "|" + contract.contact.issueDate} org={props.org} displayName="Client" />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Purchase Order Reference
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="Purchase Order Reference" onChange={onContractFieldChange('purchaseOrderReference')} value={contract.purchaseOrderReference}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Payment terms in days
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="payment terms" onChange={onContractFieldChange('paymentTermsInDays')} value={contract.paymentTermsInDays}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Tax / VAT
        </Col>
        <Col md="4">
            <InputGroup className="mb-3" size="sm">
                <Form.Control size="sm" type="text" placeholder="tax / vat" onChange={onContractFieldChange('tax')} value={contract.hasOwnProperty("strTax") ? contract.strTax : contract.tax}/>
                <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Billable Frequency
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onContractBillablesFieldChange('frequency')} value={contract.billables[0].frequency}>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="yearly">Yearly</option>
            </Form.Select>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Start date
        </Col>
        <Col md="4">
            <Form.Control size="sm" type="text" placeholder="yyyy-mm-dd" onChange={onContractFieldChange('startDate')} value={contract.startDate}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            End date
        </Col>
        <Col md="2">
            <Form.Check type="checkbox" label="Contract has end date" checked={contract.hasEndDate} onChange={onContractFieldChangeBool("hasEndDate")} />
        </Col>
        {contract.hasEndDate ?
            <Col md="2">
                <Form.Control size="sm" type="text" placeholder="yyyy-mm-dd" onChange={onContractFieldChange('endDate')} value={contract.endDate}/>
            </Col>
        : null }
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Monthly Automatic Invoicing
        </Col>
        <Col md="4">
            <Form.Select className="form-select form-select-sm" onChange={onContractFieldChangeBool('autoInvoice')} value={contract.autoInvoice ? "enabled" : "disabled"}>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
            </Form.Select>
        </Col>
    </Row>
    <Row className="mt-5">
        <Table striped bordered hover>
        <thead>
            <tr key="header">
                <th>Item Type</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            {contract.billables[0].lineItems.map((lineItem, lineItemKey) => (
                <EditInvoiceLineItem currency={currency} key={lineItemKey} lineItem={lineItem} lineItemKey={lineItemKey} onChange={oneLineItemChange} removeRow={removeRow} />
            ))}
        </tbody>
        </Table>
    </Row>
    <Row>
        <Col md="2">
            <Button variant="outline-secondary" size="sm" onClick={() => addExtraRow() }>
                <PlusCircle size={16} /> Add Row
            </Button>
        </Col>
        <Col md="5">
        </Col>
        <Col md="5">
        <p className="text-end fs-3 fw-bold">
            Total Payable {contract.billables[0].frequency}: {formatter.format(getTotal())}
        </p>
        </Col>

    </Row>
    <Row className="mt-3">
        <Col md="2">
            <Button variant="primary" disabled={props.submitting} onClick={submitEditContract}>
                <span>Save Contract <Save size={18} /></span>
            </Button>
        </Col>
    </Row>


    </Form.Group>
    )
}
export default EditClientContract
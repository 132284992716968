import React from "react";

import Table from 'react-bootstrap/Table'

import Form from 'react-bootstrap/Form';
import {Link, useNavigate} from 'react-router-dom';
import { FilePlus } from "react-feather";

function ListContractorContracts(props) {
  const navigate = useNavigate();

  const onRowClick = (id, issueDate) => {
    if(props.role === "admin") navigate("/getContract?id=" + id +"&issueDate="+issueDate) 
  }

  function addContracts() {
    let items = [];

    if(props.contracts.length > 0) {    
      props.contracts.forEach(contract => {
        const key = contract.id + "|" + contract.issueDate
        // Create our number formatter.
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: contract.company.currency,
        });
        items.push(<tr key={key} className={props.role === "admin" ? "clickable" : ""}>
            <td><Form.Check type="checkbox" label="" name={key} checked={props.checkedItems.has(key) ? props.checkedItems.get(key) : false} onChange={(e) => props.handleCheckboxChange(e.target)}  /></td>
            <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{contract.description}</td>
            <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{formatter.format(contract.dayRate)}</td>
            <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{new Date(contract.startDate).toISOString().split("T")[0]}</td>
            <td onClick={() => onRowClick(contract.id, contract.issueDate)}>{contract.hasEndDate ? new Date(contract.endDate).toISOString().split("T")[0] : "-"}</td>
            </tr>);
      })
    }
    return items
  }

  return (
      <div>
        <Table striped bordered hover>
            <thead>
                <tr key="header">
                <th></th>
                <th>Name</th>
                <th>Dayrate</th>
                <th>Start Date</th>
                <th>End Date</th>
                </tr>
            </thead>
            <tbody>
                {addContracts()}
            </tbody>
      </Table>
      {props.role === "contractor" ?
        <Link to="/contractor/newInvoice"><button type="button" className="btn btn-outline-primary"><FilePlus size={16} /> New Invoice</button></Link>
        : null
      }
      </div>
  );
}
  
  export default ListContractorContracts;
import React, { useState } from "react";
import Button from 'react-bootstrap/Button';

import { HexColorPicker } from "react-colorful";


function ColorPicker(props) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [color, setColor] = useState(props.color)
    const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      }

      React.useEffect(() => {
          if(props.color === "") {
            const randomColor = hslToHex(~~(360 * Math.random()), 70, 80)
            setColor(randomColor)
            props.onChange(randomColor)
          }
      }, [props])

      function hslToHex(h, s, l) {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
          const k = (n + h / 30) % 12;
          const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
          return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
      }
      return (
        <div>
          <Button variant="outline-secondary border-dark" onClick={() => setDisplayColorPicker(true)}>Pick Color</Button>
          { displayColorPicker ? <div style={ popover }>
            <div style={ cover } onClick={() => setDisplayColorPicker(false)}/>
            <HexColorPicker color={color} onChange={(color) => props.onChange(color)} />
          </div> : null }
        </div>
      )
}

export default ColorPicker
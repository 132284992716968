import { Auth } from '@aws-amplify/auth';
import { config } from './config'

const awsExports = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: config.aws.region,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: config.aws.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.aws.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.aws.userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH',

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'in4it-billing-app.auth.eu-west-1.amazoncognito.com',
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: config.url.FRONTEND_URL,
            redirectSignOut: config.url.FRONTEND_URL,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    API: {
        endpoints: [
            {
                name: "billing",
                endpoint: config.url.API_URL,
                custom_header: async () => {
                    return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
}

export default awsExports;
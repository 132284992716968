import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

import { FilePlus } from 'react-feather'
import { API } from '@aws-amplify/api'
import React, { useState } from 'react'

import EditContact from './EditContact'

function Header(props) {
    return (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Contact</h1>
        <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup aria-label="New contacts group">
                <Link to="/newContact"><button type="button" className="btn btn-sm btn-outline-secondary"><FilePlus size={16} /> New Contact</button></Link>
            </ButtonGroup>
        </ButtonToolbar>
        </div>
    )
}

function GetContact(props) {
    const [showSaved, setShowSaved] = useState(props.showSaved);
    const [submitting, setSubmitting] = useState(false)

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [contact, setContact] = useState({})

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    React.useEffect(() => {

        API
        .get("billing", "/billing?action=getContact&org="+props.org + "&id=" + props.id + "&issueDate=" + props.issueDate, {})
        .then(contact => {
            if(contact.length > 0) {
                if(contact[0].bankData === null || contact[0].bankData.length === 0) {
                    contact[0].bankData = [
                        {
                            accountNumber: "",
                            holderName: "",
                        }
                    ]
                }
                if(contact[0].contactDetails === null || contact[0].contactDetails.length === 0) {
                    contact[0].contactDetails = [
                        {
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            mobile: "",
                        }
                    ]
                }
                setContact({
                    ...contact[0],
                    strTax: contact[0].tax.toString(),
                })
            } else {
                setErrorMessage("No contact returned")
            }
            setLoading(false)

        })
        .catch(error => {
            setErrorMessage(error.message)
        });
    }, [props.role, props.org, props.id, props.issueDate])

    function onSubmit(newContact) {
        setSubmitting(true)
        const params = {
            body: {
                action: "updateContact",
                contact: newContact,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(contact => {
            setSubmitting(false)
            setShowSaved(true)
            scrollToTop()
        })
        .catch(error => {
            setErrorMessage(error.message);
            scrollToTop()
            setSubmitting(false)
        });
    }

    if(loading) {
        return (
            <div>
                <Header />
                {errorMessage !== "" ?
                        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                            {errorMessage}
                        </Alert>
                    :
                        <Spinner animation="border"/>
                    }
                
            </div>
        )
    }

    return (
        <div>
        <Header />
        {showSaved ?
            <Alert variant="success" onClose={() => setShowSaved(false)} dismissible>
                Contact saved
            </Alert>
            : ""
            }  
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <EditContact contact={contact} onSubmit={onSubmit} submitting={submitting} {...props} />
        </div>
    )
    
}
export default GetContact;
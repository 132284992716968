import React, {useState} from 'react'
import BTable from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import Calendar from 'react-calendar'

import { ArrowDownCircle, ArrowUpCircle } from 'react-feather'

import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'
import { matchSorter } from 'match-sorter'

import { API } from "aws-amplify";

import 'react-calendar/dist/Calendar.css';

function Header(props) {
  const [calendarValue, setCalenderValue] = useState([new Date(), new Date()]);
  const [showCalendar, setShowCalendar] = useState(false)

  function onCalendarChange(value) {
    setCalenderValue(value)
    setShowCalendar(false)
    props.onCalendarChange(value)
  }

  function getCalendarValue() {
    if(calendarValue[0].getFullYear() === calendarValue[1].getFullYear() && calendarValue[0].getMonth() === calendarValue[1].getMonth()) {
      return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0")
    } else {
      return calendarValue[0].getFullYear() + "-" + (calendarValue[0].getMonth()+1).toString().padStart(2, "0") + " until " + calendarValue[1].getFullYear() + "-" + (calendarValue[1].getMonth()+1).toString().padStart(2, "0")
    }
  }
  return (
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Audit Log</h1>
          <div>
            <Button variant="outline-secondary" onClick={() => setShowCalendar(!showCalendar)}>Date: {getCalendarValue()}</Button>
            {showCalendar ? 
              <Calendar className="calendar-overlay"
              onChange={onCalendarChange}
              value={calendarValue}
              view="year"
              maxDetail="year"
              selectRange={true}
            />
            :
              null
            }
          </div>
      </div>
  )
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

function ColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  

function Table({ columns, data, loading }) {
  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  )

  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th key={index}>
                <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                      &nbsp;{column.isSorted
                        ? column.isSortedDesc
                          ? <ArrowDownCircle size={16} />
                          : <ArrowUpCircle size={16} />
                        : ''}
                  </span>
                </div>
                {/* Render the columns filter UI */}
                <div>{column.canFilter ? column.render('Filter') : <br />}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            </th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? 
          <tr><td colSpan={visibleColumns.length}><Spinner animation="border"/></td></tr>
        :
          null
        }
        {loading ? null : rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </BTable>
  )
}

function AuditLog(props) {
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [errorMessage, setErrorMessage] = useState('');
    const [auditLogEntries, setAuditLogEntries] = useState({
        timesheetEntriesAuditLog: [],
    });
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        const start = dateRange[0].getFullYear() + "-" + (dateRange[0].getMonth()+1).toString().padStart(2, "0") + "-01T00:00:00.000000Z"
        const end = dateRange[1].toISOString()
        API
        .get("billing", "/billing?action=listAuditLogs&org="+props.org+"&start="+start+"&end="+end, {})
        .then(res => {
            res.timesheetEntriesAuditLog.forEach((timeEntry, index) => {
              res.users.forEach(user => {
                if(user.userID === timeEntry.userID) {
                    res.timesheetEntriesAuditLog[index].user = user.firstName + " " + user.lastName
                }
              })
              if(timeEntry.newEntry.id !== "") {
                enrichNewEntry(res.timesheetEntriesAuditLog[index].newEntry, res.projectNames, res.users)
              }
              if(timeEntry.oldEntry.id !== "") {
                enrichNewEntry(res.timesheetEntriesAuditLog[index].oldEntry, res.projectNames, res.users)
              }
              if(timeEntry.action === "newTimesheetEntry") {
                  timeEntry.description = "Logged " + timeEntry.newEntry.duration / 3600 + " hours on " + timeEntry.newEntry.entrySortKey.entryDate.split("T")[0] + " with description: " + timeEntry.newEntry.description 
                  timeEntry.clientName = timeEntry.newEntry.clientName
                  timeEntry.projectName = timeEntry.newEntry.projectName
                  timeEntry.taskName = timeEntry.newEntry.taskName
                  if(timeEntry.newEntry.entrySortKey.userID !== timeEntry.userID) {
                    res.users.forEach(user => {
                      if(user.userID === timeEntry.newEntry.entrySortKey.userID) {
                        res.timesheetEntriesAuditLog[index].user += " for (" + user.firstName + " " + user.lastName + ")"
                      }
                    })
                  } 
              }
              if(timeEntry.action === "deleteTimesheetEntry") {
                timeEntry.description = "Deleted " + timeEntry.oldEntry.duration / 3600 + " hours on " + timeEntry.oldEntry.entrySortKey.entryDate.split("T")[0] + " with description: " + timeEntry.oldEntry.description
                timeEntry.clientName = timeEntry.oldEntry.clientName
                timeEntry.projectName = timeEntry.oldEntry.projectName
                timeEntry.taskName = timeEntry.oldEntry.taskName
                if(timeEntry.oldEntry.entrySortKey.userID !== timeEntry.userID) {
                  res.users.forEach(user => {
                    if(user.userID === timeEntry.oldEntry.entrySortKey.userID) {
                      res.timesheetEntriesAuditLog[index].user += " for (" + user.firstName + " " + user.lastName + ")"
                    }
                  })
                } 
              }
              if(timeEntry.action === "updateTimesheetEntry") {
                if(timeEntry.oldEntry.duration !== timeEntry.newEntry.duration && timeEntry.oldEntry.description !== timeEntry.newEntry.description) {
                    timeEntry.description = "Updated " + timeEntry.oldEntry.duration / 3600 + " to " + timeEntry.newEntry.duration / 3600 + " hours on " + timeEntry.newEntry.entrySortKey.entryDate.split("T")[0] + " with updated description from '" + timeEntry.oldEntry.description + "' to '" + timeEntry.newEntry.description + "'"
                }
                if(timeEntry.oldEntry.duration !== timeEntry.newEntry.duration && timeEntry.oldEntry.description === timeEntry.newEntry.description) {
                    timeEntry.description = "Updated " + timeEntry.oldEntry.duration / 3600 + " to " + timeEntry.newEntry.duration / 3600 + " hours on " + timeEntry.newEntry.entrySortKey.entryDate.split("T")[0] + " with no changes to description"
                }
                if(timeEntry.oldEntry.duration === timeEntry.newEntry.duration && timeEntry.oldEntry.description !== timeEntry.newEntry.description) {
                    timeEntry.description = "Updated description on " + timeEntry.newEntry.entrySortKey.entryDate.split("T")[0] + " from '" + timeEntry.oldEntry.description + "' to: " + timeEntry.newEntry.description
                }
                if(timeEntry.oldEntry.duration === timeEntry.newEntry.duration && timeEntry.oldEntry.description === timeEntry.newEntry.description) {
                    if(timeEntry.oldEntry.locked !== timeEntry.newEntry.locked) {
                      if(timeEntry.newEntry.locked) {
                        timeEntry.description = "Time entry locked"
                      } else {
                        timeEntry.description = "Time entry unlocked"
                      }
                    } else {
                      timeEntry.description = "No updates made (on " + timeEntry.newEntry.entrySortKey.entryDate.split("T")[0] + ")"
                    }
                }
                timeEntry.clientName = timeEntry.oldEntry.clientName
                timeEntry.projectName = timeEntry.oldEntry.projectName
                timeEntry.taskName = timeEntry.oldEntry.taskName
                if(timeEntry.newEntry.entrySortKey.userID !== timeEntry.userID) {
                  res.users.forEach(user => {
                    if(user.userID === timeEntry.newEntry.entrySortKey.userID) {
                      res.timesheetEntriesAuditLog[index].user += " for (" + user.firstName + " " + user.lastName + ")"
                    }
                  })
                } 
              }
              const timestamp = new Date(Date.parse(timeEntry.timestamp))
              timeEntry.timestampDate = timestamp.getUTCFullYear() + "-" + ((timestamp.getUTCMonth())+1).toString().padStart(2, "0") + "-" + timestamp.getUTCDate()
              timeEntry.timestampTime = timestamp.getUTCHours().toString().padStart(2, "0") + ":" + timestamp.getUTCMinutes().toString().padStart(2, "0") + ":" + timestamp.getUTCSeconds().toString().padStart(2, "0")
            })
            setAuditLogEntries(res)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });

    }, [props.org, dateRange])

    function enrichNewEntry(entry, projectNames, users) {
        projectNames.forEach(project => {
          if(project.issueDate === entry.entrySortKey.projectIssueDate) {
            entry.projectName = project.name
            entry.clientName = project.clientName
            project.tasks.forEach(task => {
              if(task.issueDate === entry.entrySortKey.projectTaskIssueDate) {
                entry.taskName = task.taskName
              }
            })
          }
          users.forEach(user => {
            if(user.userID === entry.entrySortKey.userID) {
              entry.user = user.firstName + " " + user.lastName
            }
          })
        })
    }

    function onCalendarChange(value) {
      setLoading(true)
      setDateRange(value)
    }

    // This is a custom filter UI for selecting
    // a unique option from a list
    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      // Calculate the options for filtering
      // using the preFilteredRows
      const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
          if(row.hasOwnProperty("values")) {
            options.add(row.values[id])
          }
        })
        return [...options.values()]
      }, [id, preFilteredRows])

      // Render a multi-select box
      return (
        <select
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined)
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      )
    }

    const columns = React.useMemo(
        () => [
          {
            Header: 'Date (UTC)',
            accessor: 'timestampDate',
            Filter: ColumnFilter,
            filter: 'fuzzyText',
          },
          {
            Header: 'Time (UTC)',
            accessor: 'timestampTime',
            Filter: ColumnFilter,
            filter: 'fuzzyText',
          },
          {
            Header: 'Action',
            accessor: 'action',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'User',
            accessor: 'user',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Client Name',
            accessor: 'clientName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Project Name',
            accessor: 'projectName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Task Name',
            accessor: 'taskName',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Description',
            accessor: 'description',
            Filter: ColumnFilter,
            filter: 'fuzzyText',
          },
        ],
        []
      )

    return (
      <div>
        <Header onCalendarChange={onCalendarChange} />
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        <Table columns={columns} data={auditLogEntries.timesheetEntriesAuditLog} loading={loading} />
      </div>
    )

}
export default AuditLog;
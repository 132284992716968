import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TopNumbers(props) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.preferredCurrency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    function formatData(value) {
        if(!props.data.hasData) {
            return "-"
        }
        if(props.organizationAttributes.configuration.privacyMode) return "***"
        return formatter.format(value)
    }

    return (
        <Card>
            <Card.Body>
                <Row className="text-center">
                    <Col className="topnumbers-border-right" md="2">
                        <Card.Title>Billables Revenue</Card.Title>
                        <Card.Text>
                            {formatData(props.data.total)}
                        </Card.Text>
                    </Col>
                    <Col className="topnumbers-border-right" md="2">
                        <Card.Title>Billables Costs</Card.Title>
                        <Card.Text>
                            {formatData(props.data.totalBillableCosts)}
                        </Card.Text>
                    </Col>
                    <Col className="topnumbers-border-right-2x" md="2">
                        <Card.Title>Billables Profit</Card.Title>
                        <Card.Text>
                            {formatData(props.data.totalProfit)}
                        </Card.Text>
                    </Col>
                    <Col className="topnumbers-border-right" md="2">
                        <Card.Title>Total Revenue</Card.Title>
                        <Card.Text>
                        {formatData(props.data.total + props.data.totalRecurring)}
                        </Card.Text>
                    </Col>
                    <Col className="topnumbers-border-right" md="2">
                        <Card.Title>Total Cost</Card.Title>
                        <Card.Text>
                            {formatData(props.data.totalBillableCosts + props.data.totalOtherCosts)}
                        </Card.Text>
                    </Col>
                    <Col className="" md="2">
                        <Card.Title>Total Profit</Card.Title>
                        <Card.Text>
                            {formatData(props.data.total + props.data.totalRecurring - props.data.totalBillableCosts - props.data.totalOtherCosts)}
                        </Card.Text>
                    </Col>
                </Row>
                
            </Card.Body>
        </Card>
    )
}

export default TopNumbers;
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import React, { useState } from "react";
import { PlusSquare, Save, Trash } from 'react-feather';
import SelectCountry from '../common/SelectCountry';
import SelectCurrency from '../common/SelectCurrency';
import SelectUsers from '../users/SelectUsers';


function EditContact(props) {
    const [contact, setContact] = useState(props.contact)

    const onContactFieldChange = (propertyName) => (event) => {
        if(propertyName === "tax") {
            if(!isNumeric(event.target.value) && event.target.value !== "") {
                return
            }
            setContact({
                ...contact,
                [propertyName]: parseFloat(event.target.value),
                strTax : event.target.value,
              })
        } else {
            setContact({...contact,  [propertyName]: event.target.value})
        }
    }
    const onContactDetailsFieldChange = (propertyName, i) => (event) => {
        let contactDetails = [...contact.contactDetails]
        contactDetails[i] = {...contact.contactDetails[i], [propertyName]: event.target.value}
        setContact({...contact,  contactDetails: contactDetails })
    }
    const onBankFieldChange = (propertyName) => (event) => {
        setContact({...contact,  bankData: [{...contact.bankData[0], [propertyName]: event.target.value}]})
    }
    function onUserChange(userID) {
        setContact({...contact,  user: { ID: props.org + "|user", userID: userID }})
    }

    const setCurrency = (currency) => { setContact({...contact,  currency, bankData: [{...contact.bankData[0], currency}] }) };
    const setCountry = (country) => { setContact({...contact,  country }) };

    const onAddContactDetails = () => {
        setContact({...contact,  contactDetails: [...contact.contactDetails, {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            mobile: "",
        }]})
    };

    const deleteContactDetails = (index) => {
        let contactDetails = [...contact.contactDetails]
        contactDetails.splice(index, 1);
        setContact({...contact,  contactDetails: contactDetails })
    }

    function submitEditContact() {
        props.onSubmit(contact)
        setContact({
            ...contact,
            version: contact.version + 1,
        })
    }
    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    return (
    <Form.Group>
    <Row className="mt-5 d-flex align-items-center">
        <Col md="4">
            Contact Name (Company Name)
        </Col>
        <Col md="5">
            <Form.Control size="sm" type="text" placeholder="Company name" onChange={onContactFieldChange('name')} value={contact.name}/>
        </Col>
    </Row>
    {[...Array(contact.contactDetails.length)].map((_, i) =>
        <div key={i}>
        <Row className="mt-5">
            <Col md="4">
                {i === 0 ? "Primary" : "#"+(i+1) } Contact First name, Last name
            </Col>
            <Col md="2">
                <Form.Control size="sm" type="text" placeholder="First name"  onChange={onContactDetailsFieldChange('firstName', i)} value={contact.contactDetails[i].firstName}/>
            </Col>
            <Col md="3">
                <Form.Control size="sm" type="text" placeholder="Last name" onChange={onContactDetailsFieldChange('lastName', i)} value={contact.contactDetails[i].lastName}/>
            </Col>
        </Row>
        <Row className="mt-3">
        <Col md="4">
                {i === 0 ? "Primary" : "#"+(i+1) } Contact Email
            </Col>
            <Col md="5">
                <Form.Control size="sm" type="text" placeholder="email@address" onChange={onContactDetailsFieldChange('email', i)} value={contact.contactDetails[i].email}/>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col md="4">
                {i === 0 ? "Primary" : "#"+(i+1) } Contact Phone / Mobile
            </Col>
            <Col md="2">
                <Form.Control size="sm" type="text" placeholder="Phone"  onChange={onContactDetailsFieldChange('phone', i)} value={contact.contactDetails[i].phone}/>
            </Col>
            <Col md={props.role === "admin" && i !== 0 ? 2 : 3}>
                <Form.Control size="sm" type="text" placeholder="Mobile" onChange={onContactDetailsFieldChange('mobile', i)} value={contact.contactDetails[i].mobile}/>
            </Col>
            {props.role === "admin" && i !== 0 ?
            <Col md="1">
                <Button variant="outline-secondary" style={{height: 32, paddingTop: "1px"}} onClick={(event) => deleteContactDetails(i)}>
                    <span><Trash size={14} /></span>
                </Button>
            </Col>
            : null }
        </Row>
      </div>
    )}
    {props.role === "admin" ?
        <Row className="mt-3">
            <Col md="6"></Col>
            <Col md="3">
                <Button variant="outline-secondary" onClick={onAddContactDetails}>
                    <span><PlusSquare size={18} /> Add Contact</span>
                </Button>
            </Col>
        </Row>
    : null}
    <Row className="mt-5">
        <Col md="4">
            Company Address
        </Col>
        <Col md="5">
                <Form.Control size="sm" type="text" placeholder="street line 1" onChange={onContactFieldChange('street1')} value={contact.street1}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
        </Col>
        <Col md="5">
                <Form.Control size="sm" type="text" placeholder="street line 2" onChange={onContactFieldChange('street2')} value={contact.street2}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
        </Col>
        <Col md="2">
                <Form.Control size="sm" type="text" placeholder="zipcode" onChange={onContactFieldChange('zipcode')} value={contact.zipcode}/>
        </Col>
        <Col md="3">
                <Form.Control size="sm" type="text" placeholder="state" onChange={onContactFieldChange('state')} value={contact.state}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
        </Col>
        <Col md="5">
                <Form.Control size="sm" type="text" placeholder="city" onChange={onContactFieldChange('city')} value={contact.city}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Country
        </Col>
        <Col md="5">
            <SelectCountry onChange={setCountry} default={contact.country} />
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Currency
        </Col>
        <Col md="5">
            <SelectCurrency onChange={setCurrency} default={contact.currency} />
        </Col>
    </Row>
    <Row className="mt-5">
        <Col md="4">
            Tax / VAT
        </Col>
        <Col md="2">            
            <InputGroup className="mb-3" size="sm">
               <Form.Control size="sm" type="text" placeholder="tax / vat" onChange={onContactFieldChange('tax')} value={contact.strTax}/>
               <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
        </Col>
        <Col md="3">            
            <Form.Control size="sm" type="text" placeholder="Tax / VAT identification number" onChange={onContactFieldChange('taxID')} value={contact.taxID}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Invoice Note
        </Col>
        <Col md="5">
            <Form.Control as="textarea" rows={2} size="sm" type="text" placeholder="Note to put on every invoice of this contact" onChange={onContactFieldChange('invoiceNote')} value={contact.invoiceNote}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Account Number
        </Col>
        <Col md="5">
            <Form.Control size="sm" type="text" placeholder="Account Number" onChange={onBankFieldChange('accountNumber')} value={contact.bankData[0].accountNumber}/>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col md="4">
            Account Holder Name
        </Col>
        <Col md="5">
            <Form.Control size="sm" type="text" placeholder="Account Holder Name" onChange={onBankFieldChange('holderName')} value={contact.bankData[0].holderName}/>
        </Col>
    </Row>
    <Row className="mt-5">
        <Col md="4">
            Linked User
        </Col>
        <Col md="5">
            <SelectUsers org={props.org} type="selectbox" handleUserChange={onUserChange} selected={contact.user.userID} showDefaultValue={true} />
        </Col>
    </Row>
    {props.role === "admin" ?
        <Row className="mt-3">
            <Col md="2">
                <Button variant="primary" disabled={props.submitting} onClick={submitEditContact}>
                    <span>Save <Save size={18} /></span>
                </Button>
            </Col>
        </Row>
    : null}



    </Form.Group>
    )
}
export default EditContact
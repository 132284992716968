import React from "react";


import {useNavigate} from 'react-router-dom';

import { DownloadCloud } from "react-feather"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

function SelectImport(props) {
    const navigate = useNavigate();
    return (
        <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Select Import</h1>
            </div>

            <Card style={{ width: '18rem', display: 'inline-block', height: '15rem' }}>
                <Card.Body>
                    <Card.Title>Time</Card.Title>
                    <Card.Text>
                        Import time from a .CSV file. Headers are: "clientName","projectName","taskName","user","entryDate","description","duration"
                    </Card.Text>
                    <Button variant="primary" onClick={() => navigate("/import/time")}><DownloadCloud size={16} /> Import Time</Button>
                </Card.Body>
            </Card>
        </div>
        
    )
}

export default SelectImport;
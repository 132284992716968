import Spinner from "react-bootstrap/Spinner"
import React, { useState } from "react"
import { Bar } from 'react-chartjs-2';
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

function BillablesCosts(props) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: props.preferredCurrency,
    });
    const [dashboardData, setDashboardData] = useState({hasData: props.dashboardData.hasData})
    const [dashboardType, setDashboardType] = useState("revenue")

    const dashboardOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Billables & Costs of contractors'
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) { 
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += formatter.format(context.parsed.y);
                        }
                        return label;
                    }
                }
            }, 
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        return formatter.format(value)
                    }
                },
            },
        },             
    }
    const dashboardNonBillableOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Contractor Cost of non-billable hours'
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) { 
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += formatter.format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
        },
        responsive: true,
        scales: {
            y: {
                stacked: true,
                ticks: {
                    callback: function (value) {
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        return formatter.format(value)
                    }
                },
                },
            x: {
                stacked: true,
                },
            },                
    }
    const dashboardProfitOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Profit'
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) { 
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += formatter.format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        if(props.organizationAttributes.configuration.privacyMode) return "***"
                        return formatter.format(value)
                    }
                },
                },
            x: {
                },
            },                
    }

    React.useEffect(() => {
        // dashboard constants
        const backgroundColors = [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 202, 255, 0.2)',
        ]
        const borderColors = [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 122, 171, 1)',
        ]
        if(!props.dashboardData.hasData) {
            setDashboardData(props.dashboardData)
            return
        }
        let dashboardDataNonBillables = props.dashboardData.nonBillables
        dashboardDataNonBillables.datasets.forEach((_, i) => {
            if(i < backgroundColors.length) {
                dashboardDataNonBillables.datasets[i].backgroundColor = [backgroundColors[i]]
                dashboardDataNonBillables.datasets[i].borderColor = [borderColors[i]]
                dashboardDataNonBillables.datasets[i].borderWidth = 1
            }
        })
        let dashboardDataBillables = props.dashboardData.billables
        // set colors
        dashboardDataBillables.datasets.forEach((_, i) => {
            if(i < backgroundColors.length) {
                dashboardDataBillables.datasets[i].backgroundColor = [backgroundColors[i]]
                dashboardDataBillables.datasets[i].borderColor = [borderColors[i]]
                dashboardDataBillables.datasets[i].borderWidth = 1
                dashboardDataBillables.datasets[i].stack = "bar-stacked"
            }
            if(dashboardDataBillables.datasets.length-1 === i) { // last element is a line
                dashboardDataBillables.datasets[i].type = "line"
                dashboardDataBillables.datasets[i].borderWidth = 2
                dashboardDataBillables.datasets[i].borderColor = "rgb(211,211,211)"
                dashboardDataBillables.datasets[i].backgroundColor = "rgb(211,211,211)"
                dashboardDataBillables.datasets[i].tension = 0.1
                dashboardDataBillables.datasets[i].fill = false
                dashboardDataBillables.datasets[i].stack = ""
            }
        })

        if(props.dashboardData.yearly) {
            if(props.dashboardData.billablesPrevPeriod.datasets.length > 0) {
                dashboardDataBillables.datasets.push({
                    data: props.dashboardData.billablesPrevPeriod.datasets[0].data,
                    type: "line",
                    label: props.dashboardData.billablesPrevPeriod.datasets[0].label,
                    borderWidth: 2,
                    fill: false,
                    tension: 0.1,
                    borderColor: "#86db94",
                    backgroundColor: "#86db94",
                })
            }
        }

        let dashboardDataProfit = props.dashboardData.profit
        dashboardDataProfit.datasets[0].type = "line"
        dashboardDataProfit.datasets[0].borderWidth = 2
        dashboardDataProfit.datasets[0].fill = false
        dashboardDataProfit.datasets[0].tension = 0.1
        dashboardDataProfit.datasets[0].borderColor = "#004c6d"
        dashboardDataProfit.datasets[0].backgroundColor = "#004c6d"
        // average
        dashboardDataProfit.datasets.push({
            data: Array(31).fill(props.dashboardData.profitAverage),
            type: "line",
            label: "Average",
            borderWidth: 2,
            fill: false,
            tension: 0.1,
            borderColor: "#b8c1e5",
            backgroundColor: "#b8c1e5",
        })
        // previous period
        if(props.dashboardData.yearly) {
            if(props.dashboardData.profitPrevPeriod.datasets.length > 0) {
                dashboardDataProfit.datasets.push({
                    data: props.dashboardData.profitPrevPeriod.datasets[0].data,
                    type: "line",
                    label: "Previous Period",
                    borderWidth: 2,
                    fill: false,
                    tension: 0.1,
                    borderColor: "#86db94",
                    backgroundColor: "#86db94",
                })
            }
        } else {
            // average of previous period
            dashboardDataProfit.datasets.push({
                data: Array(31).fill(props.dashboardData.profitAveragePrevPeriod),
                type: "line",
                label: "Average Previous Period",
                borderWidth: 2,
                fill: false,
                tension: 0.1,
                borderColor: "#86db94",
                backgroundColor: "#86db94",
            })
        }

        // cumulative
        let dashboardDataCumulative = props.dashboardData.cumulative
        dashboardDataCumulative.datasets[0].type = "line"
        dashboardDataCumulative.datasets[0].borderWidth = 2
        dashboardDataCumulative.datasets[0].fill = false
        dashboardDataCumulative.datasets[0].tension = 0.1
        dashboardDataCumulative.datasets[0].borderColor = "#004c6d"
        dashboardDataCumulative.datasets[0].backgroundColor = "#004c6d"
        dashboardDataCumulative.datasets[1].backgroundColor = backgroundColors[1]
        
        if(props.dashboardData.cumulativePrevPeriod.length > 0) {
            dashboardDataCumulative.datasets[2] = {
                data: props.dashboardData.cumulativePrevPeriod[0].data,
                type: "line",
                label: "Profit Previous Period",
                borderWidth: 2,
                fill: false,
                tension: 0.1,
                borderColor: borderColors[3],
                backgroundColor: borderColors[3],
            }
            dashboardDataCumulative.datasets[3] = {
                data: props.dashboardData.cumulativePrevPeriod[1].data,
                type: "bar",
                label: "Revenue Previous Period",
                backgroundColor: backgroundColors[3],
            }
        }

        setDashboardData({
            billables: dashboardDataBillables,
            nonBillables: dashboardDataNonBillables,
            profit: dashboardDataProfit,
            cumulative: dashboardDataCumulative,
            hasData: true,
        })
    },[props.dashboardData,props.organizationAttributes.configuration.privacyMode])

    function switchDashboardType(newDashBoardType) {
        setDashboardType(newDashBoardType)
    }

    if(!dashboardData.hasData) {
        return (
            <Spinner animation="border" />
        )
    }
    return (
        <div className="chart-billables">
            <Row>
                <div className="chart-billables-buttongroup">
                    <ButtonGroup size="sm">
                        <Button active={dashboardType === "revenue" ? true : false} variant="secondary" onClick={() => switchDashboardType("revenue")}>Revenue</Button>
                        <Button active={dashboardType === "profit" ? true : false} variant="secondary" onClick={() => switchDashboardType("profit")}>Profit</Button>
                        <Button active={dashboardType === "cumulative" ? true : false} variant="secondary" onClick={() => switchDashboardType("cumulative")}>Cumulative</Button>
                        <Button active={dashboardType === "non-billables" ? true : false} variant="secondary" onClick={() => switchDashboardType("non-billables")}>Non-Billables</Button>
                    </ButtonGroup>
                </div>
            </Row>
            <Row className="mt-2">
            {dashboardType === "revenue" ?
                <Bar id="billables" data={dashboardData.billables} options={dashboardOptions} />
                : null
            }
            {dashboardType === "profit" ?
                <Bar id="billables" data={dashboardData.profit} options={dashboardProfitOptions} />
                : null
            }
            {dashboardType === "cumulative" ?
                <Bar id="billables" data={dashboardData.cumulative} options={dashboardProfitOptions} />
                : null
            }
            {dashboardType === "non-billables" ?
                <Bar id="non-billables" data={dashboardData.nonBillables} options={dashboardNonBillableOptions} />
                : null
            }
            </Row>
        </div>
        
    )
}
export default BillablesCosts;
import Spinner from "react-bootstrap/Spinner"
import React, { useState } from "react"
import { Bar } from 'react-chartjs-2';
import { API } from "aws-amplify";
import Alert from "react-bootstrap/Alert"

function Budgets(props) {
    const [errorMessage, setErrorMessage] = useState("")
    const [dashboardData, setDashboardData] = useState({
        labels: [],
        datasets: [],
    })


    const dashboardOptions = {
        indexAxis: 'y', 
        plugins: {
            title: {
                display: true,
                text: props.responseType === "hoursremaining" ? "Hours Remaining" : 'Budgets'
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) { 
                        if(props.organizationAttributes.configuration.privacyMode) return "**.**%"
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (props.responseType === "hoursremaining") {
                            return label + parseFloat(context.parsed.x).toFixed(2);
                        }
                        if (context.parsed.y !== null) {
                            label += (parseFloat(context.parsed.x) * 100).toString() + "%";
                        }
                        return label;
                    }
                }
            },
        },
        scales: {
            x: {

                suggestedMax: 1.2,
            },
        },
        responsive: true,               
    }

    
    const chartPlugins = [
        {
            getLinePosition: function(chart, pointIndex) {
                const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
                const data = meta.data;
                return data[pointIndex].x;
            },
            renderVerticalLine: function(chartInstance, pointIndex) {
                const scale = chartInstance.scales.y;
                const { ctx } = chartInstance;

                let drawThroughPixel = 0

                for (let k = 0; k < chartInstance.scales.x.ticks.length; k++) {
                    if(chartInstance.scales.x.ticks[k].value === 1) { // draw line through "1.0"
                        drawThroughPixel = chartInstance.scales.x.getPixelForTick(k)
                    }
                }

                if(drawThroughPixel > 0) {
                    // draw vertical line
                    ctx.save();
                    ctx.beginPath();
                    ctx.strokeStyle = '#ff0000';
                    ctx.moveTo(drawThroughPixel, scale.top);
                    ctx.lineTo(drawThroughPixel, scale.bottom);
                    ctx.stroke();
                }
            },
          
            afterDatasetDraw: function(chart) {
                this.renderVerticalLine(chart, 0);
            }
        }
    ];
      

    React.useEffect(() => {
        // dashboard constants
        const backgroundColors = [
            "#003f5c",
            "#516795",
            "#806ba6",
            "#b36ca8",
            "#de6d9a",
            "#fd787e",
            "#ff905c",
            "#ffb13e",
        ]
        const borderColors = [
            "#305d7a",
            "#2f4b7c",
            "#665191",
            "#a05195",
            "#d45087",
            "#f95d6a",
            "#ff7c43",
            "#ffa600",
        ]

        setDashboardData({
            labels: [],
            datasets: [],
        })

        // API call
        API
        .get("billing", "/billing?action=getProjectBudgets&org="+props.org+"&year="+props.inputDate.year+"&month="+props.inputDate.month+"&type="+props.responseType, {})
        .then(response => {
            if(props.organizationAttributes.configuration.privacyMode) {
                response.labels.forEach((_, index) => {
                    response.labels[index] = "***"
                })
            }
            // update state
            setDashboardData({
                labels: response.labels,
                datasets: [
                    {
                        label: response.dataSet.label,
                        data: response.dataSet.data,
                        backgroundColor: backgroundColors,
                        borderColor: borderColors,
                        borderWidth: 1,
                        maxBarThickness: 20,
                    }
                ],
            })
        })
        .catch(error => {
            setErrorMessage(JSON.stringify(error));
        });
    }, [props.org, props.inputDate, props.organizationAttributes.configuration.privacyMode, props.responseType])


    if(dashboardData.datasets.length === 0) {
        if(errorMessage !== "") {
            return (<Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                    {errorMessage}
                    </Alert>)
        } else {
            return (
                <Spinner animation="border" />
            )
        }
    }
    return (
        <div className="chart-budgets">
            {errorMessage !== "" ?
                <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
                {errorMessage}
            </Alert>
            : ""
            }
            <Bar id="budgets" data={dashboardData} options={dashboardOptions} plugins={chartPlugins} width="1000" height="800"/>
        </div>
        
    )
}
export default Budgets;
import React, { useState } from "react";
import { API } from "aws-amplify";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

function SelectContract(props) {
    const [loading, setLoading] = useState(true);
    const [contracts, setContracts] = useState([]);
    const [selected, setSelected] = useState(0);
    React.useEffect(() => {
        let url = "/billing?action=listUserContracts&org="+props.org
        if(props.role === "admin") {
          url = "/billing?action=listContracts&org="+props.org
        }
        API
        .get("billing", url, {})
        .then(contracts => {
            setLoading(false)
            setContracts(contracts)
        })
        .catch(error => {
            console.log(error);
        });
    }, [props.org, props.role]);

    function addContracts(contracts) {
        let items = [];
    
        if(contracts.length > 0) {
          contracts.forEach((contract, i) => {
            items.push(<option key={i} value={i}>{contract.description}</option>);
          })
        } else {
            items.push(<option key="0" value="0">No Contract Found</option>);
        }
        return items
    }

    if(!loading) {
        return (
            <Row>
                <Col md="6">
                    <Form.Group controlId="formContractID">
                        <Form.Select onChange={e => setSelected(e.target.value)}>
                            {addContracts(contracts)}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md="1">
                    <Button variant="primary" type="submit" onClick={() => { props.onSubmit(contracts[selected]) }} disabled={loading || contracts.length === 0} >
                        Submit
                    </Button>
                </Col>
            </Row>
        )
    } else {
        return (
            <Spinner animation="border"/>
        )
    }  
}
export default SelectContract;
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Link
} from "react-router-dom";

import React, {useState} from 'react';

import { Amplify, Auth } from 'aws-amplify';

import ListContracts from './contracts/ListContracts';
import GetInvoice from './invoices/GetInvoice';
import NewInvoice from './invoices/NewInvoice';
import Dashboard from './dashboards/Dashboard';
import Landing from './Landing';
import Ponto from './integrations/Ponto';
import Quickbooks from './integrations/Quickbooks';
import Payments from './payments/Payments'
import ErrorBoundary from './ErrorBoundary'
import Organizations from "./organizations/Organizations";
import Timesheet from './timesheets/Timesheet'
import NewContact from "./contacts/NewContact";
import ListContacts from "./contacts/ListContacts";
import GetContact from "./contacts/GetContact";
import NewProject from "./projects/NewProject";
import ListProjects from "./projects/ListProjects";
import GetProject from "./projects/GetProject";
import NewClientContract from "./contracts/NewClientContract";
import GetClientContract from "./contracts/GetClientContract";
import Navigation from "./Navigation";
import GetContractorContract from "./contracts/GetContractorContract";
import OrganizationSettings from "./organizations/Settings"
import AuditLog from "./organizations/AuditLog"

import { useCookies } from 'react-cookie';

import GetUserAttributes from './UserAttributes'
import GetOrganizationAttributes from './OrganizationAttributes'

// amplify configuration
import awsExports from "./constants/aws-exports";
import ListUsers from "./users/ListUsers";
import GetUser from "./users/GetUser";
import NewUser from "./users/NewUser";
import Onboarding from "./onboarding/Onboarding";
import NewContract from "./contracts/NewContract";
import Reports from "./timesheets/Reports";
import ListSubmitted from "./timesheets/ListSubmitted";
import ListInvoices from "./invoices/ListInvoices"
import Integrations from "./integrations/Integrations";
import Planning from "./planning/Planning";
import NewEmptyInvoice from "./invoices/NewEmpyInvoice";
import Xero from "./integrations/Xero";
import SelectImport from "./import/Select";
import Time from "./import/Time";

Amplify.configure(awsExports);

const App = () => {
  const [userAttributes, setUserAttributes] = useState({user: {}, role: "", org: "", userID: ""});
  const [cookies] = useCookies(['activeOrg']);
  const [organizationAttributes, setOrganizationAttributes] = useState({preferredCurrency: "", name: "", configuration: {}});

  React.useEffect(() => {
      GetUserAttributes(cookies.activeOrg ? cookies.activeOrg : "__default").then(res => {
        if(res === null) {
          Auth.federatedSignIn()
        } else {
          setUserAttributes({
            user: res[0],
            role: res[1],
            org: res[2],
            userID: res[3],
          })
          GetOrganizationAttributes(res[2]).then(organization => {
            if(organization !== null) {
              const newOrganizationAttributes = {
                preferredCurrency: organization.preferredCurrency,
                name: organization.name,
                configuration: organization.configuration,
              }
              setOrganizationAttributes(newOrganizationAttributes)
            }
          })
        }
      })
  }, [cookies.activeOrg]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  

  function GetRoutes() {
    let query = useQuery();
    return (
      <Routes>
        <Route exact path="/" element={<Landing org={userAttributes.org} role={userAttributes.role} />} />
        <Route path="/setup" element={<Onboarding org={userAttributes.org} role={userAttributes.role} />} />
        <Route exact path="/dashboard" element={<Dashboard org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency} organizationAttributes={organizationAttributes} />} />
        <Route path="/newClientContract" element={<NewClientContract org={userAttributes.org} role={userAttributes.role} myroute="/contracts" preferredCurrency={organizationAttributes.preferredCurrency} />} />
        <Route path="/getClientContract" element={<GetClientContract org={userAttributes.org} role={userAttributes.role} id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} preferredCurrency={organizationAttributes.preferredCurrency} />} />
        <Route path="/contracts" element={<ListContracts org={userAttributes.org} role={userAttributes.role} showSaved={query.has("showSaved") ? query.get("showSaved") : false} preferredCurrency={organizationAttributes.preferredCurrency} />} />
        <Route path="/newContract" element={<NewContract org={userAttributes.org} role={userAttributes.role} myroute="/contracts" />} />
        <Route path="/getContract" element={<GetContractorContract org={userAttributes.org} role={userAttributes.role} id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} />} />
        <Route exact path="/contractor/invoice" element={<GetInvoice id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} org={userAttributes.org} role={userAttributes.role} myroute="/contractor/invoices" preferredCurrency={organizationAttributes.preferredCurrency} type="bill" />} />
        <Route path="/contractor/invoices" element={<ListInvoices org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency}  myroute="/contractor/invoice" type="bill" />} />
        <Route path="/contractor/newInvoice" element={<NewInvoice org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency} />} />
        <Route exact path="/bill" element={<GetInvoice id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} org={userAttributes.org} role={userAttributes.role} myroute="/bills" preferredCurrency={organizationAttributes.preferredCurrency} type="bill" />} />
        <Route path="/bills" element={<ListInvoices org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency} myroute="/bill" type="bill" />} />
        <Route path="/newBill" element={<NewInvoice org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency} />} />
        <Route path="/newInvoice" element={<NewEmptyInvoice org={userAttributes.org} role={userAttributes.role} organizationAttributes={organizationAttributes} />} />
        <Route exact path="/invoice" element={ <GetInvoice id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} org={userAttributes.org} role={userAttributes.role} myroute="/invoices" preferredCurrency={organizationAttributes.preferredCurrency} type="invoice" />} />
        <Route path="/invoices" element={<ListInvoices org={userAttributes.org} role={userAttributes.role} preferredCurrency={organizationAttributes.preferredCurrency} myroute="/invoice" type="invoice" />} />
        <Route path="/ponto/auth" element={<Ponto auth={true} code={query.has("code") ? query.get("code") : ""} state={query.has("state") ? query.get("state") : ""} org={userAttributes.org} role={userAttributes.role} />} />
        <Route path="/ponto" element={ <Ponto auth={false} code={query.has("code") ? query.get("code") : ""} state={query.has("state") ? query.get("state") : ""} org={userAttributes.org} role={userAttributes.role} />} />
        <Route exact path="/integrations" element={<Integrations org={userAttributes.org} role={userAttributes.role} />} />
        <Route path="/integrations/ponto" element={<Ponto auth={false} org={userAttributes.org} role={userAttributes.role} state="" code="" />} />
        <Route path="/integrations/quickbooks" element={<Quickbooks org={userAttributes.org} role={userAttributes.role} callbackCode={query.has("code") ? query.get("code") : ""} callbackState={query.has("state") ? query.get("state") : ""} realmID={query.has("realmId") ? query.get("realmId") : ""} />} />
        <Route path="/integrations/xero" element={<Xero org={userAttributes.org} role={userAttributes.role} />} />
          
        
        <Route path="/payments" element={<Payments invoiceID={query.has("invoiceID") ? query.get("invoiceID") : ""} invoiceIssueDate={query.has("invoiceIssueDate") ? query.get("invoiceIssueDate") : ""} org={userAttributes.org} role={userAttributes.role} />} />
          
        
        <Route path="/organizations" element={<Organizations org={userAttributes.org} role={userAttributes.role} />} />
          
        
        <Route path="/organizationSettings" element={<OrganizationSettings org={userAttributes.org} role={userAttributes.role} organizationAttributes={organizationAttributes} />} />
          
        
        <Route exact path="/timesheet/:year/:month/:day/:userID" element={<Timesheet org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} organizationAttributes={organizationAttributes} />} />
          
        
        <Route exact path="/timesheet/:year/:month/:day" element={<Timesheet org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} organizationAttributes={organizationAttributes} />} />
          
        
        <Route path="/timesheet" element={<Timesheet org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} organizationAttributes={organizationAttributes} />} />
          
        
        <Route path="/submittedTimesheets" element={<ListSubmitted org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} organizationAttributes={organizationAttributes} />} />
          
        
        <Route path="/newContact" element={<NewContact org={userAttributes.org} role={userAttributes.role} myroute="/contacts" preferredCurrency={organizationAttributes.preferredCurrency} />} />
          
        
        <Route path="/contacts" element={<ListContacts org={userAttributes.org} role={userAttributes.role} myroute="/contacts" showSaved={query.has("showSaved") ? query.get("showSaved") : false} />} />
          
        
        <Route path="/getContact" element={<GetContact org={userAttributes.org} role={userAttributes.role} id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} />} />
          
        
        <Route path="/newProject" element={<NewProject org={userAttributes.org} role={userAttributes.role} myroute="/projects" />} />
          
        
        <Route path="/projects" element={<ListProjects org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} myroute="/projects" showSaved={query.has("showSaved") ? query.get("showSaved") : false} />} />
          
        
        <Route path="/getProject" element={ <GetProject org={userAttributes.org} role={userAttributes.role} id={query.has("id") ? query.get("id") : ""} issueDate={query.has("issueDate") ? query.get("issueDate") : ""} />} />
         
        
        <Route path="/users" element={<ListUsers org={userAttributes.org} role={userAttributes.role} myroute="/users" showSaved={query.has("showSaved") ? query.get("showSaved") : false} />} />
          
        
        <Route path="/getUser" element={<GetUser org={userAttributes.org} role={userAttributes.role} id={query.has("id") ? query.get("id") : ""} userID={query.has("userID") ? query.get("userID") : ""} />} />
          
        
        <Route path="/newUser" element={<NewUser org={userAttributes.org} role={userAttributes.role} myroute="/users" />} />
          
        
        <Route path="/timesheetReports" element={<Reports org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} />} />
        
        <Route path="/auditLog" element={<AuditLog org={userAttributes.org} role={userAttributes.role} />} />
          
        
        <Route exact path="/planning/:year/:month/:day/:userID" element={<Planning org={userAttributes.org} role={userAttributes.role} />} />
         
        
        <Route exact path="/planning/:year/:month/:day/" element={<Planning org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} />} />
          
        
        <Route exact path="/planning" element={<Planning org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} />} />
        
        <Route exact path="/import" element={<SelectImport org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} />} />
        <Route exact path="/import/time" element={<Time org={userAttributes.org} role={userAttributes.role} userID={userAttributes.userID} />} />

        
      </Routes>
    )
  }

  return userAttributes.user.hasOwnProperty("username") && userAttributes.user.username !== "" && organizationAttributes.name !== "" ? (
    <BrowserRouter>
    <div className="App">
        <div className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
          <Link to="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3">{organizationAttributes.name} Billing</Link>
          <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-nav">
            <div className="nav-item text-nowrap">
            
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <Navigation role={userAttributes.role} organizationAttributes={organizationAttributes} />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <ErrorBoundary>
                <GetRoutes />
              </ErrorBoundary>
            </main>
         </div>
        </div>
    </div>
    </BrowserRouter>
  ) : null
}

export default App;

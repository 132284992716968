
import React, { useState } from "react";
import { API } from '@aws-amplify/api'
import Alert from 'react-bootstrap/Alert'

import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/esm/Spinner";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

function SelectTasks(props) {
    const [errorMessage, setErrorMessage] = useState('');
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newTask, setNewTask] = useState({
        name: "",
        billable: true,
    })
    const [addingNewTask, setAddingNewTask] = useState(false)

    React.useEffect(() => {
        API
        .get("billing", "/billing?action=listTasks&org="+props.org, {})
        .then(tasks => {
            setTasks(tasks)
            setLoading(false)
        })
        .catch(error => {
            setErrorMessage(error.message)
            setLoading(false)
        });
    }, [props.role, props.org])

    function onAddTask() {
        setAddingNewTask(true)
        const params = {
            body: {
                action: "newTask",
                task: newTask,
                org: props.org,
            },
        };
        // do post
        API
        .post("billing", "/billing", params)
        .then(task => {
            tasks.push(task)
            setNewTask({
                name: "",
                billable: true,
            })
            setAddingNewTask(false)
        })
        .catch(error => {
            setErrorMessage(error.message);
            setAddingNewTask(false)
        });
    }

    if(loading) {
        return (
            <Spinner animation="border"/>
        )
    }

    return (
        <div>
        <Table striped bordered hover>
        <thead>
            <tr key="header">
                <th></th>
                <th>Task Name</th>
                <th>Billable</th>
                {props.budgetPerTask ? 
                    <th>Budget</th>
                :
                    null
                }
                {props.ratePerTask ? 
                    <th>Rate</th>
                :
                    null
                }
            </tr>
        </thead>
        <tbody>
            {tasks.map((task, _) => (
                <tr key={task.id + "|" + task.issueDate}>
                    <td><Form.Check type="checkbox" label="" name={task.id + "|" + task.issueDate} checked={props.checkedTasks.has(task.id + "|" + task.issueDate) ? props.checkedTasks.get(task.id + "|" + task.issueDate) : false} onChange={(e) => props.handleTaskChange(e.target)} /></td>
                    <td>{task.name}</td>
                    <td><Form.Check type="checkbox" label="" checked={task.billable} disabled={true} /></td>
                    {props.budgetPerTask && task.billable ?
                        <td>
                            <InputGroup className="mb-3" size="sm">
                                <Form.Control size="sm" type="text" placeholder="0.00" name={task.id + "|" + task.issueDate} onChange={(e) => props.handleTaskBudgetChange('hours', e.target)} value={props.tasksBudget.has(task.id + "|" + task.issueDate) ? props.tasksBudget.get(task.id + "|" + task.issueDate).valueStr : ""} />
                                <InputGroup.Text>hours</InputGroup.Text>
                                <Form.Select className="ms-2 form-select form-select-sm" onChange={(e) => props.handleTaskBudgetChange('frequency', e.target)} value={props.tasksBudget.has(task.id + "|" + task.issueDate) ? props.tasksBudget.get(task.id + "|" + task.issueDate).frequency : ""} >
                                    <option value="monthly">Monthly</option>
                                    <option value="fixed">Fixed</option>
                                </Form.Select>
                            </InputGroup>
                        </td>
                    :
                        null
                    }
                    {props.budgetPerTask && !task.billable ? <td></td> : null }
                    {props.ratePerTask && task.billable ?
                        <td>
                            <InputGroup className="mb-3" size="sm">
                                <InputGroup.Text>{props.currency}</InputGroup.Text>
                                <Form.Control size="sm" type="text" placeholder="0.00" name={task.id + "|" + task.issueDate} onChange={(e) => props.handleTaskRateChange(e.target)} value={props.tasksRate.has(task.id + "|" + task.issueDate) ? props.tasksRate.get(task.id + "|" + task.issueDate).valueStr : ""}/>
                            </InputGroup>
                        </td>
                    :
                        null
                    }
                    {props.ratePerTask && !task.billable ? <td></td> : null }
                </tr>
            ))
            }
            <tr key="addTask">
                <td></td>
                <td>
                    <InputGroup className="mb-3">
                        <Form.Control placeholder="New task" size="sm" onChange={(e) => setNewTask({...newTask, name: e.target.value})} value={newTask.name} /><Button size="sm" disabled={addingNewTask} onClick={() => onAddTask()}>Add</Button>
                    </InputGroup>
                </td>
                <td>
                    <Form.Check type="checkbox" label="" checked={newTask.billable} onChange={(e) => setNewTask({...newTask, billable: e.target.checked })} />
                </td>
                {props.budgetPerTask ? <td></td> : null }
            </tr>
        </tbody>
        </Table>
        {errorMessage !== "" ?
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
            {errorMessage}
        </Alert>
        : ""
        }
        </div>
    )
}

export default SelectTasks;
import { ChevronLeft, ChevronRight } from 'react-feather';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

function PlanningHeader(props) {
    const fullDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    const months = ["January","February","March","April","May","June","July", "August","September","October","November","December"];

    function getColSpan(index) {
        let colspan = 1
        for(let i = index+1; i < props.days.length; i++) {
            if(props.days[i-1].getUTCMonth() === props.days[i].getUTCMonth()) {
                colspan++
            } else {
                return colspan-1              
            }
        }
        return colspan+1
    }
    return (
        <thead>
            <tr>
                <td rowSpan={3} colSpan={2}>
                    <ButtonToolbar aria-label="Toolbar with button groups" style={{alignItems: "center", justifyContent: "center"}}>
                        <ButtonGroup aria-label="previous or next timesheet group">
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => props.previousWeek()}><ChevronLeft size={22} /></button>
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => props.nextWeek()}><ChevronRight size={22} /></button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </td>
                
                {props.days.map((day, index) => {
                    if(index === 0 || day.getUTCMonth() !== props.days[index-1].getUTCMonth()) {
                        const colspan = getColSpan(index)
                        return (
                            <td key={day.getUTCFullYear() + "-" + day.getUTCMonth()+1 + "-" + index} colSpan={colspan} className="fs-3">{months[day.getUTCMonth()]}</td>
                        )
                    }
                    return null
                })}
            </tr>
            <tr>
                {props.days.map((day, index) => (
                    <td style={{width: "100px"}} key={day.getUTCFullYear() + "-" + day.getUTCMonth()+1 + "-" + day.getUTCDate() + "-dayname-" + index} className="text-center">{fullDay[day.getUTCDay()]}</td>
                ))}
            </tr>
            <tr>
                {props.days.map((day, index) => (
                    <td style={{width: "100px"}} key={day.getUTCFullYear() + "-" + day.getUTCMonth()+1 + "-" + day.getUTCDate() + "-" + index} className="text-center">{day.getUTCDate()}</td>
                ))}
            </tr>
        </thead>
    )
}

export default PlanningHeader
import { CreditCard, ThumbsUp } from "react-feather";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API } from "aws-amplify";

import React, { useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import Table from 'react-bootstrap/Table'

import {useNavigate} from 'react-router-dom';



function InvoiceActions(props) {
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [validationErrors, setValidationErrors] = useState([])

    const [loading, setLoading] = useState(false);

    const handleCloseConfirmation = () => setShowConfirmation(false);
    const handleShowConfirmation = () => setShowConfirmation(true);

    const handlePaymentsRedirect = () => navigate('/payments');

    React.useEffect(() => {
        let validationErrors = []
        props.invoices.forEach((invoice, i) => {
            validationErrors[i] = ""
            props.items.forEach((isSelected, key, m) => {
                if(invoice.id + "|" + invoice.issueDate === key && isSelected) {
                    if(invoice.status !== "approved") {
                        validationErrors[i] += "Not approved yet. "
                    }
                    if(invoice.paymentData.length === 0) {
                        validationErrors[i] += "No payment data available"
                    } else {
                        if(invoice.paymentData[0].reference === "") {
                            validationErrors[i] += "Payment reference cannot be empty"
                        }
                        if(invoice.paymentData[0].accountNumber === "") {
                            validationErrors[i] += "Account Number cannot be empty"
                        }
                    }
                }
            })
            setValidationErrors(validationErrors)
        })
    }, [props.invoices, props.items])

    function hasValidationErrors() {
        if(validationErrors.length === 0) {
            return true // no input found
        }
        for(let i = 0 ; i < validationErrors.length ; i++) {
            if(validationErrors[i] !== "") {
                return true
            } 
        }
        return false
    }
    
    function approveSelected(e) { 
         e.preventDefault();
         let approvals = []
         props.items.forEach((v, k, m) => {
             if(v) {
                 approvals.push(k)
             }
         })
         // prepare post
         setLoading(true)
         const params = {
             body: {
                 action: "approveInvoices",
                 approvals: approvals,
                 org: props.org,
             },
         };
         // do post
         API
         .post("billing", "/billing", params)
         .then(response => {
            const newInvoices = [ ...props.invoices ]; // Get a copy of the array
            newInvoices.forEach((invoice, i) => {
                const key = invoice.id + "|" + invoice.issueDate
                approvals.forEach((approval, ii) => {
                    if(key === approval) {
                        newInvoices[i].status = "approved"
                    }
                })
            })
            props.setInvoices(newInvoices)
            setLoading(false)
         })
         .catch(error => {
            props.setError(error.message)
         });
     }
     function paySelected() { 
        let documents = []
        props.items.forEach((v, k, m) => {
            if(v) {
                documents.push(k)
            }
        })
        // prepare post
        const params = {
            body: {
                action: "initiatePaymentRequest",
                documents: documents,
                org: props.org,
            },
        };
        setLoading(true)

        // check if we have the integration activated
        API
        .get("billing", "/billing?action=listPontoAccounts&org="+props.org, {})
        .then(response => {
            setLoading(false)
            if(response.pontoAccounts === null) {
                setShowConfirmation(false)
                setLoading(false)
                props.setError("No payment integration activated")
            } else {
                // do post
                API
                .post("billing", "/billing", params)
                .then(response => {
                    handlePaymentsRedirect()
                })
                .catch(error => {
                    props.setError(error.message)
                });
            }
        })
        .catch(error => {
            setLoading(false)
            props.setError(error.message)
        });
    }
    function addConfirmationItems() {
        let items = []
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
        });
        props.invoices.forEach((invoice, i) => {
            props.items.forEach((isSelected, key, m) => {
                if(invoice.id + "|" + invoice.issueDate === key && isSelected) {
                    items.push(
                        <tr key={key}>
                            <td>{invoice.company.name}</td>
                            <td>{invoice.paymentData === null || invoice.paymentData.length === 0 ? "" : invoice.paymentData[0].accountNumber}</td>
                            <td>{invoice.paymentData === null || invoice.paymentData.length === 0 ? "" : invoice.paymentData[0].paymentReference}</td>
                            <td>{formatter.format(invoice.invoiceTotal)}</td>
                            {validationErrors[i] === "" ?
                                <td>No Errors</td>
                            :
                                <td className="validationError">{validationErrors[i]}</td>
                            }
                        </tr>
                    )
                }
            })
        })

        return items
    }
    if(loading) {
        return (
            <Spinner animation="border"/>
        );
    } else {
        return (
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
            <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup aria-label="Approve" className="me-2">
                <Button type="submit" variant="outline-secondary" onClick={(e) => approveSelected(e)}><ThumbsUp size={16} /> Approve selected</Button>
                </ButtonGroup>
                <ButtonGroup aria-label="Pay" className="me-2">
                <Button variant="outline-secondary" onClick={handleShowConfirmation}><CreditCard size={16} /> Pay selected</Button>
                </ButtonGroup>
            </ButtonToolbar>
            <Modal
                    show={showConfirmation}
                    onHide={handleCloseConfirmation}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Pay selected bills</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                    The following payments will be executed.
                    <Table striped bordered hover>
                        <thead>
                            <tr key="header">
                            <th>Name</th>
                            <th>Bank Account</th>
                            <th>Reference</th>
                            <th>Amount</th>
                            <th>Validation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addConfirmationItems()}
                        </tbody>
                    </Table>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmation}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={paySelected} disabled={hasValidationErrors()}><CreditCard size={16} /> Pay</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

   export default InvoiceActions;